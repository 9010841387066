import React, { FC, PropsWithChildren, ReactElement } from "react";

import { Subtitle, Title } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

import "./TitleBlock.scss";

type TitleBlockProps = PropsWithChildren<{
  title?: string | ReactElement | null;
  subtitle?: string | ReactElement | null;
}>;

export const TitleBlock: FC<TitleBlockProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Columns gap={1} isVCentered isMobile className="title-block">
      <Column>
        <Stack direction="column" gap="xxs">
          {title && (
            <Title noWrap size={4}>
              {title}
            </Title>
          )}
          {subtitle && <Subtitle size={6}>{subtitle}</Subtitle>}
        </Stack>
      </Column>
      {children && <Column isNarrow>{children}</Column>}
    </Columns>
  );
};
