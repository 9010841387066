import React, { FC, useEffect } from "react";

import { useForm } from "react-hook-form";

import { withController } from "@utils";

import { useContact } from "@api/cognito/contact";

import { Modal } from "@ui/components";
import { Button, Divider, Icon, Notification, Text, Title } from "@ui/elements";
import { Input, Textarea } from "@ui/forms";
import { Grid, Stack } from "@ui/layout";

import { toaster, useAuth } from "@components";

import { FAQ } from "../FAQ/FAQ";

type ContactModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type ContactFormFields = {
  name: string;
  email: string;
  message: string;
  accept_privacy_policy: string;
};

const ControlledInput = withController<ContactFormFields>()(Input);
const ControlledTextarea = withController<ContactFormFields>()(Textarea);

export const ContactModal: FC<ContactModalProps> = ({ isOpen, onClose }) => {
  const authContext = useAuth();
  const {
    mutateAsync: sendContact,
    isLoading: contactLoading,
    error: contactError,
  } = useContact();
  const { control, formState, setValue, handleSubmit } =
    useForm<ContactFormFields>({
      defaultValues: {
        name: authContext.userAttributes?.name,
        email: authContext.userAttributes?.email,
      },
    });

  useEffect(() => {
    if (authContext.userAttributes) {
      if (!formState.touchedFields.name && authContext.userAttributes.name) {
        setValue("name", authContext.userAttributes.name);
      }
      if (!formState.touchedFields.email && authContext.userAttributes.email) {
        setValue("email", authContext.userAttributes.email);
      }
    }
  }, [formState.touchedFields, authContext.userAttributes]);

  const onSubmit = async (values: ContactFormFields) => {
    const { error } = await sendContact(values);

    if (!error) {
      toaster.success({
        title: "Message sent successfully!",
        message:
          "Thank you for contacting us. We'll get back to you as soon as possible.",
      });
    }
  };

  return (
    <Modal
      header={
        <Stack>
          <Icon icon="Info" />
          <Title size={4}>Get Help</Title>
        </Stack>
      }
      isActive={isOpen}
      onClose={onClose}
    >
      <Stack direction="column" gap="xxl">
        <Stack direction="column">
          <Title size={5}>Frequently Asked Questions</Title>
          <FAQ />
        </Stack>
        <Stack direction="column">
          <Title size={5}>Contact Us</Title>
          <Stack direction="column" gap="xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" gap="xl">
                <Stack direction="column">
                  <Grid>
                    <ControlledInput
                      label="Name"
                      name="name"
                      control={control}
                    />
                    <ControlledInput
                      label="E-mail"
                      type="email"
                      name="email"
                      control={control}
                    />
                  </Grid>
                  <ControlledTextarea
                    rows={4}
                    control={control}
                    name="message"
                    label="Message"
                  />
                </Stack>
                {contactError && (
                  <Notification color="danger">
                    <Text weight="bold">
                      {contactError?.response?.data.error}
                    </Text>
                    <Text>{contactError?.response?.data.message}</Text>
                  </Notification>
                )}
                <Button variant="light" isLoading={contactLoading}>
                  Send
                </Button>
              </Stack>
            </form>
            <Divider text="or" />
            <Stack justify="center">
              <Button
                variant="light"
                isRounded
                as="link"
                href="https://t.me/slimtiexxx"
                target="_blank"
              >
                <Icon icon="TelegramLogo" />
              </Button>
              <Button
                variant="light"
                isRounded
                as="link"
                href="https://instagram.com/direct/t/footballgenieai"
                target="_blank"
              >
                <Icon icon="InstagramLogo" />
              </Button>
              <Button
                variant="light"
                isRounded
                as="link"
                href="mailto:info@football-genie.com"
                target="_blank"
              >
                <Icon icon="At" />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
