export const copyToClipboard = async (text: string | number) => {
  if (!navigator.clipboard) {
    // Fallback: Copy using a temporary text area
    const textArea = document.createElement("textarea");
    textArea.value = String(text);
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      console.log("Fallback: Text copied to clipboard");
    } catch (err) {
      console.error("Fallback: Could not copy text", err);
    }
    document.body.removeChild(textArea);
  } else {
    // Using the Clipboard API
    try {
      await navigator.clipboard.writeText(String(text));
      console.log("Text successfully copied using Clipboard API");
    } catch (err) {
      console.error("Clipboard API failed", err);
    }
  }
};
