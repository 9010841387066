import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";

import { GET_USER_TIP_QUERY_KEY } from "@api/user/getTip";
import { GET_USER_TIPS_QUERY_KEY } from "@api/user/getTips";
import { GET_USER_TIPS_SUMMARY_QUERY_KEY } from "@api/user/getTipsSummary";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type DeleteUserTipResponse = {
  error?: string;
  message?: string;
};

type DeleteTipArgs = {
  id: string;
};

const deleteTip: MutateFunction<
  DeleteUserTipResponse,
  DefaultErrorResponse,
  DeleteTipArgs
> = (params) => {
  return client.delete(`tips/${params.id}`).then((res) => res.data);
};

export const DELETE_USER_TIP_QUERY_KEY = "delete-user-tip";

export const useDeleteUserTip = (
  options?: Omit<
    UseMutationOptions<
      DeleteUserTipResponse,
      DefaultErrorResponse,
      DeleteTipArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteUserTipResponse,
  DefaultErrorResponse,
  DeleteTipArgs
> => {
  const queryClient = useQueryClient();

  return useMutation([DELETE_USER_TIP_QUERY_KEY], deleteTip, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIP_QUERY_KEY, variables.id],
        refetchActive: true,
        refetchInactive: true,
      });

      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIPS_QUERY_KEY],
        refetchActive: true,
        refetchInactive: true,
      });

      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIPS_SUMMARY_QUERY_KEY],
        refetchActive: true,
        refetchInactive: true,
      });
    },
  });
};
