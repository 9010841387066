import React, { createContext, useEffect, useState } from "react";

import {
  faBrain,
  faCalendar,
  faFutbol,
  faNewspaper,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { useForm, UseFormReturn } from "react-hook-form";

import { Box } from "@ui/elements";

import {
  CustomTipItem,
  useCreateCustomPrediction,
} from "../../../../services/api/prediction-summary/createCustomPrediction";
import { useGetExpertOpinions } from "../../../../services/api/risk-analysis/getExpertOpinions";
import { StepComponent } from "../StepComponent/StepComponent";

import { DateSelect } from "./Steps/DateSelect";
import { FixtureSelect } from "./Steps/FixtureSelect";
import { Generate } from "./Steps/Generate";
import { NewsSelector } from "./Steps/NewsSelector";
import { Options } from "./Steps/Options";

export type TipValidatorFormFields = {
  id: string;
  date: Date;
  expertOpinion?: string;
  selectedArticles: string[];
  includeTotal: boolean;
  includeH2H: boolean;
  includeHomeAway: boolean;
  mathAI: boolean;
  matchImportance: boolean;
};

export type ValidatorSteps =
  | "date"
  | "fixture"
  | "news"
  | "options"
  | "generate";

type TipValidatorContextType = {
  form: UseFormReturn<TipValidatorFormFields>;
  tips: Array<CustomTipItem[]>;
  setTips: (tips: Array<CustomTipItem[]>) => void;
  isLoading: boolean;
};

export const TipValidatorContext = createContext<TipValidatorContextType>({
  form: {} as UseFormReturn<TipValidatorFormFields>,
  tips: [],
  setTips: () => {},
  isLoading: false,
});

const today = dayjs().toDate();

export const TipValidator = () => {
  const [error, setError] = useState("");
  const form = useForm<TipValidatorFormFields>({
    defaultValues: {
      matchImportance: true,
      mathAI: true,
      includeTotal: true,
      includeH2H: true,
      includeHomeAway: true,
      selectedArticles: [],
      date: today,
    },
  });
  const { handleSubmit, watch } = form;
  const [activeStep, setActiveStep] = useState<ValidatorSteps>("date");

  const fixtureId = watch("id");
  const selectedArticles = watch("selectedArticles");
  const [tips, setTips] = useState<Array<CustomTipItem[]>>([]);
  const { data: newsData } = useGetExpertOpinions(fixtureId, {
    enabled: !!fixtureId && activeStep === "news",
  });
  const {
    mutateAsync,
    isLoading,
    error: mutationError,
  } = useCreateCustomPrediction();

  const onSubmit = async (values: TipValidatorFormFields) => {
    setError("");
    const expertOpinions =
      newsData?.results
        .filter((e) => selectedArticles.includes(e.search_result.link))
        .reduce((acc, curr) => {
          acc += `\n${curr.search_result.displayLink}:
      ${curr.content}
      `;

          return acc;
        }, "") || "";

    const result = await mutateAsync({
      id: values.id,
      expert_opinion: values.expertOpinion + expertOpinions,
    });

    if (result.error) {
      setError("");
    } else if (result.tips.length) {
      setTips([...tips, result.tips]);
    }
  };

  useEffect(() => {
    if (mutationError?.message) {
      setError(mutationError.message);
    }
  }, [mutationError]);

  return (
    <TipValidatorContext.Provider value={{ form, tips, isLoading, setTips }}>
      <Box title="Tip Validator">
        <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
          {error && (
            <div className="notification is-danger is-light">{error}</div>
          )}
          <StepComponent<ValidatorSteps>
            currentStep={activeStep}
            goToStep={setActiveStep}
            items={[
              {
                id: "date",
                icon: faCalendar,
                title: "Date Select",
                Component: DateSelect,
              },
              {
                id: "fixture",
                icon: faFutbol,
                title: "Fixture Select",
                Component: FixtureSelect,
              },
              {
                id: "news",
                icon: faNewspaper,
                title: "Pick News",
                Component: NewsSelector,
              },
              {
                id: "options",
                icon: faSliders,
                title: "Options",
                Component: Options,
              },
              {
                id: "generate",
                icon: faBrain,
                title: "Generate",
                Component: Generate,
              },
            ]}
          />
        </form>
      </Box>
    </TipValidatorContext.Provider>
  );
};
