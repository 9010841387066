import React, { FC, useMemo, useState } from "react";

import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";

import { calculateKelly } from "@utils";
import { copyToClipboard } from "@utils";

import { useOdds } from "@api/football/fetchOdds";
import {
  NextGenTipItem,
  TipCategoryMap,
  useGetNextGenTips,
} from "@api/prediction-chain/tips/get";

import { Box, Icon, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

const TipCard: FC<{
  tip: NextGenTipItem;
  fixtureId: string;
}> = ({ tip, fixtureId }) => {
  const { data: oddsData } = useOdds(fixtureId);

  const handleCopy = async (text: string) => {
    await copyToClipboard(text);
    toast("Copied to clipboard", { type: "success" });
  };

  const odds = useMemo(() => {
    return Number(
      oddsData
        ?.find((e) => e.name === TipCategoryMap[tip.tip_category])
        ?.values.find((e) => e.value === tip.tip_value)?.odd,
    );
  }, [oddsData, tip.tip_category, tip.tip_value]);

  const betAmount = useMemo(() => {
    return calculateKelly(
      odds,
      Number(tip.chance_of_occurring.replace("%", "")),
    );
  }, [odds, tip.chance_of_occurring]);

  return (
    <article
      className={classNames(["message is-small"])}
      key={`safe-${tip.tip_name}-${tip.id}`}
    >
      <div className="message-header">
        <p>
          {tip.tip_category} <br />
          <span className="has-text-weight-light">
            {tip.tip_value}
            <small>&nbsp;({tip.tip_name})</small>
          </span>
        </p>
        <div className="tags">
          <p className="tag">x{odds}</p>
          <p className="tag">{betAmount}%</p>
          <p
            className={classNames([
              "tag",
              {
                "is-success": tip.tip_group === "safe",
                "is-warning": tip.tip_group === "value",
                "is-danger": tip.tip_group === "risk",
              },
            ])}
          >
            {tip.chance_of_occurring}
          </p>
        </div>
      </div>
      <div className="message-body content is-small">
        {"reasoning" in tip && (
          <p onClick={() => handleCopy(tip.reasoning)}>{tip.reasoning}</p>
        )}
      </div>
    </article>
  );
};

type NextGenTipsProps = {
  id: string;
};

export const NextGenTips: FC<NextGenTipsProps> = ({ id }) => {
  const { data } = useGetNextGenTips(id);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailedOpen, setDetailedOpen] = useState(false);
  const analysis = data?.data?.analysis;

  if (!data?.data?.analysis) return null;

  return (
    <>
      <Box
        header={
          <Stack justify="between">
            <Title size={5}>Next-Gen Tips</Title>
            <Icon icon="Money" />
          </Stack>
        }
      >
        {data.data.summary?.map((tip) => (
          <TipCard key={`tip-summary-${tip.id}`} tip={tip} fixtureId={id} />
        ))}

        <div className="buttons is-justify-content-center">
          <button
            onClick={() => setDetailedOpen(!detailedOpen)}
            className="button is-small"
          >
            {detailedOpen ? "Hide all" : "Show all"}
          </button>
        </div>
        {/*{detailedOpen && (*/}
        {/*  <Tabs*/}
        {/*    items={tabItems}*/}
        {/*    activeIndex={activeTab}*/}
        {/*    onChange={(i) => setActiveTab(i)}*/}
        {/*  />*/}
        {/*)}*/}

        <div className={classNames(["modal", { "is-active": modalOpen }])}>
          <div
            className="modal-background"
            onClick={() => setModalOpen(false)}
          ></div>
          <div className="modal-content box">
            <ReactMarkdown className="content is-small">
              {analysis?.motivations}
            </ReactMarkdown>
            <ReactMarkdown className="content is-small">
              {analysis?.standings}
            </ReactMarkdown>
            <ReactMarkdown className="content is-small">
              {analysis?.characteristics}
            </ReactMarkdown>
            <ReactMarkdown className="content is-small">
              {analysis?.players}
            </ReactMarkdown>
            <ReactMarkdown className="content is-small">
              {analysis?.lineup}
            </ReactMarkdown>
            <ReactMarkdown className="content is-small">
              {analysis?.expert_opinion}
            </ReactMarkdown>
          </div>
          <button
            className="modal-close is-large"
            onClick={() => setModalOpen(false)}
            aria-label="close"
          />
        </div>
      </Box>
    </>
  );
};
