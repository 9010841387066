import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import classNames from "classnames";

import "./Label.scss";

type InputProps = PropsWithChildren & HTMLAttributes<HTMLLabelElement>;

export const Label: FC<InputProps> = ({ children, ...rest }) => {
  return (
    <label className={classNames("label")} {...rest}>
      {children}
    </label>
  );
};
