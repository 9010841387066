import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeleteLineupsResponse = {
  error: string | null;
  success: boolean;
};

type DeleteLineupsArgs = {
  id: string | number;
};

export const deleteLineups: MutationFunction<
  DeleteLineupsResponse,
  DeleteLineupsArgs
> = (props) => {
  return client
    .delete(`/lineups`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeleteLineupsMutationKey = "delete-lineups";

export const useDeleteLineups = (
  params?: DeleteLineupsArgs,
  options?: Omit<
    UseMutationOptions<
      DeleteLineupsResponse,
      DefaultErrorResponse,
      DeleteLineupsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteLineupsResponse,
  DefaultErrorResponse,
  DeleteLineupsArgs
> => {
  return useMutation(
    [DeleteLineupsMutationKey, params?.id],
    deleteLineups,
    options,
  );
};
