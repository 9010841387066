import React, { FC, useRef, useEffect } from "react";

import classNames from "classnames";

import { Button } from "@ui/elements";

import "./ButtonSelect.scss";

interface SelectOption {
  value: string;
  label: string;
}

type ButtonSelectProps = {
  options: SelectOption[];
  size?: "small" | "normal" | "medium" | "large";
  isGrouped?: boolean;
  value?: string;
  onChange?: (value: string) => void;
};

export const ButtonSelect: FC<ButtonSelectProps> = ({
  isGrouped,
  options,
  value,
  onChange,
}) => {
  const buttonWrapper = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<Record<string, HTMLButtonElement | null>>({});

  const handleSelect = (value: string) => {
    onChange?.(value);
    scrollToCenter(value);
  };

  const scrollToCenter = (value: string) => {
    const buttonElement = buttonRefs.current[value];

    if (buttonWrapper.current && buttonElement) {
      const wrapperElement = buttonWrapper.current;

      const buttonOffsetLeft = buttonElement.offsetLeft;
      const buttonWidth = buttonElement.offsetWidth;
      const containerWidth = wrapperElement.offsetWidth;

      const scrollPosition =
        buttonOffsetLeft - containerWidth / 2 + buttonWidth / 2;

      wrapperElement.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (value) {
      scrollToCenter(value);
    }
  }, [value]);

  return (
    <div
      ref={buttonWrapper}
      className={classNames("buttons button-select", {
        "has-addons": isGrouped,
      })}
    >
      {options.map((option) => (
        <Button
          ref={(el) => (buttonRefs.current[option.value] = el)}
          key={`button-select-${option.value}`}
          color={option.value === value ? "primary" : undefined}
          isSelected={option.value === value}
          onClick={() => {
            handleSelect(option.value);
          }}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );
};
