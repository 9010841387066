import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeleteTeamCharacteristicsResponse = {
  error: string | null;
  success: boolean;
};

type DeleteTeamCharacteristicsArgs = {
  id: string | number;
};

export const deleteTeamCharacteristics: MutationFunction<
  DeleteTeamCharacteristicsResponse,
  DeleteTeamCharacteristicsArgs
> = (props) => {
  return client
    .delete(`/team-characteristics`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeleteTeamCharacteristicsMutationKey =
  "delete-team-characteristics";

export const useDeleteTeamCharacteristics = (
  params?: DeleteTeamCharacteristicsArgs,
  options?: Omit<
    UseMutationOptions<
      DeleteTeamCharacteristicsResponse,
      DefaultErrorResponse,
      DeleteTeamCharacteristicsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteTeamCharacteristicsResponse,
  DefaultErrorResponse,
  DeleteTeamCharacteristicsArgs
> => {
  return useMutation(
    [DeleteTeamCharacteristicsMutationKey, params?.id],
    deleteTeamCharacteristics,
    options,
  );
};
