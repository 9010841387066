import React, { FC, useMemo, useState } from "react";

import { FetchOddsResponse, useOdds } from "@api/football/fetchOdds";

import { InfoBox } from "@ui/components";
import {
  Divider,
  Icon,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Title,
} from "@ui/elements";
import { Select } from "@ui/forms";
import { Stack } from "@ui/layout";

import { OddsMessage } from "../../../../components/OddsCard/OddsMessage/OddsMessage";

type OddsTabContentProps = {
  items: FetchOddsResponse[];
};

const AsianHandicapContent: FC<OddsTabContentProps> = ({ items }) => {
  const transformedValues = useMemo(() => {
    const handicap = items.find((e) => e.name === "Asian Handicap");

    if (handicap) {
      const handiValues = [
        ...new Set(
          handicap.values.map((e) => {
            const splitIndex =
              e.value.indexOf("+") !== -1
                ? e.value.indexOf("+")
                : e.value.indexOf("-");

            return e.value.substring(splitIndex, e.value.length);
          }),
        ),
      ]
        .sort((a, b) => Number(a) - Number(b))
        .filter((e) => Number(e) % 0.5 === 0);

      return handiValues.map((value) => {
        const awayOdds = handicap.values.find(
          (e) =>
            e.value ===
            `Away ${
              value !== "+0"
                ? value.includes("+")
                  ? value.replace("+", "-")
                  : value.replace("-", "+")
                : value
            }`,
        )?.odd;
        const homeOdds = handicap.values.find(
          (e) => e.value === `Home ${value}`,
        )?.odd;

        return {
          handi: value,
          home: homeOdds ? `x${homeOdds}` : homeOdds,
          test: `Away ${
            value.includes("+") ? `-${Number(value) * -1}` : `${value}`
          }`,
          away: awayOdds ? `x${awayOdds}` : "",
        };
      });
    }

    return [];
  }, [items]);

  return (
    <div>
      {!!transformedValues.length && (
        <Table isFullwidth isHoverable isStriped>
          <TableRow>
            <TableHeading></TableHeading>
            <TableHeading>Home</TableHeading>
            <TableHeading>Away</TableHeading>
          </TableRow>
          {transformedValues.map((item) => (
            <TableRow key={`handicap-row-${item.handi}`}>
              <TableData>{item.handi}</TableData>
              <TableData>{item.home}</TableData>
              <TableData>{item.away}</TableData>
            </TableRow>
          ))}
        </Table>
      )}
    </div>
  );
};

const CornersContent: FC<OddsTabContentProps> = ({ items }) => {
  const transformedValues = useMemo(() => {
    const corners = items.find((e) => e.name === "Total Corners (3 way)");

    if (corners) {
      const cornerValues = [
        ...new Set(
          corners.values.map((e) => {
            return Number(
              e.value.substring(e.value.indexOf(" "), e.value.length),
            );
          }),
        ),
      ].sort((a, b) => a - b);

      return cornerValues.map((value) => ({
        corner: value,
        under: `x${corners.values.find((e) => e.value === `Under ${value}`)
          ?.odd}`,
        over: `x${corners.values.find((e) => e.value === `Over ${value}`)
          ?.odd}`,
      }));
    }

    return [];
  }, [items]);

  const findOdd = (category: string, value: string) => {
    const odd = items
      ?.find((e) => e.name === category)
      ?.values.find((e) => e.value === value)?.odd;

    if (odd) {
      return `x${odd}`;
    }

    return "";
  };

  const itemExists = (category: string) =>
    !!items.find((e) => e.name === category);

  return (
    <div>
      {!!transformedValues.length && (
        <Table isFullwidth isHoverable isStriped>
          <TableRow>
            <TableHeading></TableHeading>
            <TableHeading>Under</TableHeading>
            <TableHeading>Over</TableHeading>
          </TableRow>
          {transformedValues.map((item) => (
            <TableRow key={`corners-row-${item.corner}`}>
              <TableData>{item.corner}</TableData>
              <TableData>{item.under}</TableData>
              <TableData>{item.over}</TableData>
            </TableRow>
          ))}
        </Table>
      )}
      {itemExists("Corners 1x2") && (
        <OddsMessage title="Corner Match Bet">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableData>Home</TableData>
              <TableData>{findOdd("Corners 1x2", "Home")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Away</TableData>
              <TableData>{findOdd("Corners 1x2", "Away")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
    </div>
  );
};

const OddsTabContent: FC<OddsTabContentProps> = ({ items }) => {
  const [goalCategory, setGoalCategory] = useState("Goals Over/Under");
  const findOdd = (category: string, value: string) => {
    const odd = items
      ?.find((e) => e.name === category)
      ?.values.find((e) => e.value === value)?.odd;

    if (odd) {
      return `x${odd}`;
    }

    return "";
  };

  const itemExists = (category: string) =>
    !!items.find((e) => e.name === category);

  return (
    <Stack direction="column">
      {itemExists("Match Winner") && (
        <Table isFullwidth isHoverable isStriped>
          <TableRow>
            <TableHeading></TableHeading>
            <TableHeading>Home</TableHeading>
            <TableHeading>Draw</TableHeading>
            <TableHeading>Away</TableHeading>
          </TableRow>
          <TableRow>
            <TableData>Match</TableData>
            <TableData>{findOdd("Match Winner", "Home")}</TableData>
            <TableData>{findOdd("Match Winner", "Draw")}</TableData>
            <TableData>{findOdd("Match Winner", "Away")}</TableData>
          </TableRow>
          <TableRow>
            <TableData>1st Half</TableData>
            <TableData>{findOdd("First Half Winner", "Home")}</TableData>
            <TableData>{findOdd("First Half Winner", "Draw")}</TableData>
            <TableData>{findOdd("First Half Winner", "Away")}</TableData>
          </TableRow>
          <TableRow>
            <TableData>2nd Half</TableData>
            <TableData>{findOdd("Second Half Winner", "Home")}</TableData>
            <TableData>{findOdd("Second Half Winner", "Draw")}</TableData>
            <TableData>{findOdd("Second Half Winner", "Away")}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Either Half</TableData>
            <TableData>{findOdd("To Win Either Half", "Home")}</TableData>
            <TableData></TableData>
            <TableData>{findOdd("To Win Either Half", "Away")}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Both Halves</TableData>
            <TableData>{findOdd("Win Both Halves", "Home")}</TableData>
            <TableData></TableData>
            <TableData>{findOdd("Win Both Halves", "Away")}</TableData>
          </TableRow>
          <TableRow>
            <TableData>Win to Nil</TableData>
            <TableData>{findOdd("Win To Nil", "Home")}</TableData>
            <TableData></TableData>
            <TableData>{findOdd("Win To Nil", "Away")}</TableData>
          </TableRow>
        </Table>
      )}
      {itemExists("Double Chance") && (
        <OddsMessage title="Double Chance">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableData>Home/Draw</TableData>
              <TableData>{findOdd("Double Chance", "Home/Draw")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Draw/Away</TableData>
              <TableData>{findOdd("Double Chance", "Draw/Away")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Home/Away</TableData>
              <TableData>{findOdd("Double Chance", "Home/Away")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("Asian Handicap") && (
        <OddsMessage title="Draw no Bet">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableData>Home</TableData>
              <TableData>{findOdd("Asian Handicap", "Home +0")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Away</TableData>
              <TableData>{findOdd("Asian Handicap", "Away +0")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("To Qualify") && (
        <OddsMessage title="To Qualify">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableHeading></TableHeading>
              <TableHeading>Home</TableHeading>
              <TableHeading>Away</TableHeading>
            </TableRow>
            <TableRow>
              <TableData>Match</TableData>
              <TableData>{findOdd("To Qualify", "Home")}</TableData>
              <TableData>{findOdd("To Qualify", "Away")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("Clean Sheet - Home") && (
        <OddsMessage title="Clean Sheet">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableHeading></TableHeading>
              <TableHeading>Home</TableHeading>
              <TableHeading>Away</TableHeading>
            </TableRow>
            <TableRow>
              <TableData>Yes</TableData>
              <TableData>{findOdd("Clean Sheet - Home", "Yes")}</TableData>
              <TableData>{findOdd("Clean Sheet - Away", "Yes")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>No</TableData>
              <TableData>{findOdd("Clean Sheet - Home", "No")}</TableData>
              <TableData>{findOdd("Clean Sheet - Away", "No")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("Goals Over/Under") && (
        <Table isFullwidth isHoverable isStriped>
          <TableRow>
            <TableHeading>
              <Select
                options={[
                  { label: "Both Teams Combined", value: "Goals Over/Under" },
                  { label: "Home", value: "Total - Home" },
                  { label: "Away", value: "Total - Away" },
                  { label: "1st Half", value: "Goals Over/Under First Half" },
                  {
                    label: "2nd Half",
                    value: "Goals Over/Under - Second Half",
                  },
                ]}
                value={goalCategory}
                onChange={(e) => setGoalCategory(e.target.value)}
              />
            </TableHeading>
            <TableHeading>Over</TableHeading>
            <TableHeading>Under</TableHeading>
          </TableRow>
          {[0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5].map((goalNum) => (
            <TableRow key={`total-goals-odds-${goalNum}`}>
              <TableData>{goalNum} Goals</TableData>
              <TableData>{findOdd(goalCategory, `Over ${goalNum}`)}</TableData>
              <TableData>{findOdd(goalCategory, `Under ${goalNum}`)}</TableData>
            </TableRow>
          ))}
        </Table>
      )}
      {itemExists("Both Teams Score") && (
        <OddsMessage title="Both Teams Score">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableHeading></TableHeading>
              <TableHeading>Yes</TableHeading>
              <TableHeading>No</TableHeading>
            </TableRow>
            <TableRow>
              <TableData>Match</TableData>
              <TableData>{findOdd("Both Teams Score", "Yes")}</TableData>
              <TableData>{findOdd("Both Teams Score", "No")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>1st Half</TableData>
              <TableData>
                {findOdd("Both Teams Score - First Half", "Yes")}
              </TableData>
              <TableData>
                {findOdd("Both Teams Score - First Half", "No")}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>2nd Half</TableData>
              <TableData>
                {findOdd("Both Teams To Score - Second Half", "Yes")}
              </TableData>
              <TableData>
                {findOdd("Both Teams To Score - Second Half", "No")}
              </TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("To Score In Both Halves By Teams") && (
        <OddsMessage title="Score in Both Halves">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableData>Home</TableData>
              <TableData>
                {findOdd("To Score In Both Halves By Teams", "Home")}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>Away</TableData>
              <TableData>
                {findOdd("To Score In Both Halves By Teams", "Away")}
              </TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("Team To Score First") && (
        <OddsMessage title="First Scorer">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableData>Home</TableData>
              <TableData>{findOdd("Team To Score First", "Home")}</TableData>
            </TableRow>
            <TableRow>
              <TableData>Away</TableData>
              <TableData>{findOdd("Team To Score First", "Away")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
      {itemExists("Highest Scoring Half") && (
        <OddsMessage title="Highest Scoring Half">
          <Table isFullwidth isHoverable isStriped>
            <TableRow>
              <TableData>1st Half</TableData>
              <TableData>
                {findOdd("Highest Scoring Half", "1st Half")}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>2nd Half</TableData>
              <TableData>
                {findOdd("Highest Scoring Half", "2nd Half")}
              </TableData>
            </TableRow>
            <TableRow>
              <TableData>Draw</TableData>
              <TableData>{findOdd("Highest Scoring Half", "Draw")}</TableData>
            </TableRow>
          </Table>
        </OddsMessage>
      )}
    </Stack>
  );
};

const MatchOutcomes = [
  "Match Winner",
  "To Qualify",
  "Win To Nil",
  "To Win Either Half",
  "Win Both Halves",
  "First Half Winner",
  "Second Half Winner",
  "Clean Sheet - Home",
  "Clean Sheet - Away",
  "Double Chance",
  "Asian Handicap",
];

const Goals = [
  "Goals Over/Under",
  "Goals Over/Under First Half",
  "Goals Over/Under - Second Half",
  "Both Teams Score",
  "Both Teams Score - First Half",
  "Both Teams To Score - Second Half",
  "Highest Scoring Half",
  "Total - Home",
  "Total - Away",
  "To Score In Both Halves By Teams",
  "Team To Score First",
];

const AsianLines = ["Asian Handicap"];
const Corners = ["Total Corners (3 way)", "Corners 1x2"];

type OddsItemsProps = {
  id: string | number;
};

export const OddsItems: FC<OddsItemsProps> = ({ id }) => {
  const { data, isLoading } = useOdds(id);

  if (!data?.length && !isLoading) {
    return (
      <InfoBox hasSpacing icon="SmileyXEyes">
        Failed to fetch Odds data ... <br />
        Please try again later.
      </InfoBox>
    );
  }

  return (
    <>
      <Stack direction="column">
        <Title size={5} hasIcon>
          <Icon icon="Trophy" />
          <span>Match Outcome</span>
        </Title>
        {isLoading && (
          <div className="skeleton-block" style={{ height: 360 }}></div>
        )}
        <OddsTabContent
          items={data?.filter((e) => MatchOutcomes.includes(e.name)) || []}
        />
      </Stack>
      <Divider />
      <Stack direction="column">
        <Title size={5} hasIcon>
          <Icon icon="SoccerBall" />
          <span>Goals</span>
        </Title>
        {isLoading && (
          <div className="skeleton-block" style={{ height: 360 }}></div>
        )}
        <OddsTabContent
          items={data?.filter((e) => Goals.includes(e.name)) || []}
        />
      </Stack>
      <Divider />
      <Stack direction="column">
        <Title size={5} hasIcon>
          <Icon icon="PlusMinus" />
          <span>Asian Handicap</span>
        </Title>
        {isLoading && (
          <div className="skeleton-block" style={{ height: 360 }}></div>
        )}
        <AsianHandicapContent
          items={data?.filter((e) => AsianLines.includes(e.name)) || []}
        />
      </Stack>
      <Divider />
      <Stack direction="column">
        <Title size={5} hasIcon>
          <Icon icon="Flag" />
          <span>Corners</span>
        </Title>
        {isLoading && (
          <div className="skeleton-block" style={{ height: 360 }}></div>
        )}
        <CornersContent
          items={data?.filter((e) => Corners.includes(e.name)) || []}
        />
      </Stack>
    </>
  );
};
