import React from "react";

import { Icon } from "@ui/elements";
import "./Spinner.scss";

export const Spinner = () => {
  return (
    <div className="spinner">
      <Icon icon="SpinnerGap" size="large" />
    </div>
  );
};
