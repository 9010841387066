import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useForm } from "react-hook-form";

import { useDevice } from "@utils";

import { League, TipItem, useGetTips } from "@api/math-tips";
import { useGetTipsSummary } from "@api/math-tips/getTipsSummary";

import { InfoBox, Pagination, TitleBlock } from "@ui/components";
import { Column, Columns, Stack } from "@ui/layout";

import {
  TipCard,
  TipsTableContext,
  TipTableColumnDefs,
  DataTable,
  TipCardSkeleton,
  TipFilter,
  TipFilterValues,
  useTipFilterDefaultValues,
} from "@components";

import { AIPerformanceCards } from "./AIPerformanceSummaryCards";
import { TopPerformers } from "./TopPerformers";
import "./AIPerformance.scss";

dayjs.extend(isoWeek);

export const AIPerformance = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const defaultValues = useTipFilterDefaultValues("ai-performance");
  const [filterValues, setFilterValues] =
    useState<TipFilterValues>(defaultValues);
  const [availableLeagues, setAvailableLeagues] = useState<League[]>([]);

  const form = useForm<TipFilterValues>({
    defaultValues,
  });

  const { data, isLoading: tipsLoading } = useGetTips({
    page: currentPage,
    ...filterValues,
  });

  const { data: summaryData, isLoading: summaryLoading } = useGetTipsSummary({
    page: currentPage,
    ...filterValues,
  });

  useEffect(() => {
    if (typeof data?.total_pages !== "undefined") {
      setTotalPages(Number(data.total_pages));
    }
  }, [data?.total_pages]);

  useEffect(() => {
    if (data?.available_leagues) {
      setAvailableLeagues(data?.available_leagues?.filter(Boolean) || []);
    }
  }, [data?.available_leagues.join("-")]);

  const handleReset = () => {
    form.reset({
      include_ratings: ["safe", "value", "risky"],
      enabledFilters: ["tipRating"],
      timePeriod: "last-7-days",
    });
  };

  const device = useDevice();

  return (
    <Stack direction="column" gap="xxl">
      <TopPerformers />
      <Stack direction="column">
        <TitleBlock
          title="Previous AI Tips"
          subtitle={
            (!!data?.total_tips && (
              <>
                Showing <strong>{data?.total_tips}</strong> tips from{" "}
                <strong>{data?.total_items}</strong> matches
              </>
            )) ||
            null
          }
        />
        <Columns isMultiline>
          <Column tablet={12} widescreen={5} fullhd={4}>
            <TipFilter
              id="ai-performance"
              onSubmit={(values) => {
                setCurrentPage(1);
                setFilterValues(values);
              }}
              leagueOptions={availableLeagues?.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              form={form}
              enabledTimePeriods={[
                "today",
                "yesterday",
                "last-7-days",
                "last-30-days",
                "custom",
              ]}
              allowedFilters={{
                betAmount: true,
                edge: true,
                leagues: true,
                odds: true,
                tipRating: true,
                tipStatus: false,
              }}
            />
          </Column>
          <Column tablet={12} widescreen={7} fullhd={8}>
            <Stack isFullwidth direction="column" gap="xxl">
              <AIPerformanceCards
                data={summaryData}
                isLoading={summaryLoading}
              />
              {!summaryLoading && !tipsLoading && !data?.data.length && (
                <InfoBox
                  icon="SmileyXEyes"
                  hasSpacing
                  title="Not found tips ..."
                  secondaryButtonLabel="Reset Filters"
                  secondaryButtonAction={handleReset}
                >
                  We&apos;re unable to find tips for this filter criteria.{" "}
                  <br />
                  Try again later or modify your filters.
                </InfoBox>
              )}
              <Stack direction="column" gap="md">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(page) => setCurrentPage(page)}
                />
                <Stack gap="md" direction="column">
                  {tipsLoading && !data ? (
                    <>
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                    </>
                  ) : (
                    data?.data.map(({ fixture, tips }) => (
                      <TipCard
                        key={`ai-performance-tip-card-${fixture.fixture.id}`}
                        fixture={fixture}
                      >
                        <DataTable<TipItem, TipsTableContext>
                          context={{ fixture }}
                          columnDefs={
                            device === "mobile"
                              ? [
                                  TipTableColumnDefs.tip,
                                  TipTableColumnDefs.ratingPercentage,
                                  TipTableColumnDefs.odds,
                                  TipTableColumnDefs.betAmount,
                                  TipTableColumnDefs.profitLoss,
                                ]
                              : [
                                  TipTableColumnDefs.tip,
                                  TipTableColumnDefs.rating,
                                  TipTableColumnDefs.probability,
                                  TipTableColumnDefs.odds,
                                  TipTableColumnDefs.betAmount,
                                  TipTableColumnDefs.profitLoss,
                                ]
                          }
                          data={tips}
                        />
                      </TipCard>
                    ))
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </Stack>
  );
};
