import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type StandingItem = {
  all: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  away: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  form: string;
  rank: string;
  description: string;
  update: string;
  team: {
    name: string;
    logo: string;
    id: string;
  };
  goalsDiff: string;
  points: string;
  group: string;
  status: string;
  home: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
};

type TeamCharacteristicsData = {
  id: string;
  standings: {
    home: StandingItem;
    away: StandingItem;
  };
  prompt?: string;
};

export type GetTeamCharacteristicsResponse = {
  error: string | null;
  data: TeamCharacteristicsData | null;
};

export const getTeamCharacteristics: QueryFunction<
  GetTeamCharacteristicsResponse
> = ({ queryKey }) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/team-characteristics", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetTeamCharacteristicsQueryKey = "get-team-characteristics";

export const useGetTeamCharacteristics = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetTeamCharacteristicsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetTeamCharacteristicsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GetTeamCharacteristicsQueryKey, id],
    getTeamCharacteristics,
    options,
  );
};
