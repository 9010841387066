import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";
import { TipsQueryArgs } from "@api/math-tips";

import { client } from "../client";

type TipBankrollRecord = {
  tip: string;
  bankroll: string;
};

type TipsSummary = {
  roi: string;
  avg_odds: string;
  avg_probability: string;
  hit_rate: string;
  bankroll_over_time: TipBankrollRecord[];
  total_profit: string;
  total_bet_amount: string;
};

export type GetTipsSummaryResponse = {
  summary: TipsSummary;
  tips: string;
  matches: string;
  days: string;
};

export const fetchTipsSummary: QueryFunction<GetTipsSummaryResponse> = ({
  queryKey,
}) => {
  const [
    _key,
    from,
    to,
    include_ratings,
    include_leagues,
    min_odds,
    max_odds,
    min_edge,
    max_edge,
    min_bet_amount,
    max_bet_amount,
  ] = queryKey;

  return client
    .get("/tips/summary", {
      params: {
        from,
        to,
        include_ratings,
        include_leagues,
        min_odds,
        max_odds,
        min_edge,
        max_edge,
        min_bet_amount,
        max_bet_amount,
      },
    })
    .then((res) => res.data);
};

export const GET_TIPS_SUMMARY_QUERY_KEY = "get-tips-summary";

export const useGetTipsSummary = (
  args?: TipsQueryArgs,
  options?: Omit<
    UseQueryOptions<GetTipsSummaryResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetTipsSummaryResponse, DefaultErrorResponse> => {
  return useQuery(
    [
      GET_TIPS_SUMMARY_QUERY_KEY,
      args?.from,
      args?.to,
      args?.include_ratings,
      args?.include_leagues || [],
      args?.min_odds,
      args?.max_odds,
      args?.min_edge,
      args?.max_edge,
      args?.min_bet_amount,
      args?.max_bet_amount,
    ],
    fetchTipsSummary,
    options,
  );
};
