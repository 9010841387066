import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type ChatChoice = {
  index: string;
  finish_reason: string;
  message: {
    tool_calls: null;
    role: "assistant" | "user";
    function_call: null;
    content: string;
  };
  logprobs: null;
};

export type ChatAnswer = {
  created: string;
  usage: {
    total_tokens: string;
    completion_tokens: string;
    prompt_tokens: string;
  };
  model: string;
  id: string;
  choices: ChatChoice[];
  system_fingerprint: "fp_76f018034d";
  object: "chat.completion";
};

export type GetChatResponse = {
  id: string;
  answer: ChatAnswer;
  error: string | null;
};

export const getChat: QueryFunction<GetChatResponse> = ({ queryKey }) => {
  const [_key, chat_id] = queryKey;

  return client
    .get("/get-chat", { params: { id: chat_id } })
    .then((res) => res.data);
};

export const GetChatQueryKey = "get-chat";

export const useGetChat = (
  id?: string,
  options?: Omit<
    UseQueryOptions<GetChatResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetChatResponse, DefaultErrorResponse> => {
  return useQuery([GetChatQueryKey, id], getChat, options);
};
