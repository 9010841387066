import React, { FC, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import { copyToClipboard } from "@utils";

import { useCreateExpertOpinions } from "@api/prediction-chain/expertOpinions/create";
import { useDeleteExpertOpinions } from "@api/prediction-chain/expertOpinions/delete";
import {
  GetExpertOpinionsQueryKey,
  useGetExpertOpinions,
} from "@api/prediction-chain/expertOpinions/get";
import { useCreateLineups } from "@api/prediction-chain/lineups/create";
import { useDeleteLineups } from "@api/prediction-chain/lineups/delete";
import {
  GetLineupsQueryKey,
  useGetLineups,
} from "@api/prediction-chain/lineups/get";
import { useCreateMotivations } from "@api/prediction-chain/motivations/create";
import { useDeleteMotivations } from "@api/prediction-chain/motivations/delete";
import {
  GetMotivationsQueryKey,
  useGetMotivations,
} from "@api/prediction-chain/motivations/get";
import { useCreatePlayers } from "@api/prediction-chain/players/create";
import { useDeletePlayers } from "@api/prediction-chain/players/delete";
import {
  GetPlayersQueryKey,
  useGetPlayers,
} from "@api/prediction-chain/players/get";
import { useCreateStandings } from "@api/prediction-chain/standings/create";
import { useDeleteStandings } from "@api/prediction-chain/standings/delete";
import {
  GetStandingsQueryKey,
  useGetStandings,
} from "@api/prediction-chain/standings/get";
import { useCreateStatistics } from "@api/prediction-chain/statistics/create";
import { useDeleteStatistics } from "@api/prediction-chain/statistics/delete";
import {
  GetStatisticsQueryKey,
  useGetStatistics,
} from "@api/prediction-chain/statistics/get";
import { useCreateTeamCharacteristics } from "@api/prediction-chain/teamCharacteristics/create";
import { useDeleteTeamCharacteristics } from "@api/prediction-chain/teamCharacteristics/delete";
import {
  GetTeamCharacteristicsQueryKey,
  useGetTeamCharacteristics,
} from "@api/prediction-chain/teamCharacteristics/get";

import { Table, Box } from "@ui/elements";

import { NextGenTips } from "../NextGenTips/NextGenTips";

import { ChatWindow } from "./ChatWindow";
import { FixtureSelect } from "./FixtureSelect";
import { PredictionChainTableRow } from "./PredictionChainTableRow";

const predictionPrompt = `### Objective:
Your task is to analyze the data from each section thoroughly and derive betting tips categorized into three distinct groups: Safe bets, Value bets, and Risky bets. Each category serves a different purpose and should reflect a varying degree of confidence based on detailed analysis.

#### Categories Explained:

1. Safe Bets: These are bets that our analysis suggests are almost certain to occur. The confidence in these bets should be so high that they can be combined with other safe bets in accumulators.
2. Value Bets: These bets also exhibit a high probability of winning, with most analyses supporting their likelihood. They offer a favorable balance between risk and potential return.
3. Risky Bets: These bets are less certain but have potential high rewards due to favorable odds. They should be considered based on significant but not overwhelming analytical support.

#### Creativity in Tip Selection:
Feel free to be innovative in your selection of tips. Consider a variety of betting markets such as player performances, over/under scores, corners, cards, and other game-specific variables, not just match outcomes or goal totals.

#### Tip Format:
Each tip should be clearly formatted as follows:

Category Name
Tip Name: (Chance of Occurring %)
Reasoning: Explain why this tip is considered under this category based on the analysis.


#### Guidelines for Analysis:
- Ensure each tip is backed by your comprehensive analysis, reviewing all relevant data and previous assessments.
- Maintain conservative judgment when categorizing tips, especially for safe bets, to minimize risk.
- Re-check all analytical segments before finalizing tips to confirm high confidence levels and correct categorization.
`;

const today = dayjs().toDate();

export const ChatComponent: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id") || "");
  const [date, setDate] = useState(
    searchParams.get("date") ? dayjs(searchParams.get("date")).toDate() : today,
  );

  // Motivations
  const getMotivations = useGetMotivations(id, { enabled: !!id });
  const createMotivations = useCreateMotivations();
  const deleteMotivations = useDeleteMotivations();

  // Expert Opinions
  const getExpertOpinions = useGetExpertOpinions(id, {
    enabled: !!id,
  });
  const createExpertOpinions = useCreateExpertOpinions();
  const deleteExpertOpinions = useDeleteExpertOpinions();

  // Standings
  const getStandings = useGetStandings(id, {
    enabled: !!id,
  });
  const createStandings = useCreateStandings();
  const deleteStandings = useDeleteStandings();

  // Characteristics
  const getCharacteristics = useGetTeamCharacteristics(id, {
    enabled: !!id,
  });
  const createCharacteristics = useCreateTeamCharacteristics();
  const deleteCharacteristics = useDeleteTeamCharacteristics();

  // Statistics
  const getStatistics = useGetStatistics(id, {
    enabled: !!id,
  });
  const createStatistics = useCreateStatistics();
  const deleteStatistics = useDeleteStatistics();

  // Players
  const getPlayers = useGetPlayers(id, {
    enabled: !!id,
  });
  const createPlayers = useCreatePlayers();
  const deletePlayers = useDeletePlayers();

  // Lineups
  const getLineups = useGetLineups(id, {
    enabled: !!id,
  });
  const createLineups = useCreateLineups();
  const deleteLineups = useDeleteLineups();

  const chatSteps = useMemo(
    () => [
      {
        name: "Motivations",
        required: true,
        prompt: getMotivations?.data?.data?.prompt,
      },
      {
        name: "Standings",
        required: true,
        prompt: getStandings?.data?.data?.prompt,
      },
      {
        name: "Characteristics",
        required: true,
        prompt: getCharacteristics?.data?.data?.prompt,
      },
      {
        name: "Statistics",
        required: true,
        prompt: getStatistics?.data?.data?.prompt,
      },
      {
        name: "Players",
        required: true,
        prompt: getPlayers?.data?.data?.prompt,
      },
      {
        name: "Lineup",
        required: false,
        prompt: getLineups?.data?.data?.prompt,
      },
      {
        name: "Expert Opinions",
        required: true,
        prompt: getExpertOpinions?.data?.data?.prompt,
      },
      {
        name: "Predictions",
        required: true,
        prompt: predictionPrompt,
      },
    ],
    [
      getExpertOpinions,
      getLineups,
      getPlayers,
      getStatistics,
      getCharacteristics,
      getStandings,
      getMotivations,
    ],
  );

  const singlePrompt = useMemo(() => {
    let outputString = `
      I want you to act as an experienced football analytic, and create a comprehensive analysis touching each point individually and as a whole in order to create a very detailed pre-match analysis.
      After you completed the detailed analysis, act as an expert football tipster focusing on obviously safe tips. You are very conservative and sceptical, and you are focusing on giving tips that has very high chance of occurring.

      Your task is to analyze the data from each section thoroughly and derive betting tips categorized into three distinct groups: Safe bets, Value bets, and Risky bets. Each category serves a different purpose and should reflect a varying degree of confidence based on detailed analysis.

      #### Categories Explained:

      1. Safe Bets: These are bets that our analysis suggests are almost certain to occur. The confidence in these bets should be so high that they can be combined with other safe bets in accumulators.
      2. Value Bets: These bets also exhibit a high probability of winning, with most analyses supporting their likelihood. They offer a favorable balance between risk and potential return.
      3. Risky Bets: These bets are less certain but have potential high rewards due to favorable odds. They should be considered based on significant but not overwhelming analytical support.

      #### Creativity in Tip Selection:
      Feel free to be innovative in your selection of tips. Consider a variety of betting markets such as player performances, over/under scores, corners, cards, and other game-specific variables, not just match outcomes or goal totals.

      #### Tip Format:
      Each tip should be clearly formatted as follows:

      Category Name
      Tip Name: (Chance of Occurring %)
      Reasoning: Explain why this tip is considered under this category based on the analysis.


      #### Guidelines for Analysis:
      - Ensure each tip is backed by your comprehensive analysis, reviewing all relevant data and previous assessments.
      - Maintain conservative judgment when categorizing tips, especially for safe bets, to minimize risk.
      - Re-check all analytical segments before finalizing tips to confirm high confidence levels and correct categorization.
      - Additionally provide at least one super safe bet which is absolutely sure that is going to happen (at least 90%-95% chance to happen). In case you don't find any, just state: "No super safe bets".
      
      Here is the data:
      
      `;

    chatSteps
      .filter((e) => e.name !== "Predictions")
      .forEach(({ prompt }) => {
        outputString += `\n\n${prompt}`;
      });

    return outputString;
  }, [chatSteps]);

  const handleCopyAll = () => {
    copyToClipboard(singlePrompt);
  };

  return (
    <>
      <Box>
        <FixtureSelect
          date={date}
          setDate={(date) => {
            setSearchParams({
              id: searchParams.get("id") || "",
              date: dayjs(date).format("YYYY-MM-DD"),
            });
            setDate(date);
          }}
          value={id}
          setValue={(id) => {
            setSearchParams({
              date: searchParams.get("date")
                ? dayjs(searchParams.get("date")).format("YYYY-MM-DD")
                : "",
              id,
            });
            setId(id);
          }}
        />
      </Box>
      {id && <NextGenTips id={id} />}
      {id && (
        <Box>
          <Table className="mb-0" isFullwidth>
            <PredictionChainTableRow
              title="Motivations"
              create={createMotivations}
              get={getMotivations}
              del={deleteMotivations}
              getQueryKey={GetMotivationsQueryKey}
              id={id}
            />
            <PredictionChainTableRow
              title="Standings"
              create={createStandings}
              get={getStandings}
              del={deleteStandings}
              getQueryKey={GetStandingsQueryKey}
              id={id}
            />
            <PredictionChainTableRow
              title="Characteristics"
              create={createCharacteristics}
              get={getCharacteristics}
              del={deleteCharacteristics}
              getQueryKey={GetTeamCharacteristicsQueryKey}
              id={id}
            />
            <PredictionChainTableRow
              title="Statistics"
              create={createStatistics}
              get={getStatistics}
              del={deleteStatistics}
              getQueryKey={GetStatisticsQueryKey}
              id={id}
            />
            <PredictionChainTableRow
              title="Players"
              create={createPlayers}
              get={getPlayers}
              del={deletePlayers}
              getQueryKey={GetPlayersQueryKey}
              id={id}
            />
            <PredictionChainTableRow
              title="Lineup"
              create={createLineups}
              get={getLineups}
              del={deleteLineups}
              getQueryKey={GetLineupsQueryKey}
              id={id}
            />
            <PredictionChainTableRow
              title="Expert Opinions"
              create={createExpertOpinions}
              get={getExpertOpinions}
              del={deleteExpertOpinions}
              getQueryKey={GetExpertOpinionsQueryKey}
              id={id}
            />
          </Table>
          <div className="buttons">
            <div className="button is-small" onClick={handleCopyAll}>
              Copy All
            </div>
          </div>
        </Box>
      )}
      {chatSteps.filter((e) => e.required).every((e) => !!e.prompt) && (
        <div className="mb-5">
          <ChatWindow
            steps={chatSteps.filter((e) => !!e.prompt)}
            singlePrompt={singlePrompt}
          />
        </div>
      )}
    </>
  );
};
