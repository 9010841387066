import React, { FC } from "react";

import { formatCurrency, formatHitRate, formatOdds } from "@utils";

import { UserTipsSummaryResponse } from "@api/user/getTipsSummary";

import { Box, IconCard, Text, Title } from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

type MyBetsSummaryCardsProps = {
  isLoading?: boolean;
  data?: UserTipsSummaryResponse;
};

export const MyBetsSummaryCards: FC<MyBetsSummaryCardsProps> = ({
  isLoading,
  data,
}) => {
  if (!isLoading && !data?.bankroll_over_time.length && !data?.avg_odds) {
    return null;
  }

  return (
    <Grid type="fixed" cols={{ mobile: 2, tablet: 2, fullhd: 3 }}>
      <Cell colSpan={{ mobile: 2, tablet: 2, fullhd: 1 }}>
        <Box isFullwidth>
          <Stack align="center">
            <IconCard icon="Coin" />
            <Stack direction="column" gap="none">
              <Text size="small">Profit/Loss</Text>
              <Title size={5} noWrap isLoading={isLoading}>
                {formatCurrency(
                  data?.profit_loss,
                  data?.currency?.code,
                  data?.currency?.locale,
                  true,
                )}
              </Title>
            </Stack>
          </Stack>
        </Box>
      </Cell>
      <Cell>
        <Box isFullwidth>
          <Stack align="center">
            <IconCard icon="Target" />
            <Stack direction="column" gap="none">
              <Text size="small">Hit Rate</Text>
              <Title size={5} isLoading={isLoading}>
                {formatHitRate(data?.hit_rate)}
              </Title>
            </Stack>
          </Stack>
        </Box>
      </Cell>
      <Cell>
        <Box isFullwidth>
          <Stack align="center">
            <IconCard icon="Scales" />
            <Stack direction="column" gap="none">
              <Text size="small">Avg. Odds</Text>
              <Title size={5} noWrap isLoading={isLoading}>
                {formatOdds(data?.avg_odds)}
              </Title>
            </Stack>
          </Stack>
        </Box>
      </Cell>
    </Grid>
  );
};
