import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { TipsQueryArgs } from "@api/math-tips";
import {
  getTipsFilterQueryArgs,
  parseTipsFilterParams,
} from "@api/math-tips/utils";

import { DefaultErrorResponse } from "../../index";
import { client, Currency } from "../client";

type SummaryBankrollOverTime = {
  tip: string;
  bankroll: string;
};

export type UserTipsSummaryResponse = {
  roi: string;
  avg_odds: string;
  avg_probability: string;
  hit_rate: string;
  total_won: string;
  total_lost: string;
  total_matches: string;
  total_tips: string;
  profit_loss: string;
  currency?: Currency;
  bankroll_over_time: SummaryBankrollOverTime[];
};

export const fetchUserTipsSummary: QueryFunction<UserTipsSummaryResponse> = ({
  queryKey,
}) => {
  const { params } = parseTipsFilterParams(queryKey);

  return client.get("/tips-summary", { params }).then((res) => res.data);
};

export const GET_USER_TIPS_SUMMARY_QUERY_KEY = "get-user-tips-summary";

export const useGetUserTipsSummary = (
  args?: TipsQueryArgs,
  options?: Omit<
    UseQueryOptions<UserTipsSummaryResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<UserTipsSummaryResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_USER_TIPS_SUMMARY_QUERY_KEY, ...getTipsFilterQueryArgs(args)],
    fetchUserTipsSummary,
    options,
  );
};
