import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useDevice } from "@utils";

import { League, TipItem } from "@api/math-tips";
import { useGetUserTips } from "@api/user/getTips";
import { useGetUserTipsSummary } from "@api/user/getTipsSummary";

import { InfoBox, TitleBlock } from "@ui/components";
import { Column, Columns, Stack } from "@ui/layout";

import {
  DataTable,
  SubscriptionWrapper,
  TipCard,
  TipCardSkeleton,
  TipFilter,
  TipFilterValues,
  TipsTableContext,
  TipTableColumnDefs,
  useTipFilterDefaultValues,
} from "@components";

import "./MyBets.scss";
import { MyBetsSummary } from "./MyBetsSummary";
import { MyBetsSummaryCards } from "./MyBetsSummaryCards";

dayjs.extend(isoWeek);

export const MyBets = () => {
  const navigate = useNavigate();
  const defaultValues = useTipFilterDefaultValues("my-bets", {
    timePeriod: "today",
    enabledFilters: ["tipStatus"],
    tip_status: ["won", "lost", "unsettled"],
  });

  const [filterValues, setFilterValues] =
    useState<TipFilterValues>(defaultValues);
  const form = useForm<TipFilterValues>({
    defaultValues,
  });
  const [availableLeagues, setAvailableLeagues] = useState<League[]>([]);

  const { data, isLoading: userTipsLoading } = useGetUserTips(filterValues);

  const { data: summaryData, isLoading: summaryLoading } =
    useGetUserTipsSummary(filterValues);

  useEffect(() => {
    if (data?.available_leagues) {
      setAvailableLeagues(data?.available_leagues || []);
    }
  }, [data?.available_leagues]);

  const device = useDevice();

  return (
    <SubscriptionWrapper>
      <Stack direction="column" gap="xxl">
        <MyBetsSummary />
        <Stack gap="sm" direction="column">
          <TitleBlock
            title="My Tips List"
            subtitle={
              (!!data?.total_tips && (
                <>
                  Showing <strong>{data?.total_tips}</strong> tips from{" "}
                  <strong>{data?.total_items}</strong> matches
                </>
              )) ||
              null
            }
          />
          <Columns isMultiline>
            <Column tablet={12} widescreen={5} fullhd={4}>
              <TipFilter
                form={form}
                onSubmit={setFilterValues}
                id="my-bets"
                leagueOptions={availableLeagues?.map((league) => ({
                  label: league?.name,
                  value: league?.id,
                }))}
                enabledTimePeriods={[
                  "tomorrow",
                  "today",
                  "yesterday",
                  "last-7-days",
                  "last-30-days",
                  "custom",
                ]}
                allowedFilters={{
                  betAmount: false,
                  edge: false,
                  leagues: true,
                  odds: true,
                  tipRating: true,
                  tipStatus: true,
                }}
              />
            </Column>
            <Column tablet={12} widescreen={7} fullhd={8}>
              <Stack isFullwidth direction="column" gap="xxl">
                <MyBetsSummaryCards
                  data={summaryData}
                  isLoading={summaryLoading}
                />
                <Stack gap="md" direction="column">
                  {!summaryLoading &&
                    !userTipsLoading &&
                    !data?.data.length && (
                      <InfoBox
                        icon="SmileyXEyes"
                        hasSpacing
                        title="Saved tips not found ..."
                        primaryButtonAction={() => navigate("/upcoming-tips")}
                        primaryButtonLabel="View Upcoming Tips"
                        secondaryButtonLabel="Reset Filters"
                        secondaryButtonAction={() =>
                          form.reset({
                            enabledFilters: ["tipStatus"],
                            timePeriod: "today",
                            tip_status: ["won", "lost", "unsettled"],
                          })
                        }
                      >
                        We couldn&apos;t find any tips within your portfolio.{" "}
                        <br />
                        Explore upcoming tips or modify filters.
                      </InfoBox>
                    )}

                  {userTipsLoading && (
                    <>
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                    </>
                  )}
                  {data?.data?.map(({ fixture, tips }) => (
                    <TipCard
                      fixture={fixture}
                      key={`my-bets-tip-card-${fixture.fixture.id}`}
                    >
                      <DataTable<TipItem, TipsTableContext>
                        context={{ fixture, currency: data?.currency }}
                        columnDefs={
                          device === "mobile"
                            ? [
                                TipTableColumnDefs.tip,
                                TipTableColumnDefs.ratingPercentage,
                                TipTableColumnDefs.odds,
                                TipTableColumnDefs.betAmount,
                                TipTableColumnDefs.profitLoss,
                                TipTableColumnDefs.saveTip,
                              ]
                            : [
                                TipTableColumnDefs.tip,
                                TipTableColumnDefs.rating,
                                TipTableColumnDefs.probability,
                                TipTableColumnDefs.odds,
                                TipTableColumnDefs.betAmount,
                                TipTableColumnDefs.profitLoss,
                                TipTableColumnDefs.saveTip,
                              ]
                        }
                        data={tips}
                      />
                    </TipCard>
                  ))}
                </Stack>
              </Stack>
            </Column>
          </Columns>
        </Stack>
      </Stack>
    </SubscriptionWrapper>
  );
};
