import React, { FC } from "react";

import "./Divider.scss";
import classNames from "classnames";

type DividerProps = {
  direction?: "vertical" | "horizontal";
  text?: string;
};

export const Divider: FC<DividerProps> = ({
  direction = "horizontal",
  text,
}) => {
  return (
    <div className={classNames("divider", `is-${direction}`)}>
      {text && <span>{text}</span>}
    </div>
  );
};
