import { TipCategory } from "@api/math-tips";

export const formatTipName = (category: TipCategory, name: string) => {
  if (category === "Total Goals Over/Under") {
    return `${name} Goals`;
  }

  if (category === "Both Teams to Score") {
    if (name === "Yes") {
      return "Both Teams to Score";
    }

    if (name === "No") {
      return "Both Teams to Score - No";
    }
  }

  return name;
};
