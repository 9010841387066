import React, {
  createContext,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

import classNames from "classnames";

import { Icon, Icons } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./Menu.scss";

type MenuContextType = {
  activeMenu: string;
  setActiveMenu: (tab: string) => void;
};

const MenuContext = createContext<MenuContextType | undefined>(undefined);

type MenuItemProps = PropsWithChildren<{
  icon?: Icons;
  value?: string;
}>;

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error("useMenu must be used within a MenuContext provider");
  }
  return context;
};

export const MenuItem: FC<MenuItemProps> = ({ children, icon, value = "" }) => {
  const { activeMenu, setActiveMenu } = useMenu();

  return (
    <li>
      <a
        onClick={() => setActiveMenu(value)}
        className={classNames({ "is-active": activeMenu === value })}
      >
        <Stack align="center" gap="sm">
          {icon && <Icon icon={icon} />}
          <span>{children}</span>
        </Stack>
      </a>
    </li>
  );
};

type MenuProps = PropsWithChildren<{
  label?: string;
  value?: string;
  onChange?: (menu: string) => void;
}>;

export const Menu: FC<MenuProps> = ({ value = "", onChange, children }) => {
  const [activeMenu, setActiveMenu] = useState(value);

  const handleMenuChange = (menu: string) => {
    setActiveMenu(menu);
    onChange?.(menu);
  };

  return (
    <MenuContext.Provider
      value={{ activeMenu, setActiveMenu: handleMenuChange }}
    >
      {children}
    </MenuContext.Provider>
  );
};

type MenuListProps = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> &
  PropsWithChildren<{
    label?: string;
  }>;

export const MenuList: FC<MenuListProps> = ({
  className,
  label,
  children,
  ...rest
}) => {
  return (
    <aside className={classNames("menu", className)} {...rest}>
      {label && <p className="menu-label">{label}</p>}
      <ul className="menu-list">{children}</ul>
    </aside>
  );
};

type MenuPanelProps = PropsWithChildren<{
  value: string;
}>;

export const MenuPanel: React.FC<MenuPanelProps> = ({ value, children }) => {
  const { activeMenu } = useMenu();
  return (
    <div className={classNames({ "is-hidden": activeMenu !== value })}>
      {children}
    </div>
  );
};
