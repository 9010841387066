import { useMemo } from "react";

import dayjs from "dayjs";
import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";
import { FixtureItem } from "../types/fixture";

export type LeagueGroup = {
  name: string;
  id: number;
  fixtures: FixtureItem[];
};

export const fetchFixturesByLeagueIds: QueryFunction<LeagueGroup[]> = async ({
  queryKey,
}) => {
  const [_key, date] = queryKey;

  return client
    .get("/get-fixtures", { params: { date } })
    .then((res) => res.data);
};

export const GetFixturesQueryKey = "get-league-fixtures";

export const useGetLeagueFixtures = (
  date: Date,
  options?: Omit<
    UseQueryOptions<LeagueGroup[], DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<LeagueGroup[], DefaultErrorResponse> => {
  const formattedDate = useMemo(() => {
    return dayjs(date).format("YYYY-MM-DD");
  }, [date]);

  return useQuery(
    [GetFixturesQueryKey, formattedDate],
    fetchFixturesByLeagueIds,
    options,
  );
};
