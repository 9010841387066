import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { TipRating, TipCategory } from "@api/math-tips";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type CategoryPerformanceItem = {
  category: TipCategory;
  roi: string;
  hit_rate: string;
  total_tips: string;
};

export type RatingPerformanceItem = {
  rating: TipRating;
  roi: string;
  hit_rate: string;
  total_tips: string;
};

export type LeaguePerformanceItem = {
  league_id: string;
  roi: string;
  hit_rate: string;
  total_tips: string;
  league: {
    id: string;
    name: string;
    type: string;
    logo: string;
  };
  country: {
    name: string;
    code: string | null;
    flag: string | null;
  };
};

export type TopPerformersResponse = {
  category_performance: CategoryPerformanceItem[];
  rating_performance: RatingPerformanceItem[];
  league_performance: LeaguePerformanceItem[];
};

export type TopPerformersArgs = {
  from?: string;
  to?: string;
};

const fetchTopPerformers: QueryFunction<TopPerformersResponse> = ({
  queryKey,
}) => {
  const [_key, from, to] = queryKey;

  return client
    .get("/top-performers", { params: { from, to } })
    .then((res) => res.data);
};

export const TOP_PERFORMERS_QUERY_KEY = "get-top-performers";

export const useGetTopPerformers = (
  args?: TopPerformersArgs,
  options?: Omit<
    UseQueryOptions<TopPerformersResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<TopPerformersResponse, DefaultErrorResponse> => {
  return useQuery(
    [TOP_PERFORMERS_QUERY_KEY, args?.from, args?.to],
    fetchTopPerformers,
    options,
  );
};
