import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { useAuth } from "@components";

import { client } from "../client";

export type SubscriptionItem = {
  id: string;
  price: number;
  interval: "week" | "month";
  currency: "string";
  payment_link: "string";
  active_subscription: boolean;
  cancels_at_date?: string;
};

export type GetSubscriptionsResponse = {
  subscriptions?: SubscriptionItem[];
  manage_subscription_link?: string;
  has_active_subscription?: boolean;
  update_subscription_link?: string;
  renew_subscription_link?: string;
};

const fetchSubscriptions: QueryFunction<GetSubscriptionsResponse> = ({
  queryKey,
}) => {
  const [_key] = queryKey;

  return client.get("/get-subscriptions").then((res) => res.data);
};

export const GET_SUBSCRIPTIONS_QUERY_KEY = "get-subscriptions";

export const useGetSubscriptions = (
  options?: Omit<
    UseQueryOptions<
      GetSubscriptionsResponse,
      DefaultErrorResponse,
      GetSubscriptionsResponse,
      string[]
    >,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetSubscriptionsResponse, DefaultErrorResponse> => {
  const context = useAuth();

  return useQuery([GET_SUBSCRIPTIONS_QUERY_KEY], fetchSubscriptions, {
    ...options,
    enabled: !!context.isAuthenticated,
  });
};
