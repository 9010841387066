import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client, Currency } from "../client";

export type UserPreferencesResponse = {
  id: string;
  kellyFraction: string;
  currency: Currency;
  has_active_subscription: boolean;
  allowResultOrientedV2: boolean;
};

const fetchTips: QueryFunction<UserPreferencesResponse> = ({ queryKey }) => {
  const [_key] = queryKey;

  return client.get("/preferences").then((res) => res.data);
};

export const GET_USER_PREFERENCES_QUERY_KEY = "get-user-preferences";

export const useUserPreferences = (
  options?: Omit<
    UseQueryOptions<UserPreferencesResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<UserPreferencesResponse, DefaultErrorResponse> => {
  return useQuery([GET_USER_PREFERENCES_QUERY_KEY], fetchTips, options);
};
