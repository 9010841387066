import React, { FC, PropsWithChildren, ReactElement } from "react";

import { Link } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./Sidebar.scss";

type SidebarProps = PropsWithChildren<{
  header?: ReactElement | "string";
  footerLink?: string;
  onFooterLinkClick?: () => void;
}>;

export const Sidebar: FC<SidebarProps> = ({
  header,
  footerLink,
  onFooterLinkClick,
  children,
}) => {
  return (
    <div className="sidebar">
      <Stack gap="sm" align="center" className="sidebar__header">
        {header}
      </Stack>
      <div className="sidebar__body">{children}</div>
      {footerLink && (
        <div className="sidebar__footer">
          <Link size="small" onClick={onFooterLinkClick}>
            {footerLink}
          </Link>
        </div>
      )}
    </div>
  );
};
