import React, { FC, PropsWithChildren } from "react";

import "./Subpage.scss";

import { Content } from "@ui/elements";
import { Column, Columns, Container } from "@ui/layout";

type SubpageTemplateProps = PropsWithChildren<{
  title?: string;
}>;

export const SubpageTemplate: FC<SubpageTemplateProps> = ({ children }) => {
  return (
    <div className="subpage">
      <Container>
        <Columns isCentered>
          <Column size={8}>
            <Content>{children}</Content>
          </Column>
        </Columns>
      </Container>
    </div>
  );
};
