import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateExpertOpinionsResponse = {
  id: number;
  success: boolean;
};

type CreateExpertOpinionsArgs = {
  id: string | number;
};

export const createExpertOpinions: MutationFunction<
  CreateExpertOpinionsResponse,
  CreateExpertOpinionsArgs
> = (props) => {
  return client
    .post(`/expert-opinions`, { id: props.id })
    .then((res) => res.data);
};

export const CreateExpertOpinionsMutationKey = "create-expert-opinions";

export const useCreateExpertOpinions = (
  params?: CreateExpertOpinionsArgs,
  options?: Omit<
    UseMutationOptions<
      CreateExpertOpinionsResponse,
      DefaultErrorResponse,
      CreateExpertOpinionsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateExpertOpinionsResponse,
  DefaultErrorResponse,
  CreateExpertOpinionsArgs
> => {
  return useMutation(
    [CreateExpertOpinionsMutationKey, params?.id],
    createExpertOpinions,
    options,
  );
};
