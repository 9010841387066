import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { signUp } from "aws-amplify/auth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { withController } from "@utils";

import { useValidateRecaptcha } from "@api/cognito/validateRecaptcha";

import { Button, Divider, Notification, Text } from "@ui/elements";
import { Checkbox, Input } from "@ui/forms";
import { Cell, Grid, Stack } from "@ui/layout";

import { useAuth } from "@components";

import { SocialLogin } from "../../components/SocialLogin/SocialLogin";

type RegisterFields = {
  email: string;
  password: string;
  confirmPassword: string;
  given_name: string;
  last_name: string;
  termsAndConditions?: boolean | undefined;
  marketingUpdates?: boolean | undefined;
};

const ControlledTextInput = withController<RegisterFields>()(Input);
const ControlledCheckbox = withController<RegisterFields>()(Checkbox);

const formSchema = Yup.object().shape({
  given_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name name is required"),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "It is required to accept Terms and Conditions.",
  ),
  marketingUpdates: Yup.boolean(),
  email: Yup.string().required("E-mail is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

export const SignUp = () => {
  const [searchParams] = useSearchParams();

  const { control, handleSubmit } = useForm<RegisterFields>({
    defaultValues: {
      given_name: "",
      last_name: "",
      password: "",
      confirmPassword: "",
      email: searchParams.get("email") || "",
      termsAndConditions: false,
    },
    resolver: yupResolver(formSchema),
  });

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: validateRecaptcha, isLoading: recaptchaLoading } =
    useValidateRecaptcha();
  const navigate = useNavigate();
  const authContext = useAuth();

  useEffect(() => {
    if (authContext.isAuthenticated === true) {
      navigate("/");
    }
  }, [authContext.isAuthenticated]);

  const onSubmit = async (values: RegisterFields) => {
    setLoading(true);
    setError("");

    // Registration
    try {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("signup");
      const recaptchaResponse = await validateRecaptcha({ token });

      if (!recaptchaResponse.success) {
        setError("Failed to validate reCAPTCHA. Please try again.");
        return;
      }

      await signUp({
        username: values.email,
        password: values.password,
        options: {
          userAttributes: {
            family_name: values.last_name,
            given_name: values.given_name,
            name: `${values.given_name} ${values.last_name}`,
            "custom:platformUpdates": "true",
            "custom:feedbackSurvey": "true",
            "custom:marketingEmails": String(!!values.marketingUpdates),
            "custom:newsLetters": String(!!values.marketingUpdates),
          },
        },
      });

      const params = new URLSearchParams(location.search);

      params.set("email", values.email);

      navigate({
        pathname: "/confirm-email",
        search: `?${params.toString()}`,
      });
    } catch (error) {
      setLoading(false);

      if (error instanceof Error) {
        return setError(
          error.message.replace("PreSignUp failed with error ", ""),
        );
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  const errorMessage = searchParams.get("error_description") || error;
  const loadingState = recaptchaLoading || loading;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Football Genie - Login</title>
      </Helmet>
      <Stack direction="column" gap="xxl">
        <Stack direction="column" gap="xl">
          <Stack direction="column" gap="xl">
            <Stack direction="column" gap="xl">
              <Stack direction="column">
                <ControlledTextInput
                  label="E-mail"
                  name="email"
                  type="email"
                  control={control}
                  autoComplete="username"
                />
                <Grid>
                  <Cell>
                    <ControlledTextInput
                      label="First name"
                      control={control}
                      name="given_name"
                      autoComplete="given-name"
                      type="text"
                    />
                  </Cell>
                  <Cell>
                    <ControlledTextInput
                      label="Last name"
                      control={control}
                      name="last_name"
                      type="text"
                      autoComplete="family-name"
                    />
                  </Cell>
                </Grid>
              </Stack>
              <Divider />
              <Stack direction="column">
                <ControlledTextInput
                  label="Password"
                  control={control}
                  name="password"
                  type="password"
                  autoComplete="new-password"
                />
                <ControlledTextInput
                  label="Confirm password"
                  control={control}
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                />
                <ControlledCheckbox
                  control={control}
                  name="marketingUpdates"
                  isCentered={false}
                >
                  <Text size="small">
                    I agree to receive marketing communications and newsletters,
                    which include special offers, product announcements,
                    industry news, tips, and user stories.
                  </Text>
                </ControlledCheckbox>
                <ControlledCheckbox
                  control={control}
                  name="termsAndConditions"
                  isCentered={false}
                >
                  <Text size="small">
                    By registering, I agree to the{" "}
                    <a href="/terms-and-conditions" target="_blank">
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a href="/privacy-policy" target="_blank">
                      Privacy Policy
                    </a>{" "}
                    and consent to receive emails related to services, updates,
                    and surveys.
                  </Text>
                </ControlledCheckbox>
              </Stack>
              <Stack direction="column">
                {errorMessage && (
                  <Notification color="danger">{errorMessage}</Notification>
                )}
                <Button color="primary" type="submit" isLoading={loadingState}>
                  Create Account
                </Button>
                <Link to="/login">
                  <Button color="text" isFullwidth>
                    Back to Login
                  </Button>
                </Link>
              </Stack>
            </Stack>

            <Divider text="or" />

            <SocialLogin />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
