import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

export type TipAnalysisRiskItem = {
  level: "Low" | "Moderate" | "High";
  reasoning: string;
  title: string;
};

export type TipAnalysisResponse = {
  id: string;
  reasoning: string;
  risks: TipAnalysisRiskItem[];
};

type TipAnalysisQueryArgs = {
  id: string;
};

export const fetchTipAnalysis: QueryFunction<TipAnalysisResponse> = ({
  queryKey,
}) => {
  const [_key, id] = queryKey;

  return client
    .get("/tip-analysis", { params: { id } })
    .then((res) => res.data);
};

export const FETCH_TIP_ANALYSIS_QUERY_KEY = "fetch-tip-analysis";

export const useGetTipAnalysis = (
  args?: TipAnalysisQueryArgs,
  options?: Omit<
    UseQueryOptions<TipAnalysisResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<TipAnalysisResponse, DefaultErrorResponse> => {
  return useQuery(
    [FETCH_TIP_ANALYSIS_QUERY_KEY, args?.id],
    fetchTipAnalysis,
    options,
  );
};
