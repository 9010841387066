import React from "react";

import { Divider } from "@ui/elements";

import {
  useMatchDetails,
  GoalScoringTrendChart,
  RecentStatistics,
  Rankings,
} from "@components";

export const KeyStatisticsTab = () => {
  const { fixture } = useMatchDetails();

  return (
    <>
      <RecentStatistics fixture={fixture} />
      <Divider />
      <GoalScoringTrendChart fixture={fixture} />
      <Divider />
      <Rankings fixture={fixture} />
    </>
  );
};
