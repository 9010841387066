import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { League, TipItem, TipsQueryArgs } from "@api/math-tips";
import {
  getTipsFilterQueryArgs,
  parseTipsFilterParams,
} from "@api/math-tips/utils";

import { FixtureItem } from "../../football/types/fixture";
import { DefaultErrorResponse } from "../../index";
import { client, Currency } from "../client";

export type UserTipsResponseItem = {
  fixture: FixtureItem;
  tips: TipItem[];
};

type GetUserTipsResponse = {
  page: string;
  limit: string;
  total_items: string;
  total_tips: string;
  total_pages: string;
  currency: Currency;
  available_leagues: League[];
  data: UserTipsResponseItem[];
};

export const fetchUserTips: QueryFunction<GetUserTipsResponse> = ({
  queryKey,
}) => {
  const { params } = parseTipsFilterParams(queryKey);

  return client.get("/tips", { params }).then((res) => res.data);
};

export const GET_USER_TIPS_QUERY_KEY = "get-user-tips";

export const useGetUserTips = (
  args?: TipsQueryArgs,
  options?: Omit<
    UseQueryOptions<GetUserTipsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetUserTipsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_USER_TIPS_QUERY_KEY, ...getTipsFilterQueryArgs(args)],
    fetchUserTips,
    options,
  );
};
