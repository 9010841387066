import React, { FC, useContext } from "react";

import dayjs from "dayjs";
import { Calendar } from "react-date-range";

import { StepComponentCommonProps } from "../../StepComponent/StepComponent";
import { TipValidatorContext, ValidatorSteps } from "../TipValidator";

export const DateSelect: FC<StepComponentCommonProps<ValidatorSteps>> = ({
  goToStep,
  active,
}) => {
  const {
    form: { setValue, watch },
  } = useContext(TipValidatorContext);
  const date = watch("date");

  if (!active) {
    return <p>{dayjs(date).format("YYYY-MM-DD")}</p>;
  }

  return (
    <div>
      <div className="field">
        <Calendar
          className="fixture-date-calendar"
          date={date}
          onChange={(date) => setValue("date", date)}
        />
      </div>
      <div className="field">
        <div className="control">
          <button
            type="button"
            className="button is-secondary"
            disabled={!date}
            onClick={() => {
              goToStep("fixture");
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
