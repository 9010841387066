import { AxiosError } from "axios";
import { QueryFunction, useQuery, UseQueryResult } from "react-query";

import { client } from "../client";

type OddValue = {
  value: string;
  odd: string;
};

export type FetchOddsResponse = {
  id: number;
  name: string;
  values: OddValue[];
};

export const fetchOdds: QueryFunction<FetchOddsResponse[]> = ({ queryKey }) => {
  const [_key, id] = queryKey;

  return client.get("/get-odds", { params: { id } }).then((res) => res.data);
};

export const useOdds = (
  id?: string | number,
): UseQueryResult<FetchOddsResponse[], AxiosError> => {
  const response = useQuery<FetchOddsResponse[], AxiosError>(
    ["get-odds", id],
    fetchOdds,
  );

  return response;
};
