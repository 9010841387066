import React, { useState } from "react";

import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { useDevice } from "@utils";

import { TipItem, TipRating, useGetUpcomingTips } from "@api/math-tips";

import { InfoBox, TitleBlock } from "@ui/components";
import { Button } from "@ui/elements";
import { ButtonSelect } from "@ui/forms";
import { Stack } from "@ui/layout";

import {
  TipCard,
  TipCardSkeleton,
  TipsTableContext,
  TipTableColumnDefs,
  DataTable,
  SubscriptionWrapper,
} from "@components";

import "./UpcomingTipsPanel.scss";

export const UpcomingTipsPanel = () => {
  const [tipRating, setRating] = useState<TipRating>("safe");
  const { data: upcomingTipsData, isLoading: upcomingTipsLoading } =
    useGetUpcomingTips({
      from: dayjs().format("YYYY-MM-DD"),
      to: dayjs().add(2, "day").format("YYYY-MM-DD"),
      include_ratings: [tipRating],
    });

  const device = useDevice();

  return (
    <Stack direction="column" gap="md" align="start" isFullwidth>
      <TitleBlock title="Upcoming Tips">
        <ButtonSelect
          isGrouped={false}
          options={["safe", "value", "risky"].map((rating) => ({
            label: rating,
            value: rating,
          }))}
          value={tipRating}
          onChange={(rating) => setRating(rating as TipRating)}
        />
      </TitleBlock>
      <Stack
        direction="column"
        gap="xl"
        className="upcoming-tips-panel-wrapper"
        isFullwidth
      >
        <SubscriptionWrapper>
          {!upcomingTipsLoading && !upcomingTipsData?.data.length && (
            <InfoBox
              icon="SmileyXEyes"
              hasSpacing
              title="Upcoming tips not found ..."
            >
              We couldn&apos;t find any tips with your filter criteria. <br />
              Come back later or modify your search.
            </InfoBox>
          )}
          {upcomingTipsLoading && (
            <>
              <TipCardSkeleton />
              <TipCardSkeleton />
            </>
          )}
          {upcomingTipsData?.data.slice(0, 3).map(({ fixture, tips }) => (
            <TipCard
              fixture={fixture}
              key={`upcoming-tip-card-${fixture.fixture.id}`}
            >
              <DataTable<TipItem, TipsTableContext>
                context={{ fixture }}
                columnDefs={
                  device === "mobile"
                    ? [
                        TipTableColumnDefs.tip,
                        TipTableColumnDefs.ratingPercentage,
                        TipTableColumnDefs.userOdds,
                        TipTableColumnDefs.userBetAmount,
                        TipTableColumnDefs.saveTip,
                      ]
                    : [
                        TipTableColumnDefs.tip,
                        TipTableColumnDefs.ratingPercentage,
                        TipTableColumnDefs.userOdds,
                        TipTableColumnDefs.userBetAmount,
                        TipTableColumnDefs.saveTip,
                      ]
                }
                data={tips}
              />
            </TipCard>
          ))}
        </SubscriptionWrapper>
      </Stack>
      <Link to="/upcoming-tips">
        <Button variant="text">Show All</Button>
      </Link>
    </Stack>
  );
};
