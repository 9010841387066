import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type StandingItem = {
  all: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  away: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  form: string;
  rank: string;
  description: string;
  update: string;
  team: {
    name: string;
    logo: string;
    id: string;
  };
  goalsDiff: string;
  points: string;
  group: string;
  status: string;
  home: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
};

type LineupsData = {
  id: string;
  standings: {
    home: StandingItem;
    away: StandingItem;
  };
  prompt?: string;
};

export type GetLineupsResponse = {
  error: string | null;
  data: LineupsData | null;
};

export const getLineups: QueryFunction<GetLineupsResponse> = ({ queryKey }) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/lineups", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetLineupsQueryKey = "get-fixture-lineups";

export const useGetLineups = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetLineupsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetLineupsResponse, DefaultErrorResponse> => {
  return useQuery([GetLineupsQueryKey, id], getLineups, options);
};
