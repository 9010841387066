import { CurrencyInputProps, formatValue } from "react-currency-input-field";
import { FormatValueOptions } from "react-currency-input-field/dist/components/utils";

import { roundNumber } from "./roundNumber";

const localeOptions: {
  [key: string]: Partial<FormatValueOptions & CurrencyInputProps>;
} = {
  "hu-HU": {
    decimalScale: 0,
    groupSeparator: " ",
    decimalsLimit: 0,
    allowDecimals: false,
  },
};

export const getLocaleOptionConfig = (
  locale: string,
): Partial<FormatValueOptions> => {
  return localeOptions[locale] || {};
};

export const formatCurrency = (
  amount?: string | number,
  currencyCode: string = "EUR",
  locale: string = "de-DE",
  addPrefix?: boolean,
): string => {
  const numAmount = roundNumber(Number(amount));

  if (isNaN(numAmount) || amount === null) {
    return "-";
  }

  let prefix = "";

  if (addPrefix && numAmount > 0) {
    prefix = "+";
  }

  const formattedValue = formatValue({
    value: String(numAmount),
    intlConfig: {
      locale,
      currency: currencyCode,
    },
    ...getLocaleOptionConfig(locale),
  });

  return `${prefix}${formattedValue}`;
};
