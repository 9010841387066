import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import "./ChatWindow.scss";
import {
  faArrowsRotate,
  faPaperPlane,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { useForm } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { ImageComponent } from "@ui/elements";

import { useAuth } from "@components";

import {
  ChatAnswer,
  useGetChat,
} from "../../../../services/api/prediction-chain/chat/get";
import {
  ConversationItem,
  useCreateChat,
} from "../../../../services/api/prediction-chain/chat/init";

type ChatMessageProps = {
  loading?: boolean;
  answer?: ChatAnswer;
  role: "assistant" | "user";
  content?: string;
  error?: string;
  id: string;
  onRetry?: () => void;
  onUserRefresh?: (chatId: string) => void;
  onDelete?: () => void;
  enableRefresh?: boolean;
  enableDelete?: boolean;
};

export const ChatMessage: FC<ChatMessageProps> = ({
  role,
  content,
  answer,
  loading,
  error,
  onRetry,
  enableRefresh,
  onUserRefresh,
  enableDelete,
  onDelete,
  id,
}) => {
  const context = useAuth();
  const userName = useMemo(() => {
    return `${context.userAttributes?.given_name} ${context.userAttributes?.family_name}`;
  }, [context]);

  const contents = useMemo(() => {
    if (answer) {
      return answer.choices.map((e) => e.message.content);
    }

    return [content];
  }, [answer, content]);

  return (
    <div
      className={classNames([
        "chat-message",
        { "chat-message--user": role === "user" },
        { "chat-message--system": role === "assistant" },
      ])}
    >
      <div className="chat-message__header">
        <div className="chat-message__avatar">
          {role === "assistant" && <ImageComponent src="/logo.png" size={32} />}
          {role === "user" && (
            <div className="chat-message__user-avatar">
              {userName.substring(0, 1)}
            </div>
          )}
        </div>
        <div className="chat-message__title">
          {role === "assistant" && "Football Genie AI"}
          {role === "user" && userName}
        </div>
      </div>
      {loading && (
        <div className="type-loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      {error && (
        <>
          <p className="help is-danger mb-2">
            <strong className="has-text-danger">Error in system:</strong>
            <br />
            {error}
          </p>
          <button
            type="button"
            onClick={onRetry}
            className="button is-small is-secondary is-light is-outlined"
          >
            Retry
          </button>
        </>
      )}
      {contents.length === 1 && contents[0] && (
        <ReactMarkdown
          className="chat-message__content content is-small"
          remarkPlugins={[remarkGfm]}
        >
          {contents[0]}
        </ReactMarkdown>
      )}
      {/*{contents.length > 1 && (*/}
      {/*  <Tab*/}
      {/*    activeIndex={selectedIndex}*/}
      {/*    onChange={(i) => setSelectedIndex(i)}*/}
      {/*    items={contents.map((content, index) => ({*/}
      {/*      title: `${index + 1}`,*/}
      {/*      content: (*/}
      {/*        <ReactMarkdown*/}
      {/*          className="chat-message__content content is-small"*/}
      {/*          remarkPlugins={[remarkGfm]}*/}
      {/*        >*/}
      {/*          {content}*/}
      {/*        </ReactMarkdown>*/}
      {/*      ),*/}
      {/*    }))}*/}
      {/*  />*/}
      {/*)}*/}
      {(enableRefresh || enableDelete) && (
        <div className="chat-message__footer">
          {enableRefresh && (
            <button
              type="button"
              className="button is-small is-white"
              onClick={() => onUserRefresh?.(id)}
            >
              <FontAwesomeIcon icon={faArrowsRotate} />
            </button>
          )}
          {enableDelete && (
            <button
              type="button"
              className="button is-small is-white"
              onClick={onDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

type MessageFields = {
  query: string;
  number: number;
};

type ChatStepItem = {
  name: string;
  required: boolean;
  prompt?: string;
};

type ChatWindowProps = {
  steps: ChatStepItem[];
  singlePrompt: string;
};

type ConversationItemWithId = ConversationItem & {
  id: string;
  answer?: ChatAnswer;
};

export const ChatWindow: FC<ChatWindowProps> = ({ steps, singlePrompt }) => {
  const { handleSubmit, register, reset, watch } = useForm<MessageFields>({
    defaultValues: { query: "", number: 1 },
  });
  const [autoPlay, setAutoPlay] = useState(false);
  const query = watch("query");
  const number = watch("number");
  const [activeStep, setActiveStep] = useState(-1);
  const bodyElement = useRef<HTMLDivElement | null>(null);
  const [chatId, setChatId] = useState("");
  const {
    mutateAsync: createChat,
    isLoading: chatLoading,
    error: chatError,
  } = useCreateChat();
  const { data: getChatData, error: getChatError } = useGetChat(chatId, {
    enabled: !!chatId,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    retryDelay: 100,
    onError: () => {
      setChatId("");
    },
  });
  const [messages, setMessages] = useState<ConversationItemWithId[]>([]);

  useEffect(() => {
    if (getChatData?.answer) {
      setChatId("");

      setMessages([
        ...messages,
        {
          role: "assistant",
          id: getChatData.id,
          content: getChatData.answer.choices[0].message.content,
          answer: getChatData.answer,
        },
      ]);

      setTimeout(() => {
        const newStep = activeStep + 1;
        if (autoPlay && newStep <= steps.length) {
          setActiveStep(activeStep + 1);
        }
      });
    }
  }, [getChatData]);

  useEffect(() => {
    if (bodyElement.current) {
      bodyElement.current.scrollTo(0, bodyElement.current.scrollHeight);
    }
  }, [messages]);

  useEffect(() => {
    const prompt = steps[activeStep]?.prompt || "";
    if (prompt) {
      onSubmit({ query: prompt, number: number });
    }
  }, [activeStep]);

  const onSubmit = async (values: MessageFields) => {
    reset();

    const newMessages = [
      ...messages,
      {
        id: uniqueId("user_chat_"),
        role: "user" as const,
        content: values.query,
      },
    ];

    await updateMessages(newMessages);

    setMessages(newMessages);
  };

  const updateMessages = async (
    conversationItems: ConversationItemWithId[],
  ) => {
    try {
      const lastChatRole = conversationItems[conversationItems.length - 1].role;

      if (lastChatRole === "user") {
        const result = await createChat({
          conversation: conversationItems.map((message) => ({
            content: message.content,
            role: message.role,
          })),
          n: number,
        });

        if (result.chat_id) {
          setChatId(result.chat_id);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const chatErrorMessage =
    chatError?.response?.data.error ||
    chatError?.message ||
    getChatError?.response?.data.error ||
    getChatError?.message;

  const stepsFinished = activeStep + 1 >= steps.length;

  const lastUserMessage: ConversationItemWithId = useMemo(() => {
    return messages.reduce(
      (lastFound, item) => {
        return item.role === "user" ? item : lastFound;
      },
      { id: "", role: "user", content: "" },
    );
  }, [messages]);

  const handleUserRefresh = (id: string) => {
    const lastMessageIndex = messages.findIndex((e) => e.id === id);
    const newMessages = messages.filter(
      (e, index) => index <= lastMessageIndex,
    );

    setMessages(newMessages);

    updateMessages(newMessages);
  };

  const handleUserDelete = () => {
    const lastMessage = messages[messages.length - 1];
    const lastQuery = messages[messages.length - 2];
    const newMessages = messages
      .filter((e) => e.id !== lastMessage.id)
      .filter((e) => e.id !== lastQuery.id);

    setMessages(newMessages);
  };

  return (
    <div className="chat-window">
      <header className="chat-window__header">
        <ul className="steps is-small">
          {steps.map((step, i) => (
            <li
              key={`step-${step.name}`}
              className={classNames([
                "step-item is-primary",
                {
                  "is-completed": activeStep > i,
                  "is-active": activeStep === i,
                },
              ])}
            >
              <div className="step-marker">{i + 1}</div>
              <div className="step-details">
                <p>{step.name}</p>
              </div>
            </li>
          ))}
        </ul>
        {!stepsFinished && !autoPlay && (
          <button
            type="button"
            disabled={chatLoading || !!chatId}
            className="button is-secondary is-small"
            onClick={() => {
              setActiveStep(activeStep + 1);
            }}
          >
            Next
          </button>
        )}
        {!stepsFinished && (
          <div className="field auto-play-field">
            <input
              id="autoPlay"
              type="checkbox"
              name="autoPlay"
              className="switch is-small"
              checked={autoPlay}
              disabled={(chatLoading || !!chatId) && !autoPlay}
              onChange={(e) => {
                if (e.target.checked && activeStep < steps.length) {
                  setActiveStep(activeStep + 1);
                }
                setAutoPlay(e.target.checked);
              }}
            />
            <label htmlFor="autoPlay">Auto Play</label>
          </div>
        )}
      </header>
      <div className="chat-window__body" ref={bodyElement}>
        {!messages.length && (
          <div className="section has-text-centered">
            <p className="title">Chat with Football Genie</p>
            <p className="subtitle">
              Ask Genie about the match by completing conversation.
            </p>
          </div>
        )}
        {!!messages.length && (
          <div className="messages-wrapper">
            {messages.map((message) => (
              <ChatMessage
                key={message.id}
                id={message.id}
                role={message.role}
                content={message.content}
                onUserRefresh={handleUserRefresh}
                answer={message.answer}
                onDelete={handleUserDelete}
                enableRefresh={lastUserMessage?.id === message.id}
                enableDelete={lastUserMessage?.id === message.id}
              />
            ))}
          </div>
        )}
        {(chatLoading || chatId) && (
          <ChatMessage
            id="loading"
            key="chat-loading"
            role="assistant"
            loading={true}
          />
        )}
        {chatErrorMessage && !chatLoading && (
          <ChatMessage
            id="error"
            role="assistant"
            key="chat-error"
            error={chatErrorMessage}
            onRetry={() => {
              updateMessages(messages);
            }}
          />
        )}
      </div>

      <footer className="chat-window__footer">
        <form
          onSubmit={handleSubmit((values) => onSubmit(values))}
          className="field has-addons chat-send-field"
        >
          <div className="control">
            <textarea
              className="textarea is-secondary"
              placeholder="Ask Football Genie ..."
              rows={5}
              disabled={chatLoading || !!chatErrorMessage}
              {...register("query")}
            />
          </div>
          <div className="control">
            <button
              className="button is-secondary"
              disabled={chatLoading || !!chatErrorMessage || !query}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faPaperPlane} />
              </span>
            </button>
          </div>
          <div className="control">
            <input className="input" type="number" {...register("number")} />
          </div>
          {!messages.length && (
            <div className="control">
              <button
                className="button is-secondary"
                type="button"
                onClick={() => {
                  onSubmit({ query: singlePrompt, number: 3 });
                }}
              >
                Quick
              </button>
            </div>
          )}
        </form>
      </footer>
    </div>
  );
};
