import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateStandingsResponse = {
  id: number;
  success: boolean;
};

type CreateStandingsArgs = {
  id: string | number;
};

export const createStandings: MutationFunction<
  CreateStandingsResponse,
  CreateStandingsArgs
> = (props) => {
  return client.post(`/standings`, { id: props.id }).then((res) => res.data);
};

export const CreateStandingsMutationKey = "create-standings";

export const useCreateStandings = (
  params?: CreateStandingsArgs,
  options?: Omit<
    UseMutationOptions<
      CreateStandingsResponse,
      DefaultErrorResponse,
      CreateStandingsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateStandingsResponse,
  DefaultErrorResponse,
  CreateStandingsArgs
> => {
  return useMutation(
    [CreateStandingsMutationKey, params?.id],
    createStandings,
    options,
  );
};
