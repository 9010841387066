import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { FixtureItem } from "@api/football/types/fixture";
import { DefaultErrorResponse } from "@api/index";
import { TipItem, TipsQueryArgs } from "@api/math-tips";
import {
  getTipsFilterQueryArgs,
  parseTipsFilterParams,
} from "@api/math-tips/utils";

import { client } from "../client";

export type ResultOrientedTipItem = TipItem & {
  reasoning: string;
  best_choice: boolean;
  rating: TipItem["rating"] & "super safe";
};

type Summary = {
  total_bet_amount: string;
  total_return: string;
  net_profit: string;
  total_wins: string;
  total_losses: string;
  total_pushes: string;
  total_pending: string;
  roi: string;
};

export type TipsResponseItem = {
  fixture: FixtureItem;
  tips: ResultOrientedTipItem[];
};

type GetTipsResponse = {
  summary: Summary;
  data: TipsResponseItem[];
  last_evaluated_key: string;
};

export const fetchTips: QueryFunction<GetTipsResponse> = ({ queryKey }) => {
  const { params } = parseTipsFilterParams(queryKey);

  return client
    .get("/tips", {
      params,
    })
    .then((res) => res.data);
};

export const GET_RESULT_ORIENTED_TIPS_QUERY_KEY = "get-result-oriented-tips";

export const useGetResultOrientedTips = (
  args?: TipsQueryArgs,
  options?: Omit<
    UseQueryOptions<GetTipsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetTipsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_RESULT_ORIENTED_TIPS_QUERY_KEY, ...getTipsFilterQueryArgs(args)],
    fetchTips,
    options,
  );
};
