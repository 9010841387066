import React, { FC, HTMLAttributes } from "react";

import classNames from "classnames";

import { Colors } from "@ui/types";
import "./StatusBadge.scss";

type StatusBadgeProps = {
  type: Colors;
  size?: "small" | "normal" | "medium" | "large";
  isPulse?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const StatusBadge: FC<StatusBadgeProps> = ({
  type = "text",
  size = "normal",
  isPulse = false,
  className,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        "status-badge",
        `is-${size}`,
        `is-${type}`,
        className,
      )}
      {...rest}
    >
      <div
        className={classNames(["status-badge__inner", { "is-pulse": isPulse }])}
      />
    </div>
  );
};
