import React, { PropsWithChildren, FC } from "react";

import classNames from "classnames";

import "./Notification.scss";
import { Delete } from "@ui/elements";
import { Colors } from "@ui/types";

type NotificationProps = PropsWithChildren<{
  color?: Colors;
  onClose?: () => void;
  isLight?: boolean;
}>;

export const Notification: FC<NotificationProps> = ({
  children,
  color,
  onClose,
  isLight = true,
}) => {
  const notificationClasses = classNames("notification", {
    [`is-${color}`]: color,
    "is-light": isLight,
  });

  return (
    <div className={notificationClasses}>
      {onClose && <Delete onClick={onClose} aria-label="close" />}
      {children}
    </div>
  );
};
