import React, { FC, useMemo } from "react";

import dayjs from "dayjs";
import { TooltipProps } from "recharts";

import { FixtureItem } from "@api/football/types/fixture";

import { Box, ImageComponent, Tag, Text, Title } from "@ui/elements";
import { Grid, Stack } from "@ui/layout";
import "./FixturePreviewTooltip.scss";

export const FixturePreviewTooltip: FC<TooltipProps<number, string>> = (
  props,
) => {
  const [payloads] = props.payload || [];
  const data: FixtureItem | undefined = payloads?.payload.data;
  const date = useMemo(() => {
    return dayjs(data?.fixture.date).format("YYYY MMM DD");
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <Box className="fixture-preview">
      <Stack direction="column" gap="lg">
        <Stack gap="lg" justify="between" className="preview-header">
          <Tag variant="light">{date}</Tag>
          <Tag variant="light">
            <Stack gap="xs">
              <ImageComponent src={data?.league.logo} size={16} />
              <p>{data.league.name}</p>
            </Stack>
          </Tag>
        </Stack>

        <Grid type="fixed" cols={3} gap={2}>
          <Stack direction="column" gap="xs" align="center">
            <ImageComponent src={data.teams.home.logo} size={32} />
            <Text isCenter isNarrow size="small">
              {data.teams.home.name}
            </Text>
          </Stack>
          <Stack direction="column" gap="none" align="center" justify="center">
            <Title size={5}>
              {data.score.fulltime.home} - {data.score.fulltime.away}
            </Title>
            <Text size="small">
              ({data.score.halftime.home} - {data.score.halftime.away})
            </Text>
          </Stack>
          <Stack direction="column" gap="xs" align="center">
            <ImageComponent src={data.teams.away.logo} size={32} />
            <Text isCenter isNarrow size="small">
              {data.teams.away.name}
            </Text>
          </Stack>
        </Grid>
      </Stack>
    </Box>
  );
};
