import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import "./PageTemplate.scss";
import classNames from "classnames";
import dayjs from "dayjs";
import { NavLink, Outlet, Link as RouterLink } from "react-router-dom";

import { Currency } from "@api/user/client";
import { useUserPreferences } from "@api/user/getPreferences";

import { Dropdown, DropdownDivider, DropdownItem } from "@ui/components";
import { Text, Icon, Icons, Button, Delete } from "@ui/elements";
import { CurrencyInput } from "@ui/forms";
import { Stack } from "@ui/layout";

import {
  useAuth,
  useModal,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components";

import { FullPageLoading } from "../FullPageLoading/FullPageLoading";
import { ScrollToTop } from "../ScrollToTop/ScrollToTop";

type LinkProps = PropsWithChildren<{
  icon: Icons;
  to: string;
}>;

const Link: FC<LinkProps> = ({ icon, to, children }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(["template-nav__item", { "is-active": isActive }])
      }
    >
      {({ isActive }) => (
        <>
          <Icon weight={isActive ? "fill" : "regular"} icon={icon} />
          <Text weight={isActive ? "bold" : "normal"}>{children}</Text>
        </>
      )}
    </NavLink>
  );
};

type PageSettingsContextType = {
  bankroll?: number | null;
  setBankroll: (value: undefined | number | null) => void;
  currency?: Currency;
  kellyFraction?: number;
  focusBankroll?: () => void;
  hasSubscription?: boolean;
  isLoading?: boolean;
};

const PageSettingsContext = createContext<PageSettingsContextType | undefined>(
  undefined,
);

export const usePageSettings = () => {
  const context = useContext(PageSettingsContext);
  if (!context) {
    throw new Error(
      "usePageSettings must be used within a PageSettings provider",
    );
  }
  return context;
};

const bankrollStorage = {
  set: (amount?: number | string | null) => {
    const date = dayjs().format("YYYY-MM-DD");

    localStorage.setItem(
      "savedBankroll",
      JSON.stringify({
        [date]: amount,
      }),
    );
  },
  get: () => {
    const date = dayjs().format("YYYY-MM-DD");
    const data = JSON.parse(localStorage.getItem("savedBankroll") || "{}");

    return data[date] || undefined;
  },
};

export const PageTemplate: FC<PropsWithChildren> = () => {
  const [open, setOpen] = useState(true);
  const [bankrollInfoOpen, setBankrollInfoOpen] = useState(false);
  const bankrollInputRef = useRef<HTMLInputElement>(null);
  const [bankroll, setBankroll] = useState<undefined | number | null>(
    bankrollStorage.get(),
  );
  const { data: userPreferenceData, isLoading: userPreferencesLoading } =
    useUserPreferences({
      onSuccess: ({ has_active_subscription }) => {
        if (!has_active_subscription) {
          modals.open("subscription");
        }
      },
    });
  const authContext = useAuth();
  const modals = useModal();

  const signo = useMemo(() => {
    if (
      authContext.userAttributes?.given_name &&
      authContext.userAttributes?.family_name
    ) {
      return (
        authContext.userAttributes.given_name.slice(0, 1) +
        authContext.userAttributes.family_name.slice(0, 1)
      );
    }
  }, [authContext.userAttributes]);

  const handleAccountDropdownChange = (type: string) => {
    switch (type) {
      case "account":
        modals.open("accountSettings");
        break;
      case "preferences":
        modals.open("preferences");
        break;
      case "subscription":
        modals.open("subscription");
        break;
      case "sign-out":
        authContext.logout();
        break;
    }
  };

  const handleBankrollFocus = () => {
    bankrollInputRef?.current?.focus();
    setBankrollInfoOpen(true);
  };

  return (
    <PageSettingsContext.Provider
      value={{
        bankroll,
        setBankroll,
        currency: userPreferenceData?.currency,
        kellyFraction: Number(userPreferenceData?.kellyFraction),
        focusBankroll: handleBankrollFocus,
        hasSubscription: !!userPreferenceData?.has_active_subscription,
        isLoading: userPreferencesLoading,
      }}
    >
      <ScrollToTop />
      <div className="page-template-wrapper">
        <Stack justify="between" className="template-header">
          <Stack gap="md" align="center">
            <RouterLink
              to="/"
              className={classNames([
                "template-header__logo",
                { "is-active": open },
              ])}
            >
              <div className="image">
                <img src="logo.png" />
              </div>
              <p className="logo-text">
                Football Genie <span>AI</span>
              </p>
            </RouterLink>
            <Button
              className="menu-toggle"
              isRounded
              variant="light"
              onClick={() => setOpen(!open)}
            >
              <Icon size="small" icon={open ? "TextOutdent" : "TextIndent"} />
            </Button>
          </Stack>

          <Stack gap="sm" align="center" justify="end">
            <Tooltip open={bankrollInfoOpen} placement="bottom-end">
              <TooltipTrigger>
                <div className={classNames("control has-icons-left")}>
                  <CurrencyInput
                    className="bankroll-input"
                    variant="grey"
                    ref={bankrollInputRef}
                    placeholder="My bankroll"
                    currency={userPreferenceData?.currency}
                    value={bankroll}
                    onChange={(event) => {
                      setBankrollInfoOpen(false);
                      setBankroll(Number(event.target.value));
                      bankrollStorage.set(Number(event.target.value));
                    }}
                  />
                  <Icon className="is-left" size="small" icon="Coins" />
                  {!!bankroll && (
                    <Delete
                      size="small"
                      className="bankroll-input-delete"
                      onClick={() => {
                        setBankroll(undefined);
                        bankrollStorage.set(undefined);
                      }}
                    />
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="tooltip">
                  <Stack direction="column">
                    <Text>You must set your current bankroll first.</Text>
                  </Stack>
                </div>
              </TooltipContent>
            </Tooltip>
            <Dropdown
              items={
                <>
                  <DropdownItem value="account" icon="User">
                    Account
                  </DropdownItem>
                  <DropdownItem value="subscription" icon="Sparkle">
                    Subscription
                  </DropdownItem>
                  <DropdownItem value="preferences" icon="Gear">
                    Preferences
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem value="sign-out" icon="SignOut">
                    Sign Out
                  </DropdownItem>
                </>
              }
              isRight
              onSelect={handleAccountDropdownChange}
            >
              <Button isRounded variant="light" color="primary">
                <span className="icon">{signo}</span>
              </Button>
            </Dropdown>
            <Button
              variant="white"
              isRounded
              onClick={() => modals.open("contact")}
            >
              <Icon icon="Info" />
            </Button>
          </Stack>
        </Stack>
        <div className="page-template">
          <Stack
            className={classNames(["template-nav", { "is-active": open }])}
            direction="column"
            gap="sm"
          >
            <Link to="/" icon="Layout">
              Dashboard
            </Link>
            <Link to="/upcoming-tips" icon="MagicWand">
              Upcoming Tips
            </Link>
            <Link to="/ai-performance" icon="ChartBar">
              AI Performance
            </Link>
            <Link to="/my-bets" icon="BookmarkSimple">
              My Tips
            </Link>
          </Stack>
          <div className="page-template__content">
            <div className="page-template__content__inner">
              {userPreferencesLoading ? <FullPageLoading /> : <Outlet />}
            </div>
          </div>
        </div>
      </div>
    </PageSettingsContext.Provider>
  );
};
