import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";
import "./Content.scss";

type ContentProps = PropsWithChildren<{
  size?: "small" | "medium" | "large";
  noMargin?: boolean;
}>;

export const Content: FC<ContentProps> = ({ children, size, noMargin }) => {
  return (
    <div
      className={classNames("content", {
        [`is-${size}`]: size,
        "mb-0": noMargin,
      })}
    >
      {children}
    </div>
  );
};
