import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

type CheckEmailArgs = {
  email?: string;
};

export type CheckEmailResponse = {
  error?: string;
  hasUser: boolean;
  given_name: string | null;
  family_name: string | null;
  name: string | null;
  user_status: string | null;
};

const fetchCheckEmail: QueryFunction<CheckEmailResponse> = ({ queryKey }) => {
  const [_key, email] = queryKey;

  return client
    .get("/check-email", { params: { email } })
    .then((res) => res.data);
};

export const CHECK_EMAIL_QUERY_KEY = "check-email";

export const useCheckEmail = (
  args?: CheckEmailArgs,
  options?: Omit<
    UseQueryOptions<CheckEmailResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<CheckEmailResponse, DefaultErrorResponse> => {
  return useQuery(
    [CHECK_EMAIL_QUERY_KEY, args?.email],
    fetchCheckEmail,
    options,
  );
};
