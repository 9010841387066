import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";
import dayjs from "dayjs";

import { formatTipName } from "@utils";

import { FixtureItem } from "@api/football/types/fixture";
import { TipItem } from "@api/math-tips";

import { TeamsHeader, TeamsInfoItem } from "@ui/components";
import { Tag, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./SingleTip.scss";

type SingleTipProps = PropsWithChildren<{
  fixture: FixtureItem;
  tip: TipItem;
}>;

export const SingleTip: FC<SingleTipProps> = ({ fixture, tip }) => {
  const { winner, odds, category, name, probability } = tip;

  return (
    <Stack justify="between" gap="md" className="single-tip">
      <Stack>
        <div
          className={classNames([
            "single-tip__badge",
            { winner: winner === true },
            { lost: winner === false },
          ])}
        >
          <div />
        </div>
        <Stack direction="column" gap="none">
          <TeamsHeader
            title={
              <>
                <Text>{formatTipName(category, name)}</Text>
                <Text variant="secondary">{probability}%</Text>
              </>
            }
            homeLogo={fixture?.teams.home.logo}
            awayLogo={fixture?.teams.away.logo}
            subtitle={
              <>
                <TeamsInfoItem
                  icon="SoccerBall"
                  label={`${fixture?.teams.home.name} vs ${fixture?.teams.away.name}`}
                />
                <TeamsInfoItem
                  icon="CalendarBlank"
                  label={dayjs(fixture?.fixture.date).format("MMM DD - HH:mm")}
                />
              </>
            }
          />
        </Stack>
      </Stack>
      <Stack>
        <Tag>x{odds}</Tag>
      </Stack>
    </Stack>
  );
};
