/* eslint-disable react/no-unescaped-entities */

import React from "react";

import { Helmet } from "react-helmet";

import { Content } from "@ui/elements";
import { Column, Columns, Container } from "@ui/layout";

import deleteAccount from "./delete-account.png";
import loginScreen from "./login-screen.png";
import menuDropdown from "./menu-dropdown.png";
import "./DataDeletion.scss";

export const DataDeletion = () => {
  return (
    <div className="static-page-wrapper">
      <Container>
        <Helmet>
          <title>Data Deletion Instructions</title>
        </Helmet>
        <div className="static-page">
          <Columns isCentered>
            <Column size={5}>
              <Content>
                <h1>Data Deletion Instructions</h1>
                <p>
                  According to Data Deletion Policy, if you wish to delete your
                  data from our application, please follow the instructions
                  below:
                </p>
                <hr />
                <p>
                  1. Log in to our application using{" "}
                  <a
                    href="https://football-genie.com/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://football-genie.com/login
                  </a>{" "}
                  link.
                </p>
                <img src={loginScreen} />
                <hr />
                <p>
                  2. Navigate to "Account" menu located under your monograms,
                  then locate the "Delete Account" section at the bottom of
                  menu..
                </p>
                <img src={menuDropdown} />
                <hr />
                <p>
                  3. Click the "Delete Account" button and confirm your action.
                </p>
                <img src={deleteAccount} />
                <br />
                <br />
                <blockquote>
                  If you encounter any issues or have further questions, please
                  contact our support team at{" "}
                  <a href="mailto:info@football-genie.com">
                    info@football-genie.com
                  </a>
                  .
                </blockquote>
              </Content>
            </Column>
          </Columns>
        </div>
      </Container>
    </div>
  );
};
