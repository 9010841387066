import React from "react";

import { Box, Button, Icon, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

export const ContactChatBox = () => {
  return (
    <Box>
      <Stack align="center">
        <Icon icon="ChatCircleDots" size="large" />
        <Stack direction="column" gap="none" isFullwidth>
          <Title size={5}>Need help?</Title>
          <Text variant="secondary">
            We&apos;re here to assist you. Let&apos;s chat!
          </Text>
        </Stack>

        <Stack>
          <Button
            variant="light"
            isRounded
            as="link"
            href="https://t.me/slimtiexxx"
            target="_blank"
          >
            <Icon icon="TelegramLogo" />
          </Button>
          <Button
            variant="light"
            isRounded
            as="link"
            href="https://instagram.com/direct/t/footballgenieai"
            target="_blank"
          >
            <Icon icon="InstagramLogo" />
          </Button>
          <Button
            variant="light"
            isRounded
            as="link"
            href="mailto:info@football-genie.com"
            target="_blank"
          >
            <Icon icon="At" />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
