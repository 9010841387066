import React, { useState } from "react";

import { useForm } from "react-hook-form";

import { withController } from "@utils";

import { Box, Button, Notification, Text } from "@ui/elements";
import { Input } from "@ui/forms";
import { Cell, Grid, Stack } from "@ui/layout";

type KellyFormFields = {
  bankroll: number;
  odds: string;
  probability: number;
};

const ControlledInput = withController<KellyFormFields>()(Input);

export const KellyCalculator = () => {
  const { handleSubmit, control } = useForm<KellyFormFields>();
  const [betAmount, setBetAmount] = useState<number | null>(null);

  const onSubmit = (data: KellyFormFields) => {
    setBetAmount(null);

    const { bankroll, odds: _odds, probability } = data;
    const odds = parseFloat(_odds.replace(",", "."));
    const p = probability / 100;
    const b = odds - 1;
    const q = 1 - p;
    const f = (b * p - q) / b;
    const quarterKelly = f / 4;
    const recommendedBet = quarterKelly * bankroll;
    setBetAmount(Math.round(recommendedBet));
  };

  return (
    <Box>
      <Stack direction="column" gap="sm">
        <Text variant="secondary">Kelly Calculator</Text>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <Stack direction="column" gap="md">
            <Grid>
              <Cell>
                <ControlledInput
                  control={control}
                  name="bankroll"
                  type="number"
                  label="Bankroll"
                />
              </Cell>

              <Cell>
                <ControlledInput
                  control={control}
                  name="odds"
                  type="number"
                  label="Odds"
                  step="any"
                />
              </Cell>

              <Cell>
                <ControlledInput
                  control={control}
                  name="probability"
                  type="number"
                  label="Probability"
                  step={1}
                />
              </Cell>
            </Grid>

            <Stack isFullwidth justify="end">
              <Button color="primary" type="submit">
                Calculate
              </Button>
            </Stack>

            {!!betAmount && betAmount > 0 && (
              <Notification color="info">
                It is recommended to place a bet with{" "}
                <strong>€{betAmount}</strong>.
              </Notification>
            )}

            {!!betAmount && betAmount < 0 && (
              <Notification color="danger">
                The odds are too low. It is not recommended to bet.
              </Notification>
            )}
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
