export function calculateKelly(
  odds: number,
  probability: number,
  fraction: number = 0.25,
): number {
  const b = odds - 1; // Calculate profit per unit staked
  const p = probability / 100; // Convert percentage to decimal
  const q = 1 - p; // Probability of losing

  // Calculate the Kelly Criterion
  const kellyFraction = (b * p - q) / b;

  // Return the Kelly fraction specified by the user, defaulting to quarter Kelly
  return kellyFraction * fraction * 100;
}
