import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  signIn,
  confirmSignIn,
  signOut,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { withController } from "@utils";

import { Button, Divider, Notification, Text } from "@ui/elements";
import { Checkbox, Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { useAuth } from "@components";

import "./first-time-signin.scss";

type ConfirmSigninFields = {
  email: string;
  password: string;
  confirmPassword: string;
  oldPassword: string;
  firstName: string;
  lastName: string;
  termsAndConditions?: boolean | undefined;
  marketingUpdates?: boolean | undefined;
};

const ControlledTextInput = withController<ConfirmSigninFields>()(Input);
const ControlledCheckbox = withController<ConfirmSigninFields>()(Checkbox);

const formSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name name is required"),
  oldPassword: Yup.string().required("Temporary password is required"),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "It is required to accept Terms and Conditions.",
  ),
  marketingUpdates: Yup.boolean(),
  email: Yup.string().required("E-mail is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

export const FirstTimeSignIn = () => {
  const [searchParams] = useSearchParams();

  const form = useForm<ConfirmSigninFields>({
    defaultValues: {
      email: searchParams.get("email") || "",
      oldPassword: searchParams.get("token") || "",
      termsAndConditions: false,
    },
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit, control } = form;
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useAuth();

  useEffect(() => {
    if (authContext.isAuthenticated === true) {
      navigate("/");
    }
  }, [authContext.isAuthenticated]);

  const onSubmit = async (values: ConfirmSigninFields) => {
    setLoading(true);
    setError("");
    try {
      if (authContext.isAuthenticated) {
        await signOut();
      }

      // Initial sign-in attempt to get the challenge
      const signInResult = await signIn({
        username: values.email,
        password: values.oldPassword,
      });

      if (
        signInResult.nextStep.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        const userAttributes = {
          family_name: values.lastName,
          given_name: values.firstName,
          name: `${values.firstName} ${values.lastName}`,
          "custom:platformUpdates": "true",
          "custom:feedbackSurvey": "true",
          "custom:marketingEmails": String(!!values.marketingUpdates),
          "custom:newsLetters": String(!!values.marketingUpdates),
        };
        const newPassword = values.password;

        // Complete the sign-in with the new password and required attributes
        await confirmSignIn({
          challengeResponse: newPassword,
          options: { userAttributes },
        });

        await signOut();

        const { result, error } = await authContext.login(
          values.email,
          newPassword,
        );

        if (typeof result !== "boolean") {
          const attributes = await fetchUserAttributes();
          toast(
            <p>
              <strong>Welcome {attributes.given_name}!</strong>
              <br />
              Your account is completed! 👋
            </p>,
          );
          return navigate("/");
        }

        if (error) {
          setError(error.message);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Football Genie - Welcome</title>
      </Helmet>
      <Stack direction="column" gap="xl">
        <Stack direction="column">
          {error && <Notification color="danger">{error}</Notification>}
          <ControlledTextInput
            label="E-mail"
            name="email"
            type="email"
            control={control}
            disabled
            autoComplete="username"
          />
          <div className="field">
            <div className="columns is-variable is-2">
              <div className="column first-name-column">
                <ControlledTextInput
                  label="First name"
                  control={control}
                  name="firstName"
                  type="text"
                />
              </div>
              <div className="column">
                <ControlledTextInput
                  label="Last name"
                  control={control}
                  name="lastName"
                  type="text"
                />
              </div>
            </div>
          </div>
          <Divider />
          <ControlledTextInput
            label="Password"
            control={control}
            name="password"
            type="password"
            autoComplete="new-password"
          />
          <ControlledTextInput
            label="Confirm password"
            control={control}
            name="confirmPassword"
            type="password"
            autoComplete="new-password"
          />
          <ControlledCheckbox
            control={control}
            name="marketingUpdates"
            isCentered={false}
          >
            <Text size="small">
              I agree to receive marketing communications and newsletters, which
              include special offers, product announcements, industry news,
              tips, and user stories.
            </Text>
          </ControlledCheckbox>
          <ControlledCheckbox
            control={control}
            name="termsAndConditions"
            isCentered={false}
          >
            <Text size="small">
              By registering, I agree to the{" "}
              <a href="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>{" "}
              and consent to receive emails related to services, updates, and
              surveys.
            </Text>
          </ControlledCheckbox>
        </Stack>

        <Stack direction="column" gap="xs">
          <Button type="submit" isLoading={loading} color="primary">
            Create account
          </Button>
          <Link to="/login">
            <Button as="link" isFullwidth variant="text">
              Already have an account?
            </Button>
          </Link>
        </Stack>
      </Stack>
    </form>
  );
};
