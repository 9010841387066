import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { updatePassword } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { Button, Divider, Text } from "@ui/elements";
import { Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster } from "@components";

type ChangePasswordFields = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

const formSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Current Password is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<ChangePasswordFields>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (values: ChangePasswordFields) => {
    setLoading(true);
    setError("");
    try {
      await updatePassword({
        oldPassword: values.oldPassword,
        newPassword: values.password,
      });

      toaster.success({
        title: "Successful update!",
        message: "You have successfully changed your password.",
      });

      reset();
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap="xl">
        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}
        <Text variant="secondary">
          For your security, make sure your password contains a mix of letters,
          numbers, and symbols. Avoid using passwords that you have used on
          other websites.
        </Text>
        <Stack direction="column" gap="xl">
          <Input
            label="Current Password"
            type="password"
            autoComplete="current-password"
            error={errors["oldPassword"]?.message}
            {...register("oldPassword")}
          />
          <Divider />
          <Stack direction="column" gap="sm">
            <Input
              label="New Password"
              type="password"
              autoComplete="new-password"
              error={errors["password"]?.message}
              {...register("password")}
            />
            <Input
              label="Confirm New Password"
              type="password"
              autoComplete="new-password"
              error={errors["confirmPassword"]?.message}
              {...register("confirmPassword")}
            />
          </Stack>
        </Stack>

        <Stack justify="end">
          <Button variant="light" isLoading={loading}>
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
