import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreatePlayersResponse = {
  id: number;
  success: boolean;
};

type CreatePlayersArgs = {
  id: string | number;
};

export const createPlayers: MutationFunction<
  CreatePlayersResponse,
  CreatePlayersArgs
> = (props) => {
  return client.post(`/players`, { id: props.id }).then((res) => res.data);
};

export const CreatePlayersMutationKey = "create-players";

export const useCreatePlayers = (
  params?: CreatePlayersArgs,
  options?: Omit<
    UseMutationOptions<
      CreatePlayersResponse,
      DefaultErrorResponse,
      CreatePlayersArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreatePlayersResponse,
  DefaultErrorResponse,
  CreatePlayersArgs
> => {
  return useMutation(
    [CreatePlayersMutationKey, params?.id],
    createPlayers,
    options,
  );
};
