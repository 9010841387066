import React, { FC } from "react";

import classNames from "classnames";

import { Icon, Icons } from "@ui/elements";
import { Colors } from "@ui/types";
import "./IconCard.scss";

export type IconCardProps = {
  type?: Colors | "text";
  icon: Icons;
  size?: "small" | "normal" | "medium" | "large";
  isLoading?: boolean;
};

export const IconCard: FC<IconCardProps> = ({
  icon,
  type,
  size = "normal",
  isLoading,
}) => {
  return (
    <div
      className={classNames("icon-card", `is-${size}`, {
        // [`has-background-${type}-95`]: type,
        [`has-text-${type}`]: type,
        "has-skeleton": isLoading,
      })}
    >
      <Icon icon={icon} size={size} />
    </div>
  );
};
