import { createClient } from "../index";

export const LiveStates = [
  "1H",
  "HT",
  "2H",
  "ET",
  "BT",
  "P",
  "SUSP",
  "INT",
  "LIVE",
];
export const NotStartedStates = ["TBD", "NS"];
export const FinishedStates = ["FT", "AET", "PEN"];

export const client = createClient("football");
