import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeleteMotivationsResponse = {
  error: string | null;
  success: boolean;
};

type DeleteMotivationsArgs = {
  id: string | number;
};

export const deleteMotivations: MutationFunction<
  DeleteMotivationsResponse,
  DeleteMotivationsArgs
> = (props) => {
  return client
    .delete(`/motivations`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeleteMotivationsMutationKey = "delete-motivations";

export const useDeleteMotivations = (
  params?: DeleteMotivationsArgs,
  options?: Omit<
    UseMutationOptions<
      DeleteMotivationsResponse,
      DefaultErrorResponse,
      DeleteMotivationsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteMotivationsResponse,
  DefaultErrorResponse,
  DeleteMotivationsArgs
> => {
  return useMutation(
    [DeleteMotivationsMutationKey, params?.id],
    deleteMotivations,
    options,
  );
};
