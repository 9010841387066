import React, { FC } from "react";

import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

export const withController =
  <T extends FieldValues>() =>
  <Props,>(Component: FC<Props>): FC<Props & UseControllerProps<T>> =>
  // eslint-disable-next-line react/prop-types,react/display-name
  ({ name, control, defaultValue, rules, shouldUnregister, ...props }) => {
    const {
      field,
      fieldState: { error },
    } = useController({ name, control, defaultValue, rules, shouldUnregister });

    return (
      <Component {...(props as Props)} {...field} error={error?.message} />
    );
  };
