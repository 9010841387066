import React from "react";

import "./scss/index.scss";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import ReactDOM from "react-dom/client";

import config from "./amplifyconfiguration.json";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

dayjs.extend(isoWeek);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://10319b3b8fd0b7dcb8ede152367c5da0@o4506988282249216.ingest.us.sentry.io/4506988283691008",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

Amplify.configure(config);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

async function enableMocking() {
  if (process.env.REACT_APP_USE_MOCK !== "true") {
    return;
  }

  const { worker } = await import("./services/api/mocks");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: (request, print) => {
      if (
        request.url
          .toString()
          .startsWith(process.env.REACT_APP_FOOTBALL_API_URL || "")
      ) {
        print.warning();
      }
    },
  });
}

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
