import React, { useState } from "react";

import { updateUserAttributes } from "aws-amplify/auth";
import { useForm } from "react-hook-form";

import { Button, Text } from "@ui/elements";
import { Checkbox } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster, useAuth } from "@components";

type EmailPreferencesFields = {
  platformUpdates: boolean;
  marketingEmails: boolean;
  newsLetters: boolean;
  feedbackSurvey: boolean;
};

export const EmailPreferences = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const authContext = useAuth();

  const { handleSubmit, register } = useForm<EmailPreferencesFields>({
    defaultValues: {
      platformUpdates:
        authContext.userAttributes?.["custom:platformUpdates"] === "true",
      marketingEmails:
        authContext.userAttributes?.["custom:marketingEmails"] === "true",
      newsLetters:
        authContext.userAttributes?.["custom:newsLetters"] === "true",
      feedbackSurvey:
        authContext.userAttributes?.["custom:feedbackSurvey"] === "true",
    },
  });

  const onSubmit = async (values: EmailPreferencesFields) => {
    setLoading(true);
    setError("");
    try {
      await updateUserAttributes({
        userAttributes: {
          "custom:platformUpdates": String(values.platformUpdates),
          "custom:marketingEmails": String(values.marketingEmails),
          "custom:newsLetters": String(values.newsLetters),
          "custom:feedbackSurvey": String(values.feedbackSurvey),
        },
      });

      toaster.success({
        title: "Successful update!",
        message: "We've updated your e-mail preferences.",
      });
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap="xl">
        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}
        <Stack direction="column" gap="xs">
          <Text variant="secondary">
            Your email preferences allow you to customize what you receive from
            us, so you&apos;re only getting the information that&apos;s
            important to you.
          </Text>
          <Text variant="secondary">
            Select from the options below to manage the types of communications
            you receive.
          </Text>
        </Stack>

        <Stack direction="column">
          <Checkbox isCentered={false} {...register("platformUpdates")}>
            <Text weight="bold">Platform Updates</Text>
            <Text variant="secondary">
              Updates about new features, downtimes, maintenance windows, or
              other relevant information about your platform that users might
              need to be aware of.
            </Text>
          </Checkbox>
          <Checkbox isCentered={false} {...register("feedbackSurvey")}>
            <Text weight="bold">Feedback and Surveys</Text>
            <Text variant="secondary">
              Emails requesting feedback on your services or products, or asking
              users to participate in surveys.
            </Text>
          </Checkbox>
          <Checkbox isCentered={false} {...register("marketingEmails")}>
            <Text weight="bold">Marketing Emails</Text>
            <Text variant="secondary">
              Promotional content such as special offers, new product
              announcements, sales.
            </Text>
          </Checkbox>
          <Checkbox isCentered={false} {...register("newsLetters")}>
            <Text weight="bold">Newsletters</Text>
            <Text variant="secondary">
              Regularly scheduled emails that provide value through content,
              such as blog posts, industry news, tips, or user stories.
            </Text>
          </Checkbox>
        </Stack>

        <Stack justify="end">
          <Button variant="light" isLoading={loading}>
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
