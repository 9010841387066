import React, { FC } from "react";

import {
  faCheckCircle,
  faCopy,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import dayjs from "dayjs";
import { Calendar } from "react-date-range";

import { Table, TableData, TableRow } from "@ui/elements";

import { useGetLeagueFixtures } from "../../../../services/api/football/fetchFixturesByLeagueId/fetchFixturesByLeagueId";
import { copyToClipboard } from "../../../../utils/copyToClipboard";

type FixtureSelectProps = {
  value: string;
  setValue: (value: string) => void;
  date: Date;
  setDate: (value: Date) => void;
};

export const FixtureSelect: FC<FixtureSelectProps> = ({
  value,
  setValue,
  date,
  setDate,
}) => {
  const { data, isLoading } = useGetLeagueFixtures(date);

  return (
    <div>
      <div className="fixture-selector">
        <div className="fixture-selector__calendar">
          <Calendar
            color="#014D4D"
            className="fixture-date-calendar"
            date={date}
            weekStartsOn={1}
            onChange={setDate}
          />
        </div>
        <div className="is-divider is-divider-vertical" />
        <div className="fixture-selector__list">
          {isLoading && (
            <div className="is-size-3 has-text-grey has-text-centered">
              <span className="icon">
                <FontAwesomeIcon icon={faSpinner} pulse={true} />
              </span>
            </div>
          )}
          {data?.sort().map((league) => {
            return (
              <div className="mb-1" key={league.id}>
                <p className="heading">{league.name}</p>
                <Table isFullwidth>
                  {league.fixtures
                    .sort(
                      (a, b) =>
                        dayjs(a.fixture.date).unix() -
                        dayjs(b.fixture.date).unix(),
                    )
                    .map((fixture) => (
                      <TableRow
                        key={fixture.fixture.id}
                        onClick={() => setValue(String(fixture.fixture.id))}
                        className={classNames([
                          "fixture-row",
                          {
                            "highlighted-row":
                              String(fixture.fixture.id) === String(value),
                          },
                        ])}
                      >
                        <TableData isFullwidth>
                          <p className="has-text-grey">
                            {fixture.teams.home.name}
                            &nbsp;vs&nbsp;
                            {fixture.teams.away.name}
                          </p>
                          <p>{dayjs(fixture.fixture.date).format("HH:mm")}</p>
                        </TableData>
                        <TableData>
                          <span className="icon has-text-white">
                            <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                          </span>
                        </TableData>
                      </TableRow>
                    ))}
                </Table>
              </div>
            );
          })}
        </div>
      </div>
      <div className="is-divider" data-content="OR"></div>
      <div>
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input is-fullwidth"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
          <div className="control">
            <button
              className="button is-secondary"
              onClick={() => copyToClipboard(value)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faCopy} size="xs" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
