import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  signIn,
  FetchUserAttributesOutput,
  SignInOutput,
  fetchUserAttributes,
  signOut,
} from "aws-amplify/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { FullPageLoading } from "../FullPageLoading/FullPageLoading";
import { toaster } from "../Toaster/Toaster";

type AuthContextValue = {
  isAuthenticated: boolean | undefined;
  userAttributes: FetchUserAttributesOutput | null;
  login: (
    username: string,
    password: string,
  ) => Promise<{ result: SignInOutput | boolean; error?: Error }>;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextValue>({
  login: () => Promise.resolve({ result: false }),
  logout: () => Promise.resolve(),
  userAttributes: null,
  isAuthenticated: undefined,
});

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthContext provider");
  }

  return context;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );
  const [userAttributes, setUserAttributes] =
    useState<FetchUserAttributesOutput | null>(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const attributes = await fetchUserAttributes();

        setUserAttributes(attributes);
        setIsAuthenticated(true);
      } catch (error) {
        await signOut();
        setUserAttributes(null);
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  const handleLogin: AuthContextValue["login"] = async (username, password) => {
    try {
      if (isAuthenticated) {
        await signOut();
      }

      const result = await signIn({
        username,
        password,
      });
      const attributes = await fetchUserAttributes();

      setIsAuthenticated(true);
      setUserAttributes(attributes);

      return { result };
    } catch (error) {
      setIsAuthenticated(false);
      return { result: false, error: error as Error };
    }
  };

  const handleLogOut: AuthContextValue["logout"] = async () => {
    try {
      await signOut();

      setIsAuthenticated(false);
      setUserAttributes(null);

      toaster({
        title: "Successful sign out!",
        message: "Waiting you back soon.",
      });
    } catch (error) {
      setIsAuthenticated(false);
      setUserAttributes(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userAttributes,
        login: handleLogin,
        logout: handleLogOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const ProtectedRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  if (isAuthenticated === undefined) {
    return <FullPageLoading />;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  const searchParams = new URLSearchParams(location.search);
  searchParams.set("redirect_to", location.pathname);

  return <Navigate to={`/login?${searchParams.toString()}`} />;
};

export default ProtectedRoute;
