import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateTeamCharacteristicsResponse = {
  id: number;
  success: boolean;
};

type CreateTeamCharacteristicsArgs = {
  id: string | number;
};

export const createTeamCharacteristics: MutationFunction<
  CreateTeamCharacteristicsResponse,
  CreateTeamCharacteristicsArgs
> = (props) => {
  return client
    .post(`/team-characteristics`, { id: props.id })
    .then((res) => res.data);
};

export const CreateTeamCharacteristicsMutationKey =
  "create-team-characteristics";

export const useCreateTeamCharacteristics = (
  params?: CreateTeamCharacteristicsArgs,
  options?: Omit<
    UseMutationOptions<
      CreateTeamCharacteristicsResponse,
      DefaultErrorResponse,
      CreateTeamCharacteristicsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateTeamCharacteristicsResponse,
  DefaultErrorResponse,
  CreateTeamCharacteristicsArgs
> => {
  return useMutation(
    [CreateTeamCharacteristicsMutationKey, params?.id],
    createTeamCharacteristics,
    options,
  );
};
