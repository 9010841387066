import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

export type GetEmailPreferencesArgs = {
  token?: string;
};

export type GetEmailPreferencesResponse = {
  email_preferences: {
    platformUpdates: boolean;
    feedbackSurvey: boolean;
    newsLetters: boolean;
    marketingEmails: boolean;
  };
};

const getEmailPreferences: QueryFunction<GetEmailPreferencesResponse> = ({
  queryKey,
}) => {
  const [_queryKey, token] = queryKey;

  return client.get(`/email-preferences/${token}`).then((res) => res.data);
};

export const GET_EMAIL_REFERENCES_QUERY_KEY = "get-email-preferences";

export const useGetEmailPreferences = (
  args?: GetEmailPreferencesArgs,
  options?: Omit<
    UseQueryOptions<GetEmailPreferencesResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetEmailPreferencesResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_EMAIL_REFERENCES_QUERY_KEY, args?.token],
    getEmailPreferences,
    options,
  );
};
