import React, { FC, HTMLAttributes, useMemo } from "react";

import classNames from "classnames";
import dayjs from "dayjs";

import { Title, Text } from "@ui/elements";

import "./CalendarDay.scss";

type CalendarDayProps = {
  date: string;
  isActive?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const CalendarDay: FC<CalendarDayProps> = ({
  date,
  isActive,
  ...rest
}) => {
  const dateNum = useMemo(() => {
    return dayjs(date).format("DD");
  }, [date]);

  const dateStr = useMemo(() => {
    return dayjs(date).format("ddd");
  }, [date]);

  const isToday = useMemo(() => {
    return dayjs(date).isSame(dayjs(), "day");
  }, [date]);

  return (
    <div
      className={classNames([
        "calendar-day",
        { "is-today": isToday, "is-active": isActive },
      ])}
      {...rest}
    >
      <Title size={5}>{dateNum}</Title>
      <Text variant="secondary">{dateStr}</Text>
    </div>
  );
};
