import React, { FC, useState } from "react";

import { useForm } from "react-hook-form";

import { formatProbability, formatTipName, roundNumber } from "@utils";

import { FixtureItem } from "@api/football/types/fixture";
import { TipItem } from "@api/math-tips";
import { useCreateTip } from "@api/user/createTip";
import { useGetUserTip } from "@api/user/getTip";

import {
  Modal,
  TeamsHeader,
  TeamsInfoItem,
  useTeamsHeaderProps,
} from "@ui/components";
import { Button, Icon, Text, Notification } from "@ui/elements";
import { Stack } from "@ui/layout";

import { DeleteTipModal, toaster } from "@components";

import { usePageSettings } from "../PageTemplate/PageTemplate";

import "./TipSave.scss";
import { oddsToNumber, TipSaveForm, TipSaveFormFields } from "./TipSaveForm";

type TipSaveProps = {
  fixture: FixtureItem;
  tip: TipItem;
};

export const TipSave: FC<TipSaveProps> = ({ tip, fixture }) => {
  const form = useForm<TipSaveFormFields>();
  const teamsHeaderProps = useTeamsHeaderProps(fixture);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { data: userTipData, isLoading: userTipLoading } = useGetUserTip(
    tip.id,
  );
  const {
    mutateAsync: createTipMutate,
    error: createTipError,
    isLoading: createTipLoading,
  } = useCreateTip();
  const { bankroll, currency, focusBankroll, kellyFraction } =
    usePageSettings();
  const hasTipSaved = Object.keys(userTipData || {}).length > 0;

  const odds = hasTipSaved ? userTipData?.odds : tip.odds;
  const stake = hasTipSaved
    ? Number(userTipData?.bet_amount)
    : roundNumber((Number(bankroll) * Number(tip.bet_amount)) / 100);

  const handleSaveButtonClick = async () => {
    if (!bankroll) {
      return focusBankroll?.();
    }

    setModalOpen(true);
    resetForm();
  };

  const resetForm = () => {
    form.reset({
      odds: oddsToNumber(odds),
      stake,
    });
  };

  const onSubmit = async (values: TipSaveFormFields) => {
    if (currency?.code) {
      try {
        await createTipMutate({
          bet_amount: values.stake,
          currency: currency.code,
          odds: oddsToNumber(values.odds),
          id: tip.id,
          kelly_fraction: Number(kellyFraction),
        });

        toaster({
          title: "Successful update!",
          message: "We've updated your portfolio. Good luck!",
        });

        setModalOpen(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const error = createTipError?.response?.data;

  return (
    <>
      <Button
        variant="ghost"
        onClick={handleSaveButtonClick}
        isLoading={userTipLoading}
      >
        {hasTipSaved && <Icon icon="PencilSimple" />}
        {!hasTipSaved && <Icon icon="BookmarkSimple" />}
      </Button>
      <DeleteTipModal
        open={deleteModalOpen}
        onClose={(closeAll) => {
          setDeleteModalOpen(false);
          if (closeAll) {
            setModalOpen(false);
          }
        }}
        id={tip.id}
      />
      <Modal
        header={
          <TeamsHeader
            title={
              <Stack align="center">
                <Text>{formatTipName(tip.category, tip.name)}</Text>{" "}
                <Text variant="secondary">
                  {formatProbability(tip.probability)}
                </Text>
              </Stack>
            }
            subtitle={
              <TeamsInfoItem label={teamsHeaderProps.title as string} />
            }
            homeLogo={teamsHeaderProps.homeLogo}
            awayLogo={teamsHeaderProps.awayLogo}
          />
        }
        footer={
          <Stack isFullwidth align="center">
            <Stack isFullwidth>
              {!hasTipSaved ? (
                <Button
                  variant="text"
                  type="button"
                  onClick={() => resetForm()}
                >
                  Reset to default
                </Button>
              ) : (
                <Button
                  variant="light"
                  color="danger"
                  type="button"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  Remove
                </Button>
              )}
            </Stack>
            <Stack>
              <Button
                variant="white"
                type="button"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="light"
                color="success"
                type="submit"
                onClick={() => form.handleSubmit(onSubmit)()}
                isLoading={createTipLoading}
              >
                {hasTipSaved ? "Update" : "Save"}
              </Button>
            </Stack>
          </Stack>
        }
        isActive={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Stack direction="column" gap="xl">
          {error && (
            <Notification color="danger">
              <Stack direction="column" gap="xxs">
                <Text weight="bold">{error.error}</Text>
                <Text>{error.message}</Text>
              </Stack>
            </Notification>
          )}
          <TipSaveForm
            tip={tip}
            form={form}
            stakeInitiallyLocked={hasTipSaved}
          />
        </Stack>
      </Modal>
    </>
  );
};
