import "./StatisticsOverview.scss";
import React, { FC, ReactElement, useMemo, useState } from "react";

import classNames from "classnames";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { teamStatisticsContentMapping } from "@api/statistics/client";
import {
  StatisticsAverage,
  StatisticsKeys,
} from "@api/statistics/fetchStatistics";

import { Button, Divider, Icon, Icons, Text } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

type StatisticsCircularProgressProps = {
  values: number[];
  postfix?: string;
  prefix?: string;
  icon?: Icons;
  iconOverlay?: ReactElement;
  label: string;
};

const StatisticsCircularProgress: FC<StatisticsCircularProgressProps> = ({
  values: _values,
  postfix = "",
  prefix,
  label,
  icon,
  iconOverlay,
}) => {
  const values = _values.map((e) => Number((e || 0).toFixed(1)));
  const total = values.reduce((acc, curr) => (acc += curr), 0);
  const topValue = Math.max(...values);
  const homePercentage = (values[0] / total) * 100;
  const awayPercentage = (values[1] / total) * 100;
  const homeBetter = homePercentage > awayPercentage;
  const awayBetter = awayPercentage > homePercentage;

  const rotation = useMemo(() => {
    if (awayBetter) {
      return 0.25 - awayPercentage / 100 / 2;
    }

    if (homeBetter) {
      return 0.75 - homePercentage / 100 / 2;
    }
  }, [homePercentage, awayPercentage, homeBetter, awayBetter]);

  const homeUnavailable = typeof _values[0] !== "number";
  const awayUnavailable = typeof _values[1] !== "number";

  return (
    <Stack direction="column" gap="xs" className="circular-statistics">
      <Text variant="secondary" size="small" isCenter>
        {label}
      </Text>
      <Stack justify="center" align="center" gap="xs">
        {iconOverlay && (
          <div className="circular-statistics__icon-overlay">{iconOverlay}</div>
        )}
        <Text weight={values[0] === topValue ? "bold" : "normal"}>
          {!homeUnavailable ? (
            <>
              {prefix}
              {values[0]}
              {postfix}
            </>
          ) : (
            "N/A"
          )}
        </Text>
        <div className="circular-statistics__progress">
          {icon && <Icon icon={icon} />}
          <CircularProgressbar
            value={awayBetter ? awayPercentage : homePercentage}
            strokeWidth={10}
            styles={buildStyles({
              rotation,
              backgroundColor: "var(--bulma-white-ter)",
              trailColor: "var(--bulma-white-ter)",
              pathColor: "var(--bulma-body-color)",
            })}
          />
        </div>
        <Text weight={values[1] === topValue ? "bold" : "normal"}>
          {!awayUnavailable ? (
            <>
              {prefix}
              {values[1]}
              {postfix}
            </>
          ) : (
            "N/A"
          )}
        </Text>
      </Stack>
    </Stack>
  );
};

type KeyIconStatsProps = {
  corners?: number;
  yellow_cards?: number;
  red_cards?: number;
  isReverse?: boolean;
};

const KeyIconStats: FC<KeyIconStatsProps> = ({
  corners,
  yellow_cards,
  red_cards,
  isReverse,
}) => {
  return (
    <Stack direction={isReverse ? "row-reverse" : "row"}>
      <Stack direction="column" gap="xxs" justify="center" align="center">
        <Icon icon="Flag" weight="fill" style={{ color: "#FF0000" }} />
        <Text>{Number(corners?.toFixed(1))}</Text>
      </Stack>
      <Stack direction="column" gap="xxs" justify="center" align="center">
        <Icon icon="Square" weight="fill" style={{ color: "#FFD700" }} />
        <Text>{Number(yellow_cards?.toFixed(1))}</Text>
      </Stack>
      <Stack direction="column" gap="xxs" justify="center" align="center">
        <Icon icon="Square" weight="fill" style={{ color: "#FF0000" }} />
        <Text>{Number(red_cards?.toFixed(1))}</Text>
      </Stack>
    </Stack>
  );
};

type KeyStatisticsProgressBarProps = {
  max: number;
  values: number[];
  innerValues: number[];
};

const KeyStatisticsProgressBar: FC<KeyStatisticsProgressBarProps> = ({
  max,
  values,
  innerValues,
}) => {
  const topValue = Math.max(...values);

  return (
    <div className="key-statistics-progress-bar">
      <div
        className={classNames([
          "key-statistics-progress-bar__item",
          {
            "key-statistics-progress-bar__item--active": values[0] === topValue,
          },
        ])}
        style={{ width: `${(values[0] / max) * 100}%` }}
      >
        <div
          className="key-statistics-progress-bar__item__inner"
          style={{ width: `${(innerValues[0] / values[0]) * 100}%` }}
        />
      </div>
      <div
        className={classNames([
          "key-statistics-progress-bar__item",
          {
            "key-statistics-progress-bar__item--active": values[1] === topValue,
          },
        ])}
        style={{ width: `${(values[1] / max) * 100}%` }}
      >
        <div
          className="key-statistics-progress-bar__item__inner"
          style={{ width: `${(innerValues[1] / values[1]) * 100}%` }}
        />
      </div>
    </div>
  );
};

type GeneralProgressBarProps = {
  label: string;
  values: number[];
  prefix?: string;
  postfix?: string;
};

const GeneralProgressBar: FC<GeneralProgressBarProps> = ({
  label,
  prefix,
  postfix,
  values: _values,
}) => {
  const values = _values.map((e) => Number((e || 0).toFixed(1)));
  const total = values.reduce((acc, curr) => (acc += curr), 0);
  const topValue = Math.max(...values);
  const unavailable = _values.every((e) => typeof e !== "number");

  return (
    <Stack direction="column" gap="none" justify="center" align="center">
      <Text variant="secondary" size="small">
        {prefix}
        {label}
        {postfix}
      </Text>
      <Stack align="center" isFullwidth>
        <Text>{!unavailable ? values[0] : "N/A"}</Text>
        <Stack isFullwidth className="general-progress-bar__bar" gap="none">
          <div
            className={classNames("general-progress-bar__bar__item", {
              "has-background-text": values[0] === topValue && !unavailable,
            })}
            style={{ width: `${(values[0] / total) * 100}%` }}
          />
          <div
            className={classNames("general-progress-bar__bar__item", {
              "has-background-text": values[1] === topValue && !unavailable,
            })}
            style={{
              width: unavailable ? "100%" : `${(values[1] / total) * 100}%`,
            }}
          />
        </Stack>
        <Text>{!unavailable ? values[1] : "N/A"}</Text>
      </Stack>
    </Stack>
  );
};

type KeyStatisticsGroup = {
  title: string;
  keys: StatisticsKeys[];
};

const statisticsGroups: KeyStatisticsGroup[] = [
  {
    title: "Scoring",
    keys: ["first_half_goals", "second_half_goals"],
  },
  {
    title: "Shooting",
    keys: [
      "blocked_shots",
      "shots_insidebox",
      "shots_outsidebox",
      "shot_accuracy",
    ],
  },
  {
    title: "Passing",
    keys: ["passes_percentage"],
  },
  {
    title: "Game progress",
    keys: ["offsides", "goalkeeper_saves"],
  },
];

type KeyStatisticsOverviewProps = {
  home: StatisticsAverage;
  away: StatisticsAverage;
  hideKeys?: StatisticsKeys[];
  id: string;
};

export const StatisticsOverview: FC<KeyStatisticsOverviewProps> = ({
  home,
  away,
  id,
  hideKeys = [],
}) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Stack direction="column" gap="sm" className="statistics-overview">
      <Stack direction="column" gap="lg">
        <Columns isMobile isMultiline isCentered>
          <Column>
            <StatisticsCircularProgress
              key={`ball-possession-${id}`}
              postfix="%"
              icon="SoccerBall"
              label="Ball possession"
              values={[home.ball_possessions, away.ball_possessions]}
            />
          </Column>
          <Column>
            <StatisticsCircularProgress
              key={`expected-goals-${id}`}
              prefix="x"
              label="Expected goals"
              icon="Target"
              values={[home.expected_goals, away.expected_goals]}
            />
          </Column>
          {!hideKeys.includes("first_half_goals") &&
            !hideKeys.includes("second_half_goals") && (
              <Column>
                <StatisticsCircularProgress
                  key={`scored-goals-${id}`}
                  label="Scored goals"
                  icon="CheckCircle"
                  values={[
                    home.first_half_goals + home.second_half_goals,
                    away.first_half_goals + away.second_half_goals,
                  ]}
                />
              </Column>
            )}
        </Columns>

        <Divider />

        <Stack direction="column">
          <Stack
            justify="around"
            className="statistics-overview-bottom-stats-wrapper"
          >
            <KeyIconStats
              corners={home.corners}
              yellow_cards={home.yellow_cards}
              red_cards={home.red_cards}
            />

            <div className="statistics-overview-bottom-progress">
              <Text size="small" variant="secondary">
                Shots / On Target
              </Text>
              <Stack justify="center" gap="xs">
                <Text>
                  {home.total_shots} / {home.shots_on_target}
                </Text>
                <KeyStatisticsProgressBar
                  values={[home.total_shots, away.total_shots]}
                  innerValues={[home.shots_on_target, away.shots_on_target]}
                  max={home.total_shots + away.total_shots}
                />
                <Text>
                  {away.total_shots} / {away.shots_on_target}
                </Text>
              </Stack>
            </div>

            <KeyIconStats
              corners={away.corners}
              yellow_cards={away.yellow_cards}
              red_cards={away.red_cards}
              isReverse={true}
            />
          </Stack>

          <Stack justify="center">
            <Button
              variant="light"
              size="small"
              onClick={() => setShowMore(!showMore)}
            >
              <span>Show More</span>
              <Icon icon={showMore ? "CaretUp" : "CaretDown"} />
            </Button>
          </Stack>
        </Stack>
      </Stack>

      {showMore && (
        <div className="statistics-overview-all-wrapper">
          <Stack
            direction="column"
            gap="xxl"
            isFullwidth
            className="statistics-overview-all"
          >
            {statisticsGroups
              .map((group) => ({
                ...group,
                keys: group.keys.filter((key) => !hideKeys.includes(key)),
              }))
              .filter((group) => group.keys.length)
              .map(({ title, keys }) => {
                return (
                  <Stack
                    key={`key-statistics-${title}`}
                    gap="xs"
                    direction="column"
                  >
                    <Stack direction="column" gap="xxs">
                      <Text>{title}</Text>
                      <Divider />
                    </Stack>
                    <Stack direction="column" gap="xs">
                      {keys.map((key) => {
                        return (
                          <GeneralProgressBar
                            key={`key-statistics-${title}-${key}`}
                            values={[home[key], away[key]]}
                            label={teamStatisticsContentMapping[key]}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        </div>
      )}
    </Stack>
  );
};
