import React, { FC, useContext } from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useQuery, useQueryClient } from "react-query";

import { useCreateMathPrediction } from "../../../../../services/api/math-prediction/createMathPrediction";
import {
  GetMathPredictionQueryKey,
  useGetMathPrediction,
} from "../../../../../services/api/math-prediction/getMathPrediction";
import { fetchStatistics } from "../../../../../services/api/statistics/fetchStatistics";
import { StepComponentCommonProps } from "../../StepComponent/StepComponent";
import { TipValidatorContext, ValidatorSteps } from "../TipValidator";

export const Options: FC<StepComponentCommonProps<ValidatorSteps>> = ({
  goToStep,
  active,
}) => {
  const {
    form: { register, watch },
  } = useContext(TipValidatorContext);
  const queryClient = useQueryClient();
  const fixtureId = watch("id");
  const {
    data: mathPredictionData,
    isLoading: mathPredictionLoading,
    isRefetching: mathPredictionRefetching,
  } = useGetMathPrediction(fixtureId);
  const {
    data: statisticsData,
    isLoading: statisticsLoading,
    error: statisticsError,
  } = useQuery(["statistics", fixtureId], fetchStatistics);
  const {
    mutateAsync: createMathPrediction,
    isLoading: createMathPredictionLoading,
  } = useCreateMathPrediction({
    onSuccess: () => {
      queryClient.invalidateQueries([GetMathPredictionQueryKey, fixtureId]);
    },
  });

  if (!fixtureId || !active) {
    return null;
  }

  return (
    <>
      <p className="label">Statistics</p>
      {statisticsLoading && (
        <div className="is-size-3 has-text-grey-light">
          <span className="icon">
            <FontAwesomeIcon icon={faSpinner} pulse={true} />
          </span>
        </div>
      )}
      {statisticsError && (
        <div className="message is-warning is-inline-block">
          <div className="message-body content is-small">
            <p>Failed to fetch statistics</p>
          </div>
        </div>
      )}
      {statisticsData?.home_fixtures.length && (
        <>
          <div className="field mb-0">
            <input
              id="total"
              type="checkbox"
              className="switch is-small"
              {...register("includeTotal")}
            />
            <label htmlFor="total">Total</label>
          </div>
          <div className="field mb-0">
            <input
              id="h2h"
              type="checkbox"
              className="switch is-small"
              {...register("includeH2H")}
            />
            <label htmlFor="h2h">H2H</label>
          </div>
          <div className="field">
            <input
              id="home-away"
              type="checkbox"
              className="switch is-small"
              {...register("includeHomeAway")}
            />
            <label htmlFor="home-away">Home vs Away</label>
          </div>
        </>
      )}
      <p className="label">Math AI</p>
      {!mathPredictionData?.predictions?.length && !mathPredictionLoading && (
        <div className="message is-inline-block">
          <div className="message-body content is-small">
            <p>Math prediction is not available</p>
            <button
              type="button"
              onClick={() => createMathPrediction({ id: fixtureId })}
              className={classNames([
                "button is-primary is-small",
                {
                  "is-loading":
                    mathPredictionRefetching || createMathPredictionLoading,
                },
              ])}
            >
              Create
            </button>
          </div>
        </div>
      )}
      {mathPredictionData?.predictions?.length && (
        <div className="field">
          <input
            id="mathAI"
            type="checkbox"
            className="switch is-small is-loading"
            disabled={mathPredictionLoading}
            {...register("mathAI")}
          />
          <label htmlFor="mathAI">Include</label>
        </div>
      )}
      <p className="label">Match Importance</p>
      <div className="field">
        <input
          id="matchImportance"
          type="checkbox"
          className="switch is-small"
          {...register("matchImportance")}
        />
        <label htmlFor="matchImportance">Include</label>
      </div>
      {active && (
        <div className="field">
          <div className="control">
            <button
              type="button"
              className="button is-secondary"
              onClick={() => {
                goToStep("generate");
              }}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};
