import React, { useEffect } from "react";

import { fetchAuthSession } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";

import { Button, Icon, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./SuccessSubscription.scss";

export const SuccessSubscription = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const session = fetchAuthSession().then(console.log);
    console.log({ session });
  }, []);

  return (
    <Stack direction="column" className="success-subscription-box">
      <Stack direction="column" align="center" gap="lg">
        <Stack direction="column" gap="xs" justify="center" align="center">
          <Icon
            icon="CheckCircle"
            size="large"
            weight="fill"
            className="has-text-success"
          />
          <Title size={4}>Subscription Successful</Title>
        </Stack>
        <Text className="has-text-centered">
          Welcome to the Football Genie Team! You can now start taking advantage
          of our AI Tips. We wish you the best of luck and many profits!
        </Text>
        <Stack direction="column" gap="xs">
          <Button
            color="primary"
            type="button"
            onClick={() => navigate("/upcoming-tips")}
          >
            Explore Upcoming Tips
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
