import React from "react";

import { Navigate, Outlet } from "react-router-dom";

import { useIsAdmin } from "../../utils/isAdmin";
import { FullPageLoading } from "../FullPageLoading/FullPageLoading";

export const AdminRoute = () => {
  const { isLoading, isInGroup, error } = useIsAdmin();

  if (isLoading) {
    return <FullPageLoading />;
  }

  if (error) return <div>Error: {error}</div>; // Handle errors as needed

  return isInGroup ? <Outlet /> : <Navigate to={`/`} />;
};
