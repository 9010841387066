import React, { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";

import classNames from "classnames";
import "./Stack.scss";

const predefinedGaps: Record<Gap, string> = {
  none: "var(--spacing-none)",
  xxs: "var(--spacing-xxs)",
  xs: "var(--spacing-xs)",
  sm: "var(--spacing-sm)",
  md: "var(--spacing-md)",
  lg: "var(--spacing-lg)",
  xl: "var(--spacing-xl)",
  xxl: "var(--spacing-xxl)",
};

type Direction = "row" | "row-reverse" | "column" | "column-reverse";
type Justify = "start" | "center" | "end" | "between" | "around" | "evenly";
type Align = "stretch" | "start" | "center" | "end" | "baseline";
type Gap = "none" | "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

type StackProps = PropsWithChildren<{
  direction?: Direction;
  justify?: Justify;
  align?: Align;
  gap?: Gap;
  padding?: Gap;
  margin?: Gap;
  className?: string;
  isFullwidth?: boolean;
  isFullHeight?: boolean;
  wrap?: boolean;
}> &
  HTMLAttributes<HTMLDivElement>;

export const Stack: React.FC<StackProps> = ({
  children,
  direction = "row",
  justify = "flex-start",
  align = "flex-start",
  gap = "sm" as Gap,
  isFullwidth = false,
  isFullHeight = false,
  wrap = false,
  className,
  ...rest
}) => {
  const containerClasses = classNames(
    "stack",
    {
      "direction-row": direction === "row",
      "direction-column": direction === "column",
      "direction-row-reverse": direction === "row-reverse",
      "direction-column-reverse": direction === "column-reverse",
      "is-fullwidth": isFullwidth,
      "is-fullheight": isFullHeight,
      [`justify-${justify}`]: justify,
      [`align-${align}`]: align,
      "is-wrap": wrap,
    },
    className,
  );

  const style: CSSProperties = {
    gap: predefinedGaps[gap],
  };

  return (
    <div className={containerClasses} style={style} {...rest}>
      {children}
    </div>
  );
};
