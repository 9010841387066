import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeletePlayersResponse = {
  error: string | null;
  success: boolean;
};

type DeletePlayersArgs = {
  id: string | number;
};

export const deletePlayers: MutationFunction<
  DeletePlayersResponse,
  DeletePlayersArgs
> = (props) => {
  return client
    .delete(`/players`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeletePlayersMutationKey = "delete-players";

export const useDeletePlayers = (
  params?: DeletePlayersArgs,
  options?: Omit<
    UseMutationOptions<
      DeletePlayersResponse,
      DefaultErrorResponse,
      DeletePlayersArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeletePlayersResponse,
  DefaultErrorResponse,
  DeletePlayersArgs
> => {
  return useMutation(
    [DeletePlayersMutationKey, params?.id],
    deletePlayers,
    options,
  );
};
