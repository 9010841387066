import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { confirmResetPassword, fetchUserAttributes } from "aws-amplify/auth";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { withController } from "@utils";

import { Button, Notification, Text, Title } from "@ui/elements";
import { Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster, useAuth } from "@components";

type CreateNewPasswordFields = {
  password: string;
  confirmPassword: string;
};

const formSchema = Yup.object().shape({
  password: Yup.string().required("New Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm New Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

const ControlledInput = withController<CreateNewPasswordFields>()(Input);

export const CreateNewPassword = () => {
  const { handleSubmit, control } = useForm<CreateNewPasswordFields>({
    resolver: yupResolver(formSchema),
  });
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useAuth();

  useEffect(() => {
    if (authContext.isAuthenticated === true) {
      navigate("/");
    }
  }, [authContext.isAuthenticated]);

  const onSubmit = async (values: CreateNewPasswordFields) => {
    setLoading(true);

    try {
      const username = searchParams.get("username") || "";
      await confirmResetPassword({
        confirmationCode: searchParams.get("confirmationCode") || "",
        newPassword: values.password,
        username,
      });

      const { result, error } = await authContext.login(
        username,
        values.password,
      );

      if (typeof result !== "boolean") {
        const attributes = await fetchUserAttributes();
        toaster.success({
          title: `Welcome ${attributes.given_name}!`,
          message: "Your new password is successfully set! 👋",
        });
        return navigate("/");
      }

      if (error) {
        setError(error.message);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Football Genie - Reset your password</title>
      </Helmet>
      <Stack direction="column" gap="xl">
        <Stack direction="column">
          <Title size={5}>Set your new Password</Title>
          <Text variant="secondary">
            Your new password must be different from previously used passwords.
          </Text>
          <ControlledInput
            label="New Password"
            type="password"
            name="password"
            control={control}
            autoComplete="new-password"
          />
          <ControlledInput
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            control={control}
            autoComplete="new-password"
          />
          {error && <Notification color="danger">{error}</Notification>}
        </Stack>

        <Stack direction="column" gap="xs">
          <Button type="submit" isLoading={loading} color="primary">
            Update password
          </Button>
          <Link to="/login">
            <Button as="link" isFullwidth variant="text">
              Return to Login
            </Button>
          </Link>
        </Stack>
      </Stack>
    </form>
  );
};
