import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";
import { FixtureItem } from "../types/fixture";

export const fetchFixture: QueryFunction<FixtureItem> = ({ queryKey }) => {
  const [_key, id] = queryKey;

  return client.get("/get-fixture", { params: { id } }).then((res) => res.data);
};

export const FETCH_FIXTURE_QUERY_KEY = "fetch-fixture";

export const useFetchFixture = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<FixtureItem, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<FixtureItem, DefaultErrorResponse> => {
  return useQuery([FETCH_FIXTURE_QUERY_KEY, id], fetchFixture, options);
};
