import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeleteExpertOpinionsResponse = {
  id: number;
  success: boolean;
};

type DeleteExpertOpinionsArgs = {
  id: string | number;
};

export const deleteExpertOpinions: MutationFunction<
  DeleteExpertOpinionsResponse,
  DeleteExpertOpinionsArgs
> = (props) => {
  return client
    .delete(`/expert-opinions`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeleteExpertOpinionsMutationKey = "create-expert-opinions";

export const useDeleteExpertOpinions = (
  params?: DeleteExpertOpinionsArgs,
  options?: Omit<
    UseMutationOptions<
      DeleteExpertOpinionsResponse,
      DefaultErrorResponse,
      DeleteExpertOpinionsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteExpertOpinionsResponse,
  DefaultErrorResponse,
  DeleteExpertOpinionsArgs
> => {
  return useMutation(
    [DeleteExpertOpinionsMutationKey, params?.id],
    deleteExpertOpinions,
    options,
  );
};
