import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type MotivationItem = {
  score: number;
  reasoning: string;
};

type MotivationsData = {
  id: string;
  analysis: {
    recommended: {
      result: boolean;
      reasoning: string;
    };
    predictability: MotivationItem;
    match_importance: {
      home: MotivationItem;
      away: MotivationItem;
    };
  };
  prompt?: string;
};

export type GetMotivationsResponse = {
  error: string | null;
  data: MotivationsData | null;
};

export const getMotivations: QueryFunction<GetMotivationsResponse> = ({
  queryKey,
}) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/motivations", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetMotivationsQueryKey = "get-motivations";

export const useGetMotivations = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetMotivationsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetMotivationsResponse, DefaultErrorResponse> => {
  return useQuery([GetMotivationsQueryKey, id], getMotivations, options);
};
