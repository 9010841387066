import React, { FC, useMemo, useState } from "react";

import dayjs from "dayjs";

import { copyToClipboard, formatOdds, formatProbability } from "@utils";

import { useGetLeagueFixtures } from "@api/football/fetchFixturesByLeagueId/fetchFixturesByLeagueId";
import { useOdds } from "@api/football/fetchOdds";
import { FixtureItem } from "@api/football/types/fixture";
import {
  NextGenTipItem,
  TipCategory,
  TipCategoryMap,
  useGetNextGenTips,
} from "@api/prediction-chain/tips/get";

import {
  CalendarSlider,
  Dropdown,
  DropdownItem,
  TeamsHeader,
  useTeamsHeaderProps,
} from "@ui/components";
import {
  Box,
  Button,
  Icon,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Tag,
  Text,
} from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import {
  MatchDetailsDrawer,
  toaster,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components";

import "./FixtureList.scss";

type TipsTableProps = {
  id?: string | number;
};

const ColorMap: Record<"safe" | "value" | "risk", Colors> = {
  safe: "success",
  value: "info",
  risk: "danger",
};

type UseFindOddsOptions = {
  id?: string | number;
  category: TipCategory;
  value: string;
};

const useFindOdds = ({ id, category, value }: UseFindOddsOptions) => {
  const { data: oddsData } = useOdds(id);

  return useMemo(() => {
    return Number(
      oddsData
        ?.find((e) => e.name === TipCategoryMap[category])
        ?.values.find((e) => e.value === value)?.odd,
    );
  }, [oddsData, category, value]);
};

type TipRowProps = {
  fixtureId?: string | number;
  item: NextGenTipItem & { probability: number };
};

const TipRow: FC<TipRowProps> = ({ item, fixtureId }) => {
  const odds = useFindOdds({
    id: fixtureId,
    category: item.tip_category,
    value: item.tip_value,
  });

  return (
    <TableRow>
      <TableData isFullwidth>
        <Stack align="center">
          <Stack direction="column" gap="none">
            <Text hasIcon>
              <span>{item.tip_value}</span>
              <Tooltip placement="top-start">
                <TooltipTrigger>
                  <Icon icon="Info" />
                </TooltipTrigger>
                <TooltipContent className="tooltip">
                  <Stack direction="column">
                    <Text weight="bold">{item.tip_name}</Text>
                    <Text>{item.reasoning}</Text>
                  </Stack>
                </TooltipContent>
              </Tooltip>
            </Text>
            <Text size="small" variant="secondary">
              {item.tip_category}
            </Text>
          </Stack>
        </Stack>
      </TableData>
      <TableData>
        <Tag type={ColorMap[item.tip_group]} size="medium" variant="light">
          {formatProbability(item.probability)}
        </Tag>
      </TableData>
      <TableData>{formatOdds(odds)}</TableData>
    </TableRow>
  );
};

const TipsTable: FC<TipsTableProps> = ({ id }) => {
  const { data, isLoading } = useGetNextGenTips(id);

  const sortedData = useMemo(() => {
    return data?.data?.summary
      .map((item) => ({
        ...item,
        probability: Number(item.chance_of_occurring.replace("%", "")),
      }))
      .sort((a, b) => b.probability - a.probability);
  }, [data]);

  if (isLoading) {
    return <Text>Loading ...</Text>;
  }

  if (!isLoading && !data?.data?.summary.length) {
    return null;
  }

  return (
    <Table>
      <TableRow>
        <TableHeading>Tip</TableHeading>
        <TableHeading>Conf.</TableHeading>
        <TableHeading>Odds</TableHeading>
      </TableRow>
      {sortedData?.map((tip) => (
        <TipRow
          fixtureId={id}
          key={`next-gen-tip-${id}-${tip?.reasoning}`}
          item={tip}
        />
      ))}
    </Table>
  );
};

type TipBoxProps = {
  fixture: FixtureItem;
};

const TipBox: FC<TipBoxProps> = ({ fixture }) => {
  const [matchDetailsOpen, setMatchDetailsOpen] = useState(false);
  const teamsHeaderProps = useTeamsHeaderProps(fixture);

  const handleDropdownChange = async (value: string) => {
    switch (value) {
      case "match-details":
        setMatchDetailsOpen(true);
        break;
      case "fixture-id":
        await copyToClipboard(fixture.fixture.id);
        toaster.success({
          title: "Copied to clipboard",
          message: "Successfully copied fixture id to clipboard",
        });
    }
  };

  return (
    <Box
      key={`fixture-list-fixture-${fixture.fixture.id}`}
      header={
        <Stack gap="none">
          <TeamsHeader {...teamsHeaderProps} />
          <Stack align="center">
            <Dropdown
              onSelect={handleDropdownChange}
              isRight
              items={
                <>
                  <DropdownItem value="match-details" icon="SoccerBall">
                    Match Details
                  </DropdownItem>
                  <DropdownItem value="fixture-id" icon="Code">
                    Copy Fixture ID
                  </DropdownItem>
                </>
              }
            >
              <Button variant="white">
                <Icon icon="DotsThreeVertical" />
              </Button>
            </Dropdown>
          </Stack>
        </Stack>
      }
    >
      <MatchDetailsDrawer
        isOpen={matchDetailsOpen}
        onClose={() => setMatchDetailsOpen(false)}
        fixture={fixture}
      />
      <TipsTable id={fixture.fixture.id} />
    </Box>
  );
};

export const FixtureList = () => {
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const { data, isLoading } = useGetLeagueFixtures(dayjs(date).toDate());

  return (
    <Stack direction="column">
      <Text variant="secondary">Fixtures</Text>
      <CalendarSlider value={date} onChange={setDate} />
      <Columns>
        <Column>
          <Stack direction="column" gap="xl">
            {isLoading && <Text>Loading ...</Text>}
            {data?.sort().map((league) => {
              return (
                <Stack
                  direction="column"
                  gap="xs"
                  key={`fixture-list-${league.id}`}
                >
                  <Text variant="secondary" size="small">
                    {league.name}
                  </Text>
                  {league.fixtures
                    .sort(
                      (a, b) =>
                        dayjs(a.fixture.date).unix() -
                        dayjs(b.fixture.date).unix(),
                    )
                    .map((fixture) => (
                      <TipBox
                        key={`fixture-list-fixture-${fixture.fixture.id}`}
                        fixture={fixture}
                      />
                    ))}
                </Stack>
              );
            })}
          </Stack>
        </Column>
      </Columns>
    </Stack>
  );
};
