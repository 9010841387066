import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";

import "./Title.scss";
import { Colors } from "@ui/types";

type TitleProps = PropsWithChildren<{
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  color?: Colors;
  hasIcon?: boolean;
  isLoading?: boolean;
  noWrap?: boolean;
  align?: "left" | "centered" | "right";
}>;

export const Title: FC<TitleProps> = ({
  children,
  size,
  hasIcon,
  isLoading,
  color,
  noWrap,
  align = "left",
}) => {
  return (
    <p
      className={classNames("title", `has-text-${align}`, {
        [`is-${size}`]: !!size,
        [`has-text-${color}`]: !!color,
        "icon-text": hasIcon,
        "is-skeleton": isLoading,
        "no-wrap": noWrap,
      })}
    >
      {children}
    </p>
  );
};
