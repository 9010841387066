import React, { ReactNode } from "react";

import {
  Table,
  TableData as UiTableData,
  TableHeading,
  TableRow,
} from "@ui/elements";

type TableData = Record<string, unknown>;

export type ColumnDef<TableShape, TableContext = Record<string, never>> = {
  header: string;
  name: keyof TableShape;
  isExpanded?: boolean;
  type?: "string" | "number" | "percentage" | "odds" | "currency";
  cellRenderer?: (
    value: TableShape[keyof TableShape],
    record: TableShape,
    context: TableContext,
  ) => ReactNode | string | number;
  // Renderer can take the value of the data type.
};

type DataTableProps<TableShape extends TableData, TableContext> = {
  columnDefs: ColumnDef<TableShape, TableContext>[];
  data: Array<TableShape>;
  context?: TableContext;
};

export const DataTable = <
  TableShape extends TableData,
  TableContext extends Record<string, unknown> = NonNullable<unknown>,
>({
  columnDefs,
  data,
  context,
}: DataTableProps<TableShape, TableContext>) => {
  return (
    <Table>
      <TableRow>
        {columnDefs.map(({ header, name, isExpanded, cellRenderer }) => {
          const allDataEmpty = data.every((record) => {
            const content = cellRenderer
              ? cellRenderer(record[name], record, context as TableContext)
              : (record[name] as unknown as ReactNode);

            return typeof content === "undefined";
          });

          if (allDataEmpty) {
            return null;
          }

          return (
            <TableHeading
              key={`table-heading-${String(name)}`}
              isFullwidth={isExpanded}
            >
              {header}
            </TableHeading>
          );
        })}
      </TableRow>

      {data.map((record, index) => (
        <TableRow key={`table-data-row-${index}`}>
          {columnDefs.map(({ name, cellRenderer }) => {
            const content = cellRenderer
              ? cellRenderer(record[name], record, context as TableContext)
              : (record[name] as unknown as ReactNode);

            if (typeof content !== "undefined") {
              return (
                <UiTableData key={`table-data-${index}-${String(name)}`}>
                  {content}
                </UiTableData>
              );
            }

            return null;
          })}
        </TableRow>
      ))}
    </Table>
  );
};
