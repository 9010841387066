import React, { FC } from "react";

import classNames from "classnames";

import { CustomTipItem } from "../../../../services/api/prediction-summary/createCustomPrediction";
import "./CustomTipCard.scss";

type CustomTipCardProps = {
  items: CustomTipItem[];
  index: number;
};

export const CustomTipCard: FC<CustomTipCardProps> = ({ items, index }) => {
  return (
    <div>
      <p className="title is-5">#{index}</p>
      {items
        .sort((a, b) => b.confidence - a.confidence)
        .map((e) => {
          return (
            <div
              key={`custom-tip-${e.tip}-${e.rationale}`}
              className="message mb-3 is-small"
            >
              <div className="message-header custom-tip-header">
                <p>{e.tip}</p>
                <div className="tags">
                  <div
                    className={classNames([
                      "tag",
                      {
                        "is-success": e.confidence >= 8,
                        "is-warning": e.confidence < 8 && e.confidence > 5,
                        "is-danger": e.confidence <= 5,
                      },
                    ])}
                  >
                    {e.confidence}
                  </div>
                  <div className="tag is-white">{e.bet_amount}</div>
                </div>
              </div>
              <div className="message-body">
                <div className="content is-small">
                  <p>{e.rationale}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
