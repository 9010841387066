import React, { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserAttributes } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { Button, Text } from "@ui/elements";
import { Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster, useAuth } from "@components";

type UserAttributes = {
  email: string;
  firstName: string;
  lastName: string;
};

const formSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name name is required"),
  email: Yup.string().required("E-mail is required"),
});

export const UserAttributes = () => {
  const authContext = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UserAttributes>({
    defaultValues: {
      email: authContext.userAttributes?.email,
      firstName: authContext.userAttributes?.given_name,
      lastName: authContext.userAttributes?.family_name,
    },
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (values: UserAttributes) => {
    setLoading(true);
    setError("");
    try {
      await updateUserAttributes({
        userAttributes: {
          given_name: values.firstName,
          family_name: values.lastName,
        },
      });

      toaster.success({
        title: "Successful update!",
        message: "We've updated your personal information.",
      });
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap="xl">
        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}
        <Text variant="secondary">
          Manage your personal information like your name, email, and contact
          number. Your email address is your primary identifier and cannot be
          changed.
        </Text>
        <Stack direction="column">
          <Input
            label="E-mail"
            disabled
            autoComplete="username"
            type="email"
            error={errors["email"]?.message}
            {...register("email")}
          />
          <Stack>
            <Input
              label="First name"
              error={errors["firstName"]?.message}
              type="text"
              isFullwidth
              {...register("firstName")}
            />
            <Input
              label="Last name"
              error={errors["lastName"]?.message}
              type="text"
              isFullwidth
              {...register("lastName")}
            />
          </Stack>
        </Stack>

        <Stack justify="end">
          <Button variant="light" isLoading={loading}>
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
