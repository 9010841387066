import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type FillResultsArgs = {
  id: string | number;
};

export type FillResultsResponse = {
  success: boolean;
  message: string;
};

export const createMathPrediction: MutateFunction<
  FillResultsResponse,
  DefaultErrorResponse,
  FillResultsArgs
> = ({ id }) => {
  return client.post("fill-results", { id }).then((res) => res.data);
};

export const FillResultsMutationKey = "fill-results";

export const useFillResults = (
  options?: Omit<
    UseMutationOptions<
      FillResultsResponse,
      DefaultErrorResponse,
      FillResultsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  FillResultsResponse,
  DefaultErrorResponse,
  FillResultsArgs
> => {
  return useMutation([FillResultsMutationKey], createMathPrediction, options);
};
