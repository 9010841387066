import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";

import classNames from "classnames";
import ReactDOM from "react-dom";

import "./Drawer.scss";

type ModalProps = PropsWithChildren<{
  isActive: boolean;
  onClose: () => void;
  header?: string | ReactElement;
  footer?: string | ReactElement;
}>;
export const Drawer: FC<ModalProps> = ({
  isActive,
  onClose,
  children,
  header,
  footer,
}) => {
  const [shouldRender, setShouldRender] = useState(isActive);
  const [isVisible, setIsVisible] = useState(isActive);

  useEffect(() => {
    if (isActive) {
      setShouldRender(true);
      document.body.classList.add("is-clipped");
      setTimeout(() => setIsVisible(true), 10);
    } else {
      setIsVisible(false);
      document.body.classList.remove("is-clipped");
      const timeoutId = setTimeout(() => setShouldRender(false), 300); // Delay matches the CSS transition duration
      return () => clearTimeout(timeoutId);
    }

    return () => {
      document.body.classList.remove("is-clipped");
    };
  }, [isActive]);

  if (!shouldRender) return null;

  return ReactDOM.createPortal(
    <div className={classNames("drawer", { "is-active": isVisible })}>
      <div className="drawer-background" onClick={onClose}></div>
      <div className="drawer-card">
        {header && <header className="drawer-card-head">{header}</header>}
        <section className="drawer-card-body">{children}</section>
        {footer && <footer className="drawer-card-foot">{footer}</footer>}
      </div>
    </div>,
    document.body,
  );
};
