import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

export type DeleteAccountResponse = {
  error?: string;
  message: string;
};

const mutateDeleteAccount: MutationFunction<DeleteAccountResponse> = () => {
  return client.delete("/delete-account").then((res) => res.data);
};

export const useDeleteAccount = (
  options?: UseMutationOptions<DeleteAccountResponse, DefaultErrorResponse>,
): UseMutationResult<DeleteAccountResponse, DefaultErrorResponse, void> => {
  return useMutation<DeleteAccountResponse, DefaultErrorResponse, void>(mutateDeleteAccount, options);
};
