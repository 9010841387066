import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { useForm } from "react-hook-form";

import { useDevice } from "@utils";

import { League, TipItem, useGetUpcomingTips } from "@api/math-tips";

import { InfoBox, TabItem, TabList, TabPanel, Tabs } from "@ui/components";
import { Icon } from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";

import {
  TipCard,
  TipFilter,
  TipFilterValues,
  useTipFilterDefaultValues,
  TipCardSkeleton,
  TipsTableContext,
  TipTableColumnDefs,
  DataTable,
  SubscriptionWrapper,
} from "@components";

import "./UpcomingTips.scss";

import { ResultOrientedTipsV2 } from "./ResultOrientedTipsV2/ResultOrientedTipsV2";

export const UpcomingTips = () => {
  const defaultValues = useTipFilterDefaultValues("upcoming-tips", {
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
    timePeriod: "today",
  });
  const [filterValues, setFilterValues] =
    useState<TipFilterValues>(defaultValues);
  const form = useForm<TipFilterValues>({
    defaultValues,
  });
  const [availableLeagues, setAvailableLeagues] = useState<League[]>([]);

  const { data: upcomingTipsData, isLoading: upcomingTipsLoading } =
    useGetUpcomingTips(filterValues);

  useEffect(() => {
    if (upcomingTipsData?.available_leagues) {
      setAvailableLeagues(upcomingTipsData?.available_leagues || []);
    }
  }, [upcomingTipsData?.available_leagues]);

  const device = useDevice();

  return (
    <SubscriptionWrapper>
      <Tabs active="value-based">
        <Columns isMultiline>
          <Column tablet={12} widescreen={5} fullhd={4}>
            <TipFilter
              id="upcoming-tips"
              form={form}
              onSubmit={setFilterValues}
              enabledTimePeriods={["today", "tomorrow"]}
              leagueOptions={availableLeagues?.map((league) => ({
                label: league?.name,
                value: league?.id,
              }))}
              allowedFilters={{
                betAmount: true,
                edge: true,
                leagues: true,
                odds: true,
                tipRating: true,
                tipStatus: false,
              }}
            />
          </Column>
          <Column tablet={12} widescreen={7} fullhd={8}>
            <Stack direction="column">
              <TabList>
                <TabItem tab="value-based">
                  <Icon icon="ChartBar" />
                  <span>Value Based</span>
                </TabItem>
                <TabItem tab="result-oriented">
                  <Icon icon="Target" />
                  <span>Result Oriented</span>
                </TabItem>
              </TabList>
              <TabPanel tab="value-based">
                <Stack direction="column" gap="lg">
                  {!upcomingTipsLoading && !upcomingTipsData?.data.length && (
                    <InfoBox
                      icon="SmileyXEyes"
                      hasSpacing
                      title="Upcoming tips not found ..."
                      secondaryButtonLabel="Reset Filters"
                      secondaryButtonAction={() =>
                        form.reset({
                          timePeriod: "today",
                          enabledFilters: ["tipRating"],
                          include_ratings: ["safe", "value", "risky"],
                        })
                      }
                    >
                      We couldn&apos;t find any tips with your filter criteria.{" "}
                      <br />
                      Come back later or modify your search.
                    </InfoBox>
                  )}
                  {upcomingTipsLoading && (
                    <>
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                      <TipCardSkeleton />
                    </>
                  )}
                  {upcomingTipsData?.data.map(({ fixture, tips }) => (
                    <TipCard
                      fixture={fixture}
                      key={`upcoming-tip-card-${fixture.fixture.id}`}
                    >
                      <DataTable<TipItem, TipsTableContext>
                        context={{ fixture }}
                        columnDefs={
                          device === "mobile"
                            ? [
                                TipTableColumnDefs.tip,
                                TipTableColumnDefs.ratingPercentage,
                                TipTableColumnDefs.userOdds,
                                TipTableColumnDefs.userBetAmount,
                                TipTableColumnDefs.saveTip,
                              ]
                            : [
                                TipTableColumnDefs.tip,
                                TipTableColumnDefs.rating,
                                TipTableColumnDefs.probability,
                                TipTableColumnDefs.userOdds,
                                TipTableColumnDefs.userBetAmount,
                                TipTableColumnDefs.saveTip,
                              ]
                        }
                        data={tips}
                      />
                    </TipCard>
                  ))}
                </Stack>
              </TabPanel>
              <TabPanel tab="result-oriented">
                <ResultOrientedTipsV2 filterValues={filterValues} />
              </TabPanel>
            </Stack>
          </Column>
        </Columns>
      </Tabs>
    </SubscriptionWrapper>
  );
};
