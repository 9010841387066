import React, { FC, useState } from "react";

import { useDeleteUserTips } from "@api/user/deleteTips";

import { Modal } from "@ui/components";
import { Button, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import { toaster } from "@components";

type DeleteConfirmationModalProps = {
  onClose: () => void;
  open: boolean;
};

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  onClose,
  open,
}) => {
  const {
    mutateAsync: deleteTips,
    isLoading: deleteTipsLoading,
    error: deleteTipsError,
  } = useDeleteUserTips({
    onSuccess: () => {
      onClose();

      toaster({
        title: "Successful update!",
        message: "We've deleted your portfolio history.",
      });
    },
  });

  return (
    <Modal
      isActive={open}
      onClose={onClose}
      header="Are you sure?"
      style={{ zIndex: 100 }}
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="xl">
          <Stack direction="column">
            <Text>
              This action will permanently delete all saved tips and cannot be
              undone.
            </Text>
          </Stack>
        </Stack>
        {deleteTipsError && (
          <div className="notification is-danger is-light is-size-7">
            {deleteTipsError?.response?.data.error}
          </div>
        )}
        <Stack justify="end">
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="light"
            color="danger"
            onClick={() => deleteTips()}
            isLoading={deleteTipsLoading}
          >
            Yes, i&apos;m sure
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export const MyBets = () => {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="column" gap="xl">
      <DeleteConfirmationModal open={open} onClose={() => setOpen(false)} />
      <Text variant="secondary">
        Resetting your portfolio will delete all saved tips permanently. This
        action cannot be undone.
      </Text>
      <Stack justify="end">
        <Button color="danger" variant="light" onClick={() => setOpen(true)}>
          Reset My Bets
        </Button>
      </Stack>
    </Stack>
  );
};
