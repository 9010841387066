import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type ExpertOpinion = {
  content: string;
  page: string;
  search_result: {
    kind: string;
    title: string;
    htmlTitle: string;
    link: string;
    displayLink: string;
    snippet: string;
    htmlSnippet: string;
    cacheId: string;
    formattedUrl: string;
    htmlFormattedUrl: string;
    pagemap: {
      cse_thumbnail: [
        {
          src: string;
          width: string;
          height: string;
        },
      ];
      metatags: [
        {
          "og:image": string;
          "og:type": string;
          "og:image:width": string;
          "twitter:card": string;
          "og:site_name": string;
          "og:title": string;
          "og:image:height": string;
          "twitter:label1": string;
          "og:image:type": string;
          "msapplication-tileimage": string;
          "og:description": string;
          "twitter:data1": string;
          viewport: string;
          "og:locale": string;
          "og:url": string;
        },
      ];
      cse_image: [
        {
          src: string;
        },
      ];
    };
  };
};

export type GetExpertOpinionsResponse = {
  error?: string;
  results: ExpertOpinion[];
};

const getExpertOpinions: QueryFunction<GetExpertOpinionsResponse> = ({
  queryKey,
}) => {
  const [_key, id] = queryKey;

  return client
    .get("expert-opinions", {
      params: {
        id,
      },
    })
    .then((res) => res.data);
};

export const GET_EXPERT_OPINIONS_QUERY_KEY = "get-expert-opinions";

export const useGetExpertOpinions = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetExpertOpinionsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetExpertOpinionsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_EXPERT_OPINIONS_QUERY_KEY, id],
    getExpertOpinions,
    options,
  );
};
