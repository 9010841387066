import React from "react";

import { Id, toast, ToastContentProps, ToastOptions } from "react-toastify";

import { Icon, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./Toaster.scss";

type ToastMessageProps = {
  title: string;
  message: string;
};

export const Msg = ({
  data: { title, message },
}: ToastContentProps<ToastMessageProps>) => {
  return (
    <Stack direction="column" gap="none">
      <Text weight="bold">{title}</Text>
      <Text variant="secondary">{message}</Text>
    </Stack>
  );
};

export const toaster = (
  myProps: ToastMessageProps,
  toastProps?: ToastOptions<ToastMessageProps>,
): Id => toast((props) => <Msg {...props} data={myProps} />, { ...toastProps });

toaster.success = (
  myProps: ToastMessageProps,
  toastProps?: ToastOptions<ToastMessageProps>,
): Id =>
  toast.success((props) => <Msg {...props} data={myProps} />, {
    ...toastProps,
    icon: (
      <Icon icon="CheckCircle" weight="fill" className="has-text-success" />
    ),
  });

toaster.warning = (
  myProps: ToastMessageProps,
  toastProps?: ToastOptions<ToastMessageProps>,
): Id =>
  toast.warning((props) => <Msg {...props} data={myProps} />, {
    ...toastProps,
    icon: <Icon icon="Warning" weight="fill" className="has-text-warning" />,
  });
