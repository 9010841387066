import React, { useMemo, useState } from "react";

import { useForm } from "react-hook-form";

import { withController } from "@utils";

import { useUserPreferences } from "@api/user/getPreferences";
import { useUpdatePreferences } from "@api/user/updatePreferences";

import { Button, Text } from "@ui/elements";
import { Slider } from "@ui/forms";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import { toaster } from "@components";

type BetAmountFormFields = {
  kellyFraction: number;
};

const ControlledSlider = withController<BetAmountFormFields>()(Slider);

const DEFAULT_KELLY = 0.25;

export const BetAmount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { data: userPreferenceData } = useUserPreferences({
    onSuccess: (data) => setValue("kellyFraction", Number(data.kellyFraction)),
  });
  const { handleSubmit, watch, control, setValue } =
    useForm<BetAmountFormFields>({
      defaultValues: {
        kellyFraction: Number(userPreferenceData?.kellyFraction),
      },
    });
  const { mutateAsync } = useUpdatePreferences();

  const kellyFraction = watch("kellyFraction");

  const onSubmit = async (values: BetAmountFormFields) => {
    setLoading(true);
    setError("");

    try {
      await mutateAsync(values);

      toaster.success({
        title: "Successful update!",
        message: "We've updated your preferred kelly fraction.",
      });
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  const kellyStrategy: Colors = useMemo(() => {
    if (kellyFraction <= 0.25) {
      return "info";
    }
    if (kellyFraction > 0.25 && kellyFraction <= 0.5) {
      return "warning";
    }
    if (kellyFraction >= 0.55) {
      return "danger";
    }

    return "text";
  }, [kellyFraction]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap="xl">
        {error && (
          <div className="notification is-danger is-light">{error}</div>
        )}
        <Stack direction="column">
          <Text variant="secondary">
            We use{" "}
            <a
              href="https://www.investopedia.com/terms/k/kellycriterion.asp"
              target="_blank"
              rel="noreferrer"
            >
              Kelly Criterion
            </a>
            , a mathematical formula used to determine the optimal size of a bet
            to maximize the growth of your bankroll over time.
          </Text>
          <Text variant="secondary">
            By setting your Kelly fraction, you can control the level of risk
            you are willing to take. The closer it is to 0, the smaller the bet
            amount.
          </Text>
        </Stack>

        <Stack direction="column" gap="xxs">
          <ControlledSlider
            name="kellyFraction"
            label="Kelly fraction"
            step={0.05}
            min={0.05}
            max={1}
            control={control}
            type={kellyStrategy}
          />
          {kellyStrategy === "info" && <Text color="info">Conservative</Text>}
          {kellyStrategy === "warning" && <Text color="warning">Volatile</Text>}
          {kellyStrategy === "danger" && (
            <Text color="danger">Highly Volatile</Text>
          )}
        </Stack>

        <Stack justify="between">
          {kellyFraction !== DEFAULT_KELLY ? (
            <Button
              type="button"
              variant="text"
              onClick={() => setValue("kellyFraction", DEFAULT_KELLY)}
            >
              Reset to default
            </Button>
          ) : (
            <div />
          )}
          <Button variant="light" isLoading={loading}>
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
