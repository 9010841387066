import { createClient } from "../index";

import { PlayerPosition, StatisticsKeys } from "./fetchStatistics";

export const teamStatisticsContentMapping: Record<StatisticsKeys, string> = {
  shots_on_target: "Shots on target",
  shots_off_target: "Shots off target",
  total_shots: "Total shots",
  blocked_shots: "Blocked shots",
  shots_insidebox: "Shots inside box",
  shots_outsidebox: "Shots outside box",
  fouls: "Fouls",
  corners: "Corners",
  offsides: "Offsides",
  ball_possessions: "Ball possession",
  yellow_cards: "Yellow cards",
  red_cards: "Red cards",
  goalkeeper_saves: "Goalkeeper saves",
  total_passes: "Total passes",
  passes_accurate: "Accurate passes",
  passes_percentage: "Pass accuracy (%)",
  expected_goals: "Expected goals",
  shot_accuracy: "Shot accuracy (%)",
  first_half_goals: "1st half goals",
  second_half_goals: "2nd half goals",
};

export const playerPositionContentMapping: Record<PlayerPosition, string> = {
  D: "Defender",
  M: "Midfielder",
  F: "Forward",
  G: "Goalkeeper",
};

export const client = createClient("statistics");
