import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client, Currency } from "../client";

export type GetCurrenciesResponse = Currency[];

const fetchCurrencies: QueryFunction<GetCurrenciesResponse> = ({
  queryKey,
}) => {
  const [_key] = queryKey;

  return client.get("/currencies").then((res) => res.data);
};

export const GET_CURRENCIES_QUERY_KEY = "get-currencies";

export const useGetCurrencies = (
  options?: Omit<
    UseQueryOptions<GetCurrenciesResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetCurrenciesResponse, DefaultErrorResponse> => {
  return useQuery([GET_CURRENCIES_QUERY_KEY], fetchCurrencies, options);
};
