import React, { useState } from "react";

import dayjs from "dayjs";
import { Link } from "react-router-dom";

import {
  capitalize,
  formatHitRate,
  formatOdds,
  formatProbability,
  formatRoi,
} from "@utils";

import { TipsQueryArgs, useGetTopPerformers } from "@api/math-tips";
import { useGetTipsSummary } from "@api/math-tips/getTipsSummary";

import { List, InfoBox, TitleBlock } from "@ui/components";
import { Box, Button, IconCard, Text, Title } from "@ui/elements";
import { ButtonSelect } from "@ui/forms";
import { Cell, Grid, Stack } from "@ui/layout";

import {
  CategoryIconCardProps,
  RatingIconCardProps,
} from "../../../ai-performance/TopPerformers";

const Periods = {
  "7d": "7d",
  "30d": "30d",
  "6m": "6m",
} as const;
type Periods = (typeof Periods)[keyof typeof Periods];

const TODAY = dayjs().format("YYYY-MM-DD");

const TIME_PERIOD_MAP: Record<Periods, TipsQueryArgs> = {
  "7d": {
    from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "30d": {
    from: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "6m": {
    from: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
    to: TODAY,
  },
};

export const AIPerformancePanel = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<Periods>("30d");
  const { data: summaryData, isLoading: summaryLoading } = useGetTipsSummary({
    page: 1,
    ...TIME_PERIOD_MAP[selectedPeriod],
  });
  const { data: topPerformersData, isLoading: topPerformersLoading } =
    useGetTopPerformers(TIME_PERIOD_MAP[selectedPeriod]);

  const topLeague = topPerformersData?.league_performance[0];
  const topCategory = topPerformersData?.category_performance[0];
  const topRating = topPerformersData?.rating_performance[0];

  return (
    <Stack direction="column" gap="md">
      <TitleBlock title="AI Performance" subtitle="Across all tip ratings">
        <ButtonSelect
          isGrouped={false}
          options={Object.values(Periods).map((period) => ({
            label: period,
            value: period,
          }))}
          value={selectedPeriod}
          onChange={(period) => setSelectedPeriod(period as Periods)}
        />
      </TitleBlock>
      <Stack isFullwidth direction="column" gap="xl">
        <Grid type="fixed" cols={{ mobile: 2, tablet: 1, desktop: 2 }}>
          <Cell colSpan={{ mobile: 2, tablet: 1, desktop: 2 }}>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Target" />
                <Stack direction="column" gap="none">
                  <Text size="small" noWrap>
                    Hit Rate
                  </Text>
                  <Title noWrap size={5} isLoading={summaryLoading}>
                    {formatHitRate(summaryData?.summary.hit_rate)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Coin" />
                <Stack direction="column" gap="none">
                  <Text size="small" noWrap>
                    Avg. Probability
                  </Text>
                  <Title size={5} noWrap isLoading={summaryLoading}>
                    {formatProbability(summaryData?.summary.avg_probability)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Scales" />
                <Stack direction="column" gap="none">
                  <Text size="small" noWrap>
                    Avg. Odds
                  </Text>
                  <Title size={5} noWrap isLoading={summaryLoading}>
                    {formatOdds(summaryData?.summary.avg_odds)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
        </Grid>
        <Stack direction="column">
          {!topPerformersLoading &&
          !topPerformersData?.category_performance.length ? (
            <InfoBox
              icon="SmileyXEyes"
              hasSpacing
              secondaryButtonLabel="Reset Time Period"
              secondaryButtonAction={() => setSelectedPeriod("30d")}
            >
              Not enough tips to aggregate top performers.
              <br />
              Try again later, or modify time period.
            </InfoBox>
          ) : (
            <Box>
              <Stack direction="column">
                <Stack direction="column" gap="none">
                  <Text>Top Performers</Text>
                  <Text variant="secondary" size="small">
                    By ROI / Hit Rate
                  </Text>
                </Stack>
                <Stack direction="column">
                  {topLeague && (
                    <List
                      title={topLeague.league.name}
                      subtitle="League"
                      value={formatRoi(topLeague.roi)}
                      subvalue={formatHitRate(topLeague.hit_rate)}
                      image={topLeague.league.logo}
                    />
                  )}
                  {topRating && (
                    <List
                      title={capitalize(topRating.rating)}
                      subtitle="Tip Rating"
                      value={formatRoi(topRating.roi)}
                      subvalue={formatHitRate(topRating.hit_rate)}
                      iconProps={RatingIconCardProps[topRating.rating]}
                    />
                  )}
                  {topCategory && (
                    <List
                      title={capitalize(topCategory.category)}
                      value={formatRoi(topCategory.roi)}
                      subtitle="Tip Category"
                      subvalue={formatHitRate(topCategory.hit_rate)}
                      iconProps={CategoryIconCardProps[topCategory.category]}
                    />
                  )}
                  {topPerformersLoading &&
                    Array(3)
                      .fill("")
                      .map((_, i) => {
                        return (
                          <List
                            key={`category-performance-skeleton-${i}`}
                            title="Goals Over/Under"
                            value="+24.5%"
                            isLoading={true}
                            subvalue="50%"
                            iconProps={{ icon: "Trophy" }}
                          />
                        );
                      })}
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>
      </Stack>
      <Link to="/ai-performance">
        <Button variant="text">More Details</Button>
      </Link>
    </Stack>
  );
};
