import React, { useMemo } from "react";

import { Button, ImageComponent } from "@ui/elements";
import { Stack } from "@ui/layout";

export const OpenEmailButtons = () => {
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  const gmail = useMemo(() => {
    if (isMobile) {
      if (/Android/i.test(navigator.userAgent)) {
        return "intent://#Intent;scheme=googlegmail;package=com.google.android.gm;end";
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return "googlegmail://";
      }
    } else {
      return "https://gmail.com/";
    }

    return "";
  }, [isMobile]);

  const outlook = useMemo(() => {
    if (isMobile) {
      if (/Android/i.test(navigator.userAgent)) {
        return "intent://#Intent;scheme=ms-outlook;package=com.microsoft.office.outlook;end";
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return "ms-outlook://";
      }
    } else {
      return "https://outlook.live.com/";
    }

    return "";
  }, [isMobile]);

  return (
    <Stack direction="column">
      <Button as="link" target="_blank" href={gmail} isFullwidth>
        <Stack align="center" gap="xs" isFullwidth>
          <ImageComponent size={16} src="icons/gmail.svg" />
          <span>View in Gmail</span>
        </Stack>
      </Button>
      <Button as="link" target="_blank" href={outlook} isFullwidth>
        <Stack align="center" gap="xs" isFullwidth>
          <ImageComponent size={16} src="icons/outlook.svg" />
          <span>View in Outlook</span>
        </Stack>
      </Button>
    </Stack>
  );
};
