import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";
import { TipItem } from "@api/math-tips";

import { client } from "../client";

type GetFixtureTipsArgs = {
  id?: string;
};

type GetFixtureTipsResponse = TipItem[];

const fetchFixtureTips: QueryFunction<GetFixtureTipsResponse> = ({
  queryKey,
}) => {
  const [_key, id] = queryKey;

  return client
    .get("/fixture-tips", {
      params: {
        id,
      },
    })
    .then((res) => res.data);
};

export const GET_FIXTURE_TIPS_QUERY_KEY = "get-fixture-tips";

export const useGetFixtureTips = (
  args?: GetFixtureTipsArgs,
  options?: Omit<
    UseQueryOptions<GetFixtureTipsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetFixtureTipsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_FIXTURE_TIPS_QUERY_KEY, args?.id],
    fetchFixtureTips,
    options,
  );
};
