import { useEffect, useState } from "react";

import { fetchAuthSession } from "aws-amplify/auth";

export const useIsAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      setError("");
      setIsAdmin(false);
      setIsLoading(true);

      try {
        const resp = await fetchAuthSession();
        const groups =
          (resp.tokens?.idToken?.payload["cognito:groups"] as Array<string>) ||
          [];

        if (groups.includes("Admins") || groups.includes("Admin")) {
          setIsAdmin(true);
          setIsLoading(false);
        }
      } catch (error) {
        if (error instanceof Error) {
          return setError(error.message);
        }

        setError(String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  return { isLoading, isInGroup: isAdmin, error };
};
