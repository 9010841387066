import React, { FC } from "react";

import dayjs from "dayjs";

import { capitalize } from "@utils";

import { useGetSubscriptions } from "@api/payment/getSubscriptions";

import { Modal } from "@ui/components";
import {
  Box,
  Button,
  Icon,
  Notification,
  Tag,
  Text,
  Title,
} from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";

import { ContactChatBox } from "../ContactChatBox/ContactChatBox";

const SubscriptionTableSkeleton = () => {
  return (
    <Grid>
      <Cell>
        <Box>
          <Stack direction="column" gap="xl">
            <Stack justify="between">
              <Text variant="secondary" isLoading>
                Weekly
              </Text>
            </Stack>
            <Stack align="end" gap="xs">
              <Title isLoading>4.5E</Title>
              <Text variant="secondary" isLoading>
                / week
              </Text>
            </Stack>
            <Button isLoading color="primary">
              Choose Plan
            </Button>
          </Stack>
        </Box>
      </Cell>
      <Cell>
        <Box>
          <Stack direction="column" gap="xl">
            <Stack justify="between">
              <Text variant="secondary" isLoading>
                Weekly
              </Text>
            </Stack>
            <Stack align="end" gap="xs">
              <Title isLoading>4.5E</Title>
              <Text variant="secondary" isLoading>
                / week
              </Text>
            </Stack>
            <Button isLoading color="primary">
              Choose Plan
            </Button>
          </Stack>
        </Box>
      </Cell>
    </Grid>
  );
};

type SubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubscriptionModal: FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const {
    data: subscriptionData,
    error: subscriptionError,
    isLoading: subscriptionLoading,
  } = useGetSubscriptions();

  return (
    <Modal
      isActive={isOpen}
      onClose={onClose}
      header={
        <Stack align="center">
          <Icon icon="Sparkle" />
          <Title size={4}>Subscription</Title>
        </Stack>
      }
    >
      <Stack direction="column" gap="xxl">
        <Stack direction="column">
          {subscriptionError && (
            <Notification color="danger">
              <Text weight="bold">
                {subscriptionError.response?.data.error}
              </Text>
              <Text>{subscriptionError.response?.data.message}</Text>
            </Notification>
          )}
          {subscriptionLoading && <SubscriptionTableSkeleton />}
          {subscriptionData && (
            <Grid>
              {subscriptionData?.subscriptions?.map((subscription) => (
                <Cell key={subscription.id}>
                  <Box>
                    <Stack direction="column" gap="xl">
                      <Stack justify="between">
                        <Text variant="secondary">
                          {capitalize(subscription.interval)}ly
                        </Text>
                        <Stack>
                          {subscription.cancels_at_date && (
                            <Tag>
                              <Icon icon="Alarm" size="small" />
                              <span>
                                Cancels at{" "}
                                {dayjs(subscription.cancels_at_date).format(
                                  "MMM DD",
                                )}
                              </span>
                            </Tag>
                          )}
                          {subscription.active_subscription &&
                            !subscription.cancels_at_date && (
                              <Icon
                                icon="CheckCircle"
                                weight="fill"
                                className="has-text-success"
                              />
                            )}
                        </Stack>
                      </Stack>
                      <Stack align="end" gap="xs">
                        <Title>€{subscription.price}</Title>
                        <Text variant="secondary">
                          / {subscription.interval}
                        </Text>
                      </Stack>
                      {subscription.active_subscription ? (
                        subscription.cancels_at_date ? (
                          <Button
                            as="link"
                            href={subscriptionData?.renew_subscription_link}
                          >
                            Renew Plan
                          </Button>
                        ) : (
                          <Button disabled>Active Plan</Button>
                        )
                      ) : (
                        <Button
                          color="primary"
                          as="link"
                          href={
                            subscriptionData?.has_active_subscription
                              ? subscriptionData?.update_subscription_link
                              : subscription.payment_link
                          }
                        >
                          {subscriptionData?.has_active_subscription
                            ? "Update Plan"
                            : "Choose Plan"}
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Cell>
              ))}
            </Grid>
          )}
          {subscriptionData?.manage_subscription_link && (
            <Stack>
              <Button
                variant="text"
                as="link"
                href={subscriptionData?.manage_subscription_link}
              >
                Manage your subscription
              </Button>
            </Stack>
          )}
        </Stack>
        <ContactChatBox />
      </Stack>
    </Modal>
  );
};
