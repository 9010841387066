import React, {
  useState,
  useEffect,
  useRef,
  PropsWithChildren,
  createContext,
  useContext,
  FC,
  ReactElement,
} from "react";

import classNames from "classnames";

import { Icon, Icons } from "@ui/elements";
import "./Dropdown.scss";

type DropdownContextType = {
  handleItemClick: (value: string) => void;
};

const DropdownContext = createContext<DropdownContextType | undefined>(
  undefined,
);

export const useDropdown = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error("useDropdown must be used within a Dropdown provider");
  }
  return context;
};

type DropdownProps = PropsWithChildren<{
  items: ReactElement;
  onSelect?: (item: string) => void;
  isRight?: boolean;
  isHoverable?: boolean;
  isUp?: boolean;
}>;

export const Dropdown: React.FC<DropdownProps> = ({
  items,
  onSelect,
  isRight,
  isHoverable,
  isUp,
  children,
}) => {
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleItemClick = (item: string) => {
    setIsActive(false);
    onSelect?.(item);
  };

  return (
    <DropdownContext.Provider value={{ handleItemClick }}>
      <div
        className={classNames([
          "dropdown",
          {
            "is-active": isActive,
            "is-right": isRight,
            "is-hoverable": isHoverable,
            "is-up": isUp,
          },
        ])}
        ref={dropdownRef}
      >
        <div
          className="dropdown-trigger"
          onClick={() => setIsActive(!isActive)}
        >
          {children}
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">{items}</div>
        </div>
      </div>
    </DropdownContext.Provider>
  );
};

type DropdownItemProps = PropsWithChildren<{
  value: string;
  icon?: Icons;
  isDivider?: boolean;
  isContent?: boolean;
  isSingle?: boolean;
}>;

export const DropdownItem: FC<DropdownItemProps> = ({
  value,
  icon,
  isDivider,
  isContent,
  children,
  isSingle,
}) => {
  const { handleItemClick } = useDropdown();

  if (isDivider) {
    return <hr className="dropdown-divider" />;
  }

  if (isContent) {
    return (
      <div className={classNames("dropdown-item", { "is-single": isSingle })}>
        {children}
      </div>
    );
  }

  return (
    <a
      className={classNames("dropdown-item", { "is-single": isSingle })}
      onClick={() => handleItemClick(value)}
    >
      {icon && <Icon size="small" icon={icon} />}
      <span>{children}</span>
    </a>
  );
};

export const DropdownDivider: FC = () => {
  return <hr className="dropdown-divider" />;
};
