import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  forwardRef,
  PropsWithChildren,
} from "react";

import "./Button.scss";
import classNames from "classnames";

import { Colors, Variants } from "@ui/types";

type ButtonCommonProps = {
  color?: Colors;
  variant?: Variants;
  size?: "small" | "normal" | "medium" | "large";
  isLoading?: boolean;
  isOutlined?: boolean;
  isInverted?: boolean;
  isRounded?: boolean;
  isFullwidth?: boolean;
  isSelected?: boolean;
  isStatic?: boolean;
  disabled?: boolean;
};

type ButtonAsButtonProps = {
  as?: "button";
} & ButtonCommonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonAsLinkProps = {
  as?: "link";
} & ButtonCommonProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = ButtonAsButtonProps | ButtonAsLinkProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = "button",
      children,
      color,
      variant,
      isLoading = false,
      isOutlined = false,
      isInverted = false,
      isRounded = false,
      isFullwidth = false,
      isSelected = false,
      isStatic = false,
      disabled,
      size = "normal",
      className,
      ...rest
    },
    ref,
  ) => {
    const buttonClassName = classNames([
      "button",
      { [`is-${color}`]: color },
      { [`is-${variant}`]: variant },
      { [`is-${size}`]: size },
      { "is-loading": isLoading },
      { "is-outlined": isOutlined },
      { "is-inverted": isInverted },
      { "is-rounded": isRounded },
      { "is-fullwidth": isFullwidth },
      { "is-selected": isSelected },
      { "is-static": isStatic },
      className,
    ]);

    return as === "link" ? (
      <a className={buttonClassName} {...(rest as ButtonAsLinkProps)}>
        {children}
      </a>
    ) : (
      <button
        ref={ref}
        className={buttonClassName}
        disabled={disabled}
        {...(rest as ButtonAsButtonProps)}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";

type ButtonWrapperProps = PropsWithChildren;

export const ButtonWrapper: FC<ButtonWrapperProps> = ({ children }) => {
  return <div className="buttons">{children}</div>;
};
