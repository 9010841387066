import React, { useEffect, useState } from "react";

import { resendSignUpCode } from "aws-amplify/auth";
import { useSearchParams } from "react-router-dom";

import { Button, Divider, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import { OpenEmailButtons, toaster } from "@components";

export const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();
  const [timer, setTimer] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const email = searchParams.get("email");

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (isDisabled) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            clearInterval(interval);
            setIsDisabled(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isDisabled]);

  const handleResendClick = async () => {
    setLoading(true);
    try {
      await resendSignUpCode({ username: email as string });

      toaster.success({
        title: "Re-sending was successful.",
        message: "Please check your mailbox for new verification link",
      });
    } catch (error) {
      let errorMessage: string;

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      errorMessage = String(error);

      toaster.warning({
        title: "Failed to re-send verification link",
        message: errorMessage,
      });
    } finally {
      setLoading(false);
      setTimer(60);
      setIsDisabled(true);
    }
  };

  return (
    <Stack direction="column" gap="md">
      <Stack direction="column" gap="xs">
        <Title size={5}>Verify Your Account</Title>
        <Text>
          Check your email for verification link sent to{" "}
          <strong>{searchParams.get("email")}</strong>.
        </Text>
      </Stack>
      <OpenEmailButtons />
      <Divider />
      <Stack justify="center" align="center">
        <Text variant="secondary" size="small">
          Not recieved?
        </Text>
        <Button
          size="small"
          variant="text"
          isLoading={loading}
          onClick={handleResendClick}
          disabled={isDisabled}
        >
          {isDisabled ? `Re-send in ${timer}s` : "Re-send"}
        </Button>
      </Stack>
    </Stack>
  );
};
