import React, { FC, PropsWithChildren, useMemo, useState } from "react";

import classNames from "classnames";

import {
  formatBetAmount,
  formatCurrency,
  formatOdds,
  formatProbability,
  formatRoi,
  formatTipName,
  useDevice,
} from "@utils";

import { FixtureItem } from "@api/football/types/fixture";
import { TipItem } from "@api/math-tips";
import { Currency } from "@api/user/client";
import { useGetUserTip } from "@api/user/getTip";

import {
  TeamsHeader,
  TeamsInfoItem,
  useTeamsHeaderProps,
} from "@ui/components";
import {
  Box,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Tag,
  Text,
} from "@ui/elements";

import { ColumnDef, MatchDetailsDrawer, TipRating } from "@components";

import { usePageSettings } from "../PageTemplate/PageTemplate";
import { TipSave } from "../TipSave/TipSave";

type UserOddsProps = {
  odds: string;
  id: string;
};

const UserOdds: FC<UserOddsProps> = ({ id, odds }) => {
  const { data: userTipData, isLoading: userTipLoading } = useGetUserTip(id);

  return (
    <Text isLoading={userTipLoading}>
      {userTipData?.odds ? formatOdds(userTipData.odds) : formatOdds(odds)}
    </Text>
  );
};

type UserBetAmountProps = {
  betAmount: string;
  id: string;
};

const UserBetAmount: FC<UserBetAmountProps> = ({ id, betAmount }) => {
  const { data: userTipData, isLoading: userTipLoading } = useGetUserTip(id);
  const { bankroll, currency } = usePageSettings();

  const dynamicBetAmount = useMemo(() => {
    if (bankroll && currency) {
      return `${formatCurrency(
        (bankroll * Number(betAmount)) / 100,
        currency?.code,
        currency?.locale,
      )}`;
    }

    return formatBetAmount(betAmount);
  }, [betAmount, bankroll, currency]);

  return (
    <Text isLoading={userTipLoading} noWrap>
      {userTipData?.bet_amount
        ? formatCurrency(
            userTipData.bet_amount,
            userTipData.currency.code,
            userTipData.currency.locale,
          )
        : dynamicBetAmount}
    </Text>
  );
};

type TipCardProps = PropsWithChildren<{
  fixture: FixtureItem;
}>;

export type TipsTableContext = {
  fixture: FixtureItem;
  currency?: Currency;
};

export const TipTableColumnDefs: Record<
  | "tip"
  | "rating"
  | "probability"
  | "odds"
  | "userOdds"
  | "betAmount"
  | "userBetAmount"
  | "profitLoss"
  | "ratingPercentage"
  | "saveTip",
  ColumnDef<TipItem, TipsTableContext>
> = {
  tip: {
    name: "category",
    header: "Tip",
    isExpanded: true,
    cellRenderer: (_, { name, category }) => formatTipName(category, name),
  },
  rating: {
    name: "rating",
    header: "Rating",
    cellRenderer: (_, data, context) => (
      <TipRating tip={data} fixture={context.fixture} />
    ),
  },
  ratingPercentage: {
    name: "rating",
    header: "Prob.",
    cellRenderer: (_, data, context) => (
      <TipRating tip={data} fixture={context.fixture} tagContent="percentage" />
    ),
  },
  probability: {
    name: "probability",
    header: "Probability",
    cellRenderer: (probability) => formatProbability(probability as string),
  },
  odds: {
    name: "odds",
    header: "Odds",
    cellRenderer: (odds) => formatOdds(odds as string),
  },
  userOdds: {
    name: "odds",
    header: "Odds",
    cellRenderer: (odds, data) => (
      <UserOdds odds={odds as string} id={data.id} />
    ),
  },
  betAmount: {
    name: "bet_amount",
    header: "Stake",
    cellRenderer: (bet_amount, data, context) => (
      <Text noWrap>
        {context.currency?.code
          ? formatCurrency(
              bet_amount as string,
              context.currency.code,
              context.currency.locale,
            )
          : formatBetAmount(bet_amount as string)}
      </Text>
    ),
  },
  userBetAmount: {
    name: "bet_amount",
    header: "Stake",
    cellRenderer: (betAmount, data) => (
      <UserBetAmount betAmount={betAmount as string} id={data.id} />
    ),
  },
  saveTip: {
    name: "id",
    header: "",
    cellRenderer: (_, data, context) => {
      return <TipSave tip={data} fixture={context.fixture} />;
    },
  },
  profitLoss: {
    name: "winner",
    header: "",
    cellRenderer: (_, data, context) => {
      const betAmount = Number(data.bet_amount);
      const odds = Number(data.odds);
      const winner = data.winner;

      if (typeof winner === "undefined") {
        return undefined;
      }

      const profitLoss = (() => {
        if (winner) {
          return betAmount * (odds - 1);
        }

        if (!winner) {
          return -1 * betAmount;
        }

        return "";
      })();

      return (
        <Text
          weight="bold"
          className={classNames({
            "has-text-success": winner,
            "has-text-danger": !winner,
          })}
        >
          {context.currency
            ? formatCurrency(
                profitLoss,
                context.currency.code,
                context.currency.locale,
                true,
              )
            : formatRoi(profitLoss)}
        </Text>
      );
    },
  },
};

export const TipCard: FC<TipCardProps> = ({ fixture, children }) => {
  const [matchDetailsOpen, setMatchDetailsOpen] = useState(false);
  const teamsHeaderProps = useTeamsHeaderProps(fixture);

  return (
    <Box
      onHeaderClick={() => setMatchDetailsOpen(true)}
      header={<TeamsHeader {...teamsHeaderProps} />}
    >
      <MatchDetailsDrawer
        fixture={fixture}
        isOpen={matchDetailsOpen}
        onClose={() => setMatchDetailsOpen(false)}
      />
      {children}
    </Box>
  );
};

export const TipCardSkeleton = () => {
  const device = useDevice();

  if (device === "mobile") {
    return (
      <Box
        header={
          <TeamsHeader
            isLoading
            title="Bahia vs Botafogo"
            subtitle={
              <>
                <TeamsInfoItem isLoading icon="Trophy" label="08 August" />
                <TeamsInfoItem isLoading icon="Trophy" label="00:00" />
                <TeamsInfoItem isLoading icon="Trophy" label="Copa do Brasil" />
              </>
            }
            score="2-2"
          />
        }
      >
        <Table isStriped={false}>
          <TableRow>
            <TableHeading>Tip</TableHeading>
            <TableHeading>Prob.</TableHeading>
            <TableHeading>Odds</TableHeading>
            <TableHeading>Stake</TableHeading>
            <TableHeading></TableHeading>
          </TableRow>
          <TableRow>
            <TableData>
              <Text isLoading>Over 0.5 Goals</Text>
            </TableData>
            <TableData>
              <Text isLoading>
                <Tag size="medium" isLoading>
                  80%
                </Tag>
              </Text>
            </TableData>
            <TableData>
              <Text isLoading>x2.02</Text>
            </TableData>
            <TableData>
              <Text isLoading>4.22%</Text>
            </TableData>
            <TableData>
              <Text isLoading>5.19%</Text>
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>
              <Text isLoading>Over 0.5 Goals</Text>
            </TableData>
            <TableData>
              <Text isLoading>
                <Tag size="medium" isLoading>
                  80%
                </Tag>
              </Text>
            </TableData>
            <TableData>
              <Text isLoading>x2.02</Text>
            </TableData>
            <TableData>
              <Text isLoading>4.22%</Text>
            </TableData>
            <TableData>
              <Text isLoading>5.19%</Text>
            </TableData>
          </TableRow>
          <TableRow>
            <TableData>
              <Text isLoading>Over 0.5 Goals</Text>
            </TableData>
            <TableData>
              <Text isLoading>
                <Tag size="medium" isLoading>
                  80%
                </Tag>
              </Text>
            </TableData>
            <TableData>
              <Text isLoading>x2.02</Text>
            </TableData>
            <TableData>
              <Text isLoading>4.22%</Text>
            </TableData>
            <TableData>
              <Text isLoading>5.19%</Text>
            </TableData>
          </TableRow>
        </Table>
      </Box>
    );
  }

  return (
    <Box
      header={
        <TeamsHeader
          isLoading
          title="Bahia vs Botafogo"
          subtitle={
            <>
              <TeamsInfoItem isLoading icon="Trophy" label="08 August" />
              <TeamsInfoItem isLoading icon="Trophy" label="00:00" />
              <TeamsInfoItem isLoading icon="Trophy" label="Copa do Brasil" />
            </>
          }
          score="2-2"
        />
      }
    >
      <Table isStriped={false}>
        <TableRow>
          <TableHeading>Tip</TableHeading>
          <TableHeading>Rating</TableHeading>
          <TableHeading>Probability</TableHeading>
          <TableHeading>Odds</TableHeading>
          <TableHeading>Stake</TableHeading>
          <TableHeading></TableHeading>
        </TableRow>
        <TableRow>
          <TableData>
            <Text isLoading>Both Teams to Score</Text>
          </TableData>
          <TableData>
            <Tag size="medium" isLoading>
              Risky
            </Tag>
          </TableData>
          <TableData>
            <Text isLoading>80%</Text>
          </TableData>
          <TableData>
            <Text isLoading>x2.02</Text>
          </TableData>
          <TableData>
            <Text isLoading>4.22%</Text>
          </TableData>
          <TableData>
            <Text isLoading>5.19%</Text>
          </TableData>
        </TableRow>
        <TableRow>
          <TableData>
            <Text isLoading>Both Teams to Score</Text>
          </TableData>
          <TableData>
            <Tag size="medium" isLoading>
              Risky
            </Tag>
          </TableData>
          <TableData>
            <Text isLoading>80%</Text>
          </TableData>
          <TableData>
            <Text isLoading>x2.02</Text>
          </TableData>
          <TableData>
            <Text isLoading>4.22%</Text>
          </TableData>
          <TableData>
            <Text isLoading>5.19%</Text>
          </TableData>
        </TableRow>
        <TableRow>
          <TableData>
            <Text isLoading>Both Teams to Score</Text>
          </TableData>
          <TableData>
            <Tag size="medium" isLoading>
              Risky
            </Tag>
          </TableData>
          <TableData>
            <Text isLoading>80%</Text>
          </TableData>
          <TableData>
            <Text isLoading>x2.02</Text>
          </TableData>
          <TableData>
            <Text isLoading>4.22%</Text>
          </TableData>
          <TableData>
            <Text isLoading>5.19%</Text>
          </TableData>
        </TableRow>
      </Table>
    </Box>
  );
};
