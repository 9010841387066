import React, {
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";

import "./Radio.scss";
import classNames from "classnames";

import { Icon } from "@ui/elements";
import { Colors } from "@ui/types";

type RadioProps = PropsWithChildren<{
  type?: Colors;
}> &
  InputHTMLAttributes<HTMLInputElement>;

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ checked, name, children, onChange, type, ...rest }, ref) => {
    return (
      <div
        className={classNames(["radio", { [`is-${type}`]: type }])}
        tabIndex={-1}
      >
        <input
          id={name}
          name={name}
          type="radio"
          onChange={onChange}
          ref={ref}
          {...rest}
        />
        <div className="radio-toggle">
          <Icon weight="fill" icon="Circle" size="small" />
        </div>
        <label htmlFor={name}>{children}</label>
      </div>
    );
  },
);

Radio.displayName = "Checkbox";
