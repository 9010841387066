import React, { FC, useState } from "react";

import { Menu, MenuItem, MenuList, MenuPanel, Modal } from "@ui/components";
import { Column, Columns } from "@ui/layout";

import { useAuth } from "../AuthProvider/AuthProvider";

import { ChangePassword } from "./Menus/ChangePassword/ChangePassword";
import { DeleteAccount } from "./Menus/DeleteAccount/DeleteAccount";
import { EmailPreferences } from "./Menus/EmailPreferences/EmailPreferences";
import { UserAttributes } from "./Menus/UserAttributes/UserAttributes";

type AccountSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountSettingsModal: FC<AccountSettingsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [activeMenu, setActiveMenu] = useState("personal-information");
  const authContext = useAuth();

  if (!authContext.userAttributes) return null;

  return (
    <Modal
      size="large"
      header="Account Settings"
      isActive={isOpen}
      onClose={onClose}
    >
      <Menu value={activeMenu} onChange={setActiveMenu}>
        <Columns>
          <Column size={4}>
            <MenuList>
              <MenuItem icon="User" value="personal-information">
                Personal Information
              </MenuItem>
              <MenuItem icon="EnvelopeSimple" value="email-preferences">
                E-mail Preferences
              </MenuItem>
              <MenuItem icon="LockSimple" value="change-password">
                Change Password
              </MenuItem>
              <MenuItem icon="Backspace" value="delete-account">
                Delete Account
              </MenuItem>
            </MenuList>
          </Column>
          <Column>
            <MenuPanel value="personal-information">
              <UserAttributes />
            </MenuPanel>
            <MenuPanel value="email-preferences">
              <EmailPreferences />
            </MenuPanel>
            <MenuPanel value="change-password">
              <ChangePassword />
            </MenuPanel>
            <MenuPanel value="delete-account">
              <DeleteAccount />
            </MenuPanel>
          </Column>
        </Columns>
      </Menu>
    </Modal>
  );
};
