import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeleteStandingsResponse = {
  error: string | null;
  success: boolean;
};

type DeleteStandingsArgs = {
  id: string | number;
};

export const deleteStandings: MutationFunction<
  DeleteStandingsResponse,
  DeleteStandingsArgs
> = (props) => {
  return client
    .delete(`/standings`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeleteStandingsMutationKey = "delete-standings";

export const useDeleteStandings = (
  params?: DeleteStandingsArgs,
  options?: Omit<
    UseMutationOptions<
      DeleteStandingsResponse,
      DefaultErrorResponse,
      DeleteStandingsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteStandingsResponse,
  DefaultErrorResponse,
  DeleteStandingsArgs
> => {
  return useMutation(
    [DeleteStandingsMutationKey, params?.id],
    deleteStandings,
    options,
  );
};
