import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import html2canvas from "html2canvas";
import { createPortal } from "react-dom";

type ScreenshotIds =
  | "totalStatistics"
  | "h2hStatistics"
  | "homeAwayStatistics"
  | "ranking"
  | "preview"
  | "prediction"
  | "customTips"
  | "fixtureCard"
  | string;

type ScreenshotBoundaryProps = {
  id: ScreenshotIds;
  renderOriginal?: boolean;
  className?: string;
};

type GeneratorFnProps = {
  background?: string;
};

export type GeneratorFn = (props?: GeneratorFnProps) => Promise<string>;

export type ScreenshotGenerators = Record<ScreenshotIds, GeneratorFn>;

export type ScreenshotsRecord = Record<ScreenshotIds, string>;

type ScreenshotContextValues = {
  generators: ScreenshotGenerators;
  screenshots: ScreenshotsRecord;
  setGenerator: (id: ScreenshotIds, generator: GeneratorFn) => void;
  setScreenshot: (id: ScreenshotIds, url: string) => void;
};

export const ScreenshotContext = createContext<ScreenshotContextValues>({
  generators: {},
  screenshots: {},
  setGenerator: () => "",
  setScreenshot: () => {},
});

export const useScreenshotProviderValues = (): ScreenshotContextValues => {
  const [generators, setGenerators] = useState<ScreenshotGenerators>({});
  const [screenshots, setScreenshots] = useState<ScreenshotsRecord>({});
  const setGenerator = (id: ScreenshotIds, generatorFn: GeneratorFn) => {
    setGenerators((values) => ({ ...values, [id]: generatorFn }));
  };

  const setScreenshot = (id: ScreenshotIds, url: string) => {
    setScreenshots((values) => ({ ...values, [id]: url }));
  };

  return {
    generators,
    screenshots,
    setGenerator,
    setScreenshot,
  };
};

export const ScreenshotBoundary: FC<
  PropsWithChildren<ScreenshotBoundaryProps>
> = ({ children, id, renderOriginal = true, className }) => {
  const printContainerRef = useRef<HTMLDivElement>(null);
  const context = useContext(ScreenshotContext);
  const screenshotRoot = document.getElementById("screenshot-root");

  useEffect(() => {
    context.setGenerator(id, handleGenerate);
    context.generators;
  }, []);

  const handleGenerate: GeneratorFn = async (args) => {
    if (!printContainerRef.current) {
      return "";
    }

    if (args?.background) {
      const image = printContainerRef.current?.querySelector(
        ".background-image img",
      ) as HTMLImageElement;

      if (image) {
        image.src = args.background;
      }
    }

    // const svgElements = document.body.querySelectorAll("svg");
    // svgElements.forEach(function (item) {
    //   item.setAttribute("width", String(item.getBoundingClientRect().width));
    //   item.setAttribute("height", String(item.getBoundingClientRect().height));
    //   item.style.width = "";
    //   item.style.height = "";
    // });

    const canvas = await html2canvas(printContainerRef.current as HTMLElement, {
      useCORS: true,
      scale: 2,
      allowTaint: true,
      backgroundColor: "transparent",
    });

    const image = canvas.toDataURL("image/png", 1.0);
    context.setScreenshot(id, image);

    return image;
  };

  if (!screenshotRoot) {
    console.error("Screenshot root element not found");
    return null;
  }

  const clonedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }

    return child;
  });

  const portal = createPortal(
    <div
      ref={printContainerRef}
      style={{ minHeight: printContainerRef.current?.clientWidth }}
      className={classNames([
        "print-container",
        `${id}-print-container`,
        className,
      ])}
    >
      <div className="background-image">
        <img />
      </div>
      <div className="print-container-content">{clonedChildren}</div>
    </div>,
    screenshotRoot,
  );

  return (
    <>
      {renderOriginal && children}
      {portal}
    </>
  );
};
