import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type ExpertOpinionsData = {
  id: string;
  content: string;
  prompt?: string;
};

export type GetExpertOpinionsResponse = {
  error?: string | null;
  data?: ExpertOpinionsData;
};

export const getExpertOpinions: QueryFunction<GetExpertOpinionsResponse> = ({
  queryKey,
}) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/expert-opinions", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetExpertOpinionsQueryKey = "get-expert-opinions";

export const useGetExpertOpinions = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetExpertOpinionsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetExpertOpinionsResponse, DefaultErrorResponse> => {
  return useQuery([GetExpertOpinionsQueryKey, id], getExpertOpinions, options);
};
