import React, { useState } from "react";

import dayjs from "dayjs";

import { capitalize, formatHitRate, formatRoi } from "@utils";

import {
  TipCategory,
  TipRating,
  TopPerformersArgs,
  useGetTopPerformers,
} from "@api/math-tips";

import { List, InfoBox, TitleBlock } from "@ui/components";
import { Box, IconCardProps } from "@ui/elements";
import { ButtonSelect } from "@ui/forms";
import { Column, Columns, Stack } from "@ui/layout";

import "./TopPerformers.scss";

export const RatingIconCardProps: Record<TipRating, IconCardProps> = {
  safe: {
    icon: "LockSimple",
    type: "success",
  },
  value: {
    icon: "Coins",
    type: "info",
  },
  risky: {
    icon: "Warning",
    type: "danger",
  },
};

export const CategoryIconCardProps: Record<TipCategory, IconCardProps> = {
  ["Double Chance"]: {
    icon: "Percent",
  },
  ["Both Teams to Score"]: {
    icon: "ArrowsLeftRight",
  },
  ["Total Goals Over/Under"]: {
    icon: "SoccerBall",
  },
  ["Match Winner"]: {
    icon: "Trophy",
  },
};

const Periods = {
  "1d": "1d",
  // yesterday: "yesterday",
  "7d": "7d",
  "30d": "30d",
  "6m": "6m",
  "1y": "1y",
} as const;
type Periods = (typeof Periods)[keyof typeof Periods];

const TODAY = dayjs().format("YYYY-MM-DD");

const TIME_PERIOD_MAP: Record<Periods, TopPerformersArgs> = {
  "1d": {
    from: dayjs().format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  },
  // yesterday: {
  //   from: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  //   to: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  // },
  "7d": {
    from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "30d": {
    from: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "6m": {
    from: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
    to: TODAY,
  },
  "1y": {
    from: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    to: TODAY,
  },
};

export const TopPerformers = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<Periods>("30d");

  const { data: topPerformersData, isLoading: topPerformersLoading } =
    useGetTopPerformers(TIME_PERIOD_MAP[selectedPeriod]);

  return (
    <Stack direction="column">
      <TitleBlock title="Top Performers" subtitle="By ROI / Hit Rate">
        <ButtonSelect
          isGrouped={false}
          options={Object.values(Periods).map((period) => ({
            label: period,
            value: period,
          }))}
          value={selectedPeriod}
          onChange={(period) => setSelectedPeriod(period as Periods)}
        />
      </TitleBlock>
      {!topPerformersLoading &&
      !topPerformersData?.category_performance.length ? (
        <InfoBox
          icon="SmileyXEyes"
          hasSpacing
          secondaryButtonLabel="Reset Time Period"
          secondaryButtonAction={() => setSelectedPeriod("30d")}
        >
          Not enough tips to aggregate top performers.
          <br />
          Try again later, or modify time period.
        </InfoBox>
      ) : (
        <Columns isMobile className="top-performers-list">
          <Column>
            <Box isFullwidth>
              <Stack direction="column" gap="lg" isFullwidth>
                <Stack gap="md" direction="column">
                  {topPerformersData?.league_performance
                    .slice(0, 3)
                    .map(({ league, roi, hit_rate, total_tips }, index) => {
                      return (
                        <List
                          key={`top-performer-${league.id}`}
                          title={league.name}
                          value={formatRoi(roi)}
                          subtitle={`From ${total_tips} tips`}
                          subvalue={formatHitRate(hit_rate)}
                          image={league.logo}
                          badge={index + 1}
                        />
                      );
                    })}

                  {topPerformersLoading &&
                    Array(3)
                      .fill("")
                      .map((_, i) => {
                        return (
                          <List
                            key={`league-performance-skeleton-${i}`}
                            title="Goals Over/Under"
                            value="+24.5%"
                            isLoading={true}
                            badge={i + 1}
                            subvalue="50%"
                            iconProps={{ icon: "Trophy" }}
                          />
                        );
                      })}
                </Stack>
              </Stack>
            </Box>
          </Column>
          <Column>
            <Box isFullwidth>
              <Stack direction="column" gap="lg" isFullwidth>
                <Stack gap="md" direction="column">
                  {topPerformersData?.rating_performance
                    .slice(0, 3)
                    .map(({ rating, roi, hit_rate, total_tips }, index) => {
                      return (
                        <List
                          key={`top-performer-rating-${rating}`}
                          title={capitalize(rating)}
                          value={formatRoi(roi)}
                          subtitle={`From ${total_tips} tips`}
                          subvalue={formatHitRate(hit_rate)}
                          iconProps={RatingIconCardProps[rating]}
                          badge={index + 1}
                        />
                      );
                    })}

                  {topPerformersLoading &&
                    Array(3)
                      .fill("")
                      .map((_, i) => {
                        return (
                          <List
                            key={`rating-performance-skeleton-${i}`}
                            title="Goals Over/Under"
                            value="+24.5%"
                            isLoading={true}
                            badge={i + 1}
                            subvalue="50%"
                            iconProps={{ icon: "Trophy" }}
                          />
                        );
                      })}
                </Stack>
              </Stack>
            </Box>
          </Column>
          <Column>
            <Box isFullwidth>
              <Stack direction="column" gap="lg" isFullwidth>
                <Stack gap="md" direction="column">
                  {topPerformersData?.category_performance
                    .slice(0, 3)
                    .map(({ category, roi, hit_rate, total_tips }, index) => {
                      return (
                        <List
                          key={`top-performer-category-${category}`}
                          title={capitalize(category)}
                          value={formatRoi(roi)}
                          subvalue={formatHitRate(hit_rate)}
                          subtitle={`From ${total_tips} tips`}
                          iconProps={CategoryIconCardProps[category]}
                          badge={index + 1}
                        />
                      );
                    })}
                  {topPerformersLoading &&
                    Array(3)
                      .fill("")
                      .map((_, i) => {
                        return (
                          <List
                            key={`category-performance-skeleton-${i}`}
                            title="Goals Over/Under"
                            value="+24.5%"
                            isLoading={true}
                            badge={i + 1}
                            subvalue="50%"
                            iconProps={{ icon: "Trophy" }}
                          />
                        );
                      })}
                </Stack>
              </Stack>
            </Box>
          </Column>
        </Columns>
      )}
    </Stack>
  );
};
