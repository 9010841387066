import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";

import { GET_USER_TIP_QUERY_KEY } from "@api/user/getTip";
import { GET_USER_TIPS_QUERY_KEY } from "@api/user/getTips";
import { GET_USER_TIPS_SUMMARY_QUERY_KEY } from "@api/user/getTipsSummary";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateTipArgs = {
  id: string;
  bet_amount: number;
  odds: number;
  kelly_fraction: number;
  currency: string;
};

export type CreateTipResponse = {
  error?: string;
  message: string;
};

const createTip: MutateFunction<
  CreateTipResponse,
  DefaultErrorResponse,
  CreateTipArgs
> = (params) => {
  return client.post("tips", params).then((res) => res.data);
};

export const CreateTipMutationKey = "create-tip";

export const useCreateTip = (
  options?: Omit<
    UseMutationOptions<CreateTipResponse, DefaultErrorResponse, CreateTipArgs>,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateTipResponse,
  DefaultErrorResponse,
  CreateTipArgs
> => {
  const queryClient = useQueryClient();

  return useMutation([CreateTipMutationKey], createTip, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIP_QUERY_KEY, variables.id],
        refetchActive: true,
        refetchInactive: true,
      });

      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIPS_QUERY_KEY],
        refetchActive: true,
        refetchInactive: true,
      });

      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIPS_SUMMARY_QUERY_KEY],
        refetchActive: true,
        refetchInactive: true,
      });
    },
  });
};
