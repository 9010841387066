import React from "react";

import { useMatchDetails } from "@components";

import { OddsItems } from "../../../pages/gen2/components/OddsItems/OddsItems";

export const OddsTab = () => {
  const { fixture } = useMatchDetails();

  return <OddsItems id={fixture.fixture.id} />;
};
