import React, { useEffect, useState, Dispatch, SetStateAction } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "aws-amplify/auth";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import { withController } from "@utils";

import { useValidateRecaptcha } from "@api/cognito/validateRecaptcha";

import { Button, Text, Notification, Content, Title } from "@ui/elements";
import { Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { OpenEmailButtons, useAuth } from "@components";

type ForgotPasswordFields = {
  email: string;
};

const formSchema = Yup.object().shape({
  email: Yup.string().required("E-mail is required"),
});

const ControlledInput = withController<ForgotPasswordFields>()(Input);

export const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: validateRecaptcha, isLoading: recaptchaLoading } =
    useValidateRecaptcha();
  const { handleSubmit, control, watch, setValue } =
    useForm<ForgotPasswordFields>({
      resolver: yupResolver(formSchema),
      defaultValues: {
        email: searchParams.get("email") || "",
      },
    });
  const [requested, setRequested] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useAuth();

  useEffect(() => {
    if (authContext.isAuthenticated) {
      navigate("/");
    }
  }, [authContext.isAuthenticated, navigate]);

  const email = watch("email");

  const onSubmit = async (values: ForgotPasswordFields) => {
    setLoading(true);
    setError(null);

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      setLoading(false);
      return;
    }

    const token = await executeRecaptcha("password_reset");
    const recaptchaResponse = await validateRecaptcha({ token });

    if (!recaptchaResponse.success) {
      setError("Failed to validate reCAPTCHA. Please try again.");
      setLoading(false);
      return;
    }

    try {
      await resetPassword({ username: values.email });
      setRequested(true);
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : typeof error === "string"
            ? error
            : "An unexpected error occurred. Please try again.",
      );
    } finally {
      setLoading(false);
    }
  };

  const setEmailValue = (value: string) => setValue("email", value);

  if (requested) {
    return (
      <RequestedState
        email={email}
        setValue={setEmailValue}
        setRequested={setRequested}
        setError={setError}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Football Genie - Forgot Password</title>
      </Helmet>
      <Stack direction="column" gap="xl">
        <Stack direction="column">
          <Title size={5}>Reset Your Password</Title>
          <Text variant="secondary">
            Please enter the email address associated with your account, and
            we&apos;ll send you a link to reset your password.
          </Text>
          <ControlledInput
            type="email"
            name="email"
            placeholder="E-mail"
            control={control}
            autoComplete="username"
          />
          {error && <Notification color="danger">{error}</Notification>}
        </Stack>
        <Stack direction="column" gap="xs">
          <Button
            type="submit"
            isLoading={loading || recaptchaLoading}
            color="primary"
            disabled={loading || recaptchaLoading}
          >
            Reset Password
          </Button>
          <Link to="/login">
            <Button as="link" isFullwidth variant="text">
              Cancel
            </Button>
          </Link>
        </Stack>
      </Stack>
    </form>
  );
};

interface RequestedStateProps {
  email: string;
  setValue: (value: string) => void;
  setRequested: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
}

const RequestedState = ({
  email,
  setValue,
  setRequested,
  setError,
}: RequestedStateProps) => {
  const [canResend, setCanResend] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(60);

  useEffect(() => {
    if (secondsLeft > 0) {
      const timer = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [secondsLeft]);

  const handleResend = () => {
    setValue("");
    setRequested(false);
    setError(null);
    setCanResend(false);
    setSecondsLeft(60);
  };

  return (
    <Stack direction="column" gap="xl">
      <Text variant="secondary">
        We&apos;ve sent an email to <strong>{email}</strong> with instructions
        to reset your password.
      </Text>
      <OpenEmailButtons />
      <Content size="small">
        <blockquote>
          <p>Didn&apos;t receive it?</p>
          <ul>
            <li>Check your spam or junk folder.</li>
            <li>Try resending the email after a few minutes.</li>
            <li>
              If you still haven&apos;t received it,{" "}
              <a
                target="_blank"
                href="mailto:info@football-genie.com"
                rel="noreferrer"
              >
                contact support
              </a>
              .
            </li>
          </ul>
        </blockquote>
      </Content>

      <Stack direction="column" gap="xs" isFullwidth>
        <Link to="/login">
          <Button as="link" color="primary" isFullwidth>
            Return to Login
          </Button>
        </Link>
        <Button
          variant="text"
          isFullwidth
          onClick={handleResend}
          disabled={!canResend}
        >
          Re-send Email {canResend ? "" : `(Wait ${secondsLeft}s)`}
        </Button>
      </Stack>
    </Stack>
  );
};
