import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { FixtureItem } from "@api/football/types/fixture";
import { DefaultErrorResponse } from "@api/index";
import { League, TipItem, TipsQueryArgs } from "@api/math-tips";
import {
  getTipsFilterQueryArgs,
  parseTipsFilterParams,
} from "@api/math-tips/utils";

import { client } from "../client";

export type UpcomingTipsResponse = {
  available_leagues: League[];
  data: Array<{
    tips: TipItem[];
    fixture: FixtureItem;
  }>;
};

export const fetchUpcomingTips: QueryFunction<UpcomingTipsResponse> = ({
  queryKey,
}) => {
  const { params } = parseTipsFilterParams(queryKey);

  return client
    .get("/upcoming-tips", {
      params,
    })
    .then((res) => res.data);
};

export const GET_UPCOMING_TIPS_QUERY_KEY = "get-upcoming-tips";

export const useGetUpcomingTips = (
  args?: TipsQueryArgs,
  options?: Omit<
    UseQueryOptions<UpcomingTipsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<UpcomingTipsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_UPCOMING_TIPS_QUERY_KEY, ...getTipsFilterQueryArgs(args)],
    fetchUpcomingTips,
    options,
  );
};
