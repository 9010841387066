import React, { FC, PropsWithChildren } from "react";

import "./Container.scss";
import classNames from "classnames";

type ContainerProps = PropsWithChildren<{
  isFluid?: boolean;
}>;

export const Container: FC<ContainerProps> = ({ children, isFluid }) => {
  return (
    <div className={classNames(["container", { "is-fluid": isFluid }])}>
      {children}
    </div>
  );
};
