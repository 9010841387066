import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { FetchLeaguesResponse } from "../../football/fetchLeagues";
import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type SettingsResponse = {
  news_enabled: boolean;
  leagues: FetchLeaguesResponse[];
};

const getSettings: QueryFunction<SettingsResponse> = ({ queryKey }) => {
  const [_key] = queryKey;

  return client.get("get-settings").then((res) => res.data);
};

export const GET_SETTINGS_QUERY_KEY = "get-settings";

export const useGetSettings = (
  options?: Omit<
    UseQueryOptions<SettingsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<SettingsResponse, DefaultErrorResponse> => {
  return useQuery([GET_SETTINGS_QUERY_KEY], getSettings, options);
};
