import React, { FC, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useDevice } from "@utils";

import { FixtureItem } from "@api/football/types/fixture";
import { fetchStatistics } from "@api/statistics/fetchStatistics";

import { InfoBox, TabItem, TabList, Tabs } from "@ui/components";
import { Icon, IconCard, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import { FixturePreviewTooltip } from "./FixturePreviewTooltip";

type GoalScoringTrendChartProps = {
  fixture: FixtureItem;
};

export const GoalScoringTrendChart: FC<GoalScoringTrendChartProps> = ({
  fixture,
}) => {
  const device = useDevice();
  const { data: statisticsData, isLoading: statisticsLoading } = useQuery(
    ["statistics", fixture.fixture.id],
    fetchStatistics,
  );

  const [selectedTeamKey, setSelectedTeamKey] = useState<"home" | "away">(
    "home",
  );

  const fixturesSet = {
    home: statisticsData?.home_fixtures,
    away: statisticsData?.away_fixtures,
  };

  const goalsData = useMemo(() => {
    return fixturesSet[selectedTeamKey]
      ?.filter((e) => e.fixture.id !== fixture.fixture.id)
      ?.sort(
        (a, b) => dayjs(a.fixture.date).unix() - dayjs(b.fixture.date).unix(),
      )
      .map((e) => {
        const teamKey =
          e.teams.home.id === fixture.teams[selectedTeamKey].id
            ? ("home" as const)
            : ("away" as const);
        const opponentKey =
          teamKey === "home" ? ("away" as const) : ("home" as const);
        const homeGoals = e.score.fulltime[teamKey];

        return {
          fixture: `${e.teams.home.name} vs ${e.teams.away.name}`,
          scored: homeGoals,
          conceded: e.score.fulltime[opponentKey],
          total: (homeGoals || 0) + (e.score.fulltime[opponentKey] || 0),
          data: e,
        };
      });
  }, [fixturesSet]);

  const averages = useMemo(() => {
    if (goalsData) {
      const fixNum = (number: number | undefined) => Number(number?.toFixed(1));

      const scored = goalsData?.reduce((acc, curr) => {
        acc += curr.scored || 0;

        return acc;
      }, 0);

      const conceded = goalsData?.reduce((acc, curr) => {
        acc += curr.conceded || 0;

        return acc;
      }, 0);

      const scoredAverage = fixNum(scored / goalsData.length);
      const concededAverage = fixNum(conceded / goalsData.length);

      return {
        scored: fixNum(scoredAverage),
        conceded: fixNum(concededAverage),
        total: fixNum(scoredAverage + concededAverage),
      };
    }
  }, [goalsData]);

  if (
    !statisticsLoading &&
    (!averages?.scored || !averages?.conceded || !averages?.total)
  ) {
    return (
      <Stack isFullwidth direction="column" gap="xl">
        <Stack direction="column" gap="none">
          <Title size={5} hasIcon>
            <Icon icon="SoccerBall" />
            <span>Goal Scoring Trends</span>
          </Title>
          <Text variant="secondary">From last 10 fixtures</Text>
        </Stack>
        <InfoBox hasSpacing icon="SmileyXEyes">
          Failed to fetch Goal Scoring Data ... <br />
          Please try again later.
        </InfoBox>
      </Stack>
    );
  }

  return (
    <Stack isFullwidth direction="column" gap="md">
      <Stack direction="column" gap="none">
        <Title size={5} hasIcon>
          <Icon icon="SoccerBall" />
          <span>Goal Scoring Trends</span>
        </Title>
        <Text variant="secondary">From last 10 fixtures</Text>
      </Stack>
      {!statisticsLoading ? (
        <Stack isFullwidth direction="column" gap="xl">
          <Tabs
            active={selectedTeamKey}
            onChange={(tab) => setSelectedTeamKey(tab as "home" | "away")}
          >
            <TabList type="toggle" isFullwidth>
              <TabItem tab="home">{fixture.teams.home.name}</TabItem>
              <TabItem tab="away">{fixture.teams.away.name}</TabItem>
            </TabList>
          </Tabs>

          <Stack justify="between">
            <Stack align="center">
              <IconCard icon="TrendUp" type="success" />
              <Stack direction="column" gap="none">
                <Text variant="secondary" size="small">
                  Avg. Scored
                </Text>
                <Title size={4}>{averages?.scored}</Title>
              </Stack>
            </Stack>
            <Stack align="center">
              <IconCard icon="TrendDown" type="danger" />
              <Stack direction="column" gap="none">
                <Text variant="secondary" size="small">
                  Avg. Conceded
                </Text>
                <Title size={4}>{averages?.conceded}</Title>
              </Stack>
            </Stack>
            <Stack align="center">
              <IconCard icon="ArrowsLeftRight" type="info" />
              <Stack direction="column" gap="none">
                <Text variant="secondary" size="small">
                  Avg. Total
                </Text>
                <Title size={4}>{averages?.total}</Title>
              </Stack>
            </Stack>
          </Stack>

          <ResponsiveContainer
            width="100%"
            height={device === "mobile" ? 200 : 250}
          >
            <LineChart data={goalsData} margin={{ left: -40 }}>
              <CartesianGrid
                strokeDasharray="5 5"
                horizontal={true}
                vertical={false}
                stroke="#f3f4f6"
              />
              <XAxis
                dataKey="fixture"
                tick={false}
                strokeWidth={0.5}
                stroke="var(--bulma-text-weak)"
              />
              <YAxis
                allowDecimals={false}
                strokeWidth={0.5}
                stroke="var(--bulma-text-weak)"
              />
              <Tooltip content={<FixturePreviewTooltip />} />
              <Line
                type="monotone"
                dataKey="scored"
                strokeWidth={0.5}
                stroke="var(--bulma-success)"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="conceded"
                strokeWidth={0.5}
                dot={false}
                stroke="var(--bulma-danger)"
              />
            </LineChart>
          </ResponsiveContainer>
        </Stack>
      ) : (
        <div className="skeleton-block" style={{ height: 363 }} />
      )}
    </Stack>
  );
};
