import React from "react";

import { Helmet } from "react-helmet";

import { SubpageTemplate } from "@components";

import { rawHtml } from "./rawHtml";

export const TermsAndConditions = () => {
  return (
    <SubpageTemplate>
      <Helmet>
        <title>Football Genie AI - Terms and Conditions</title>
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: rawHtml }} />
    </SubpageTemplate>
  );
};
