import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type ConversationItem = {
  role: "user" | "assistant";
  content: string;
};

type CreateChatResponse = {
  chat_id: string;
  error?: string;
};

type CreateChatArgs = {
  conversation: ConversationItem[];
  n?: number;
};

export const createChat: MutationFunction<
  CreateChatResponse,
  CreateChatArgs
> = (props) => {
  return client
    .post(`/init-chat`, { conversation: props.conversation, n: props.n })
    .then((res) => res.data);
};

export const CreateChatMutationKey = "create-chat";

export const useCreateChat = (
  options?: Omit<
    UseMutationOptions<
      CreateChatResponse,
      DefaultErrorResponse,
      CreateChatArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateChatResponse,
  DefaultErrorResponse,
  CreateChatArgs
> => {
  return useMutation([CreateChatMutationKey], createChat, options);
};
