import React, { FC, useEffect, useMemo, useState } from "react";

import { UseFormReturn } from "react-hook-form";

import {
  calculateKelly,
  formatCurrency,
  roundNumber,
  withController,
} from "@utils";

import { TipItem } from "@api/math-tips";

import {
  Button,
  Icon,
  Notification,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Text,
  Title,
} from "@ui/elements";
import { Control, CurrencyInput, Field, Input } from "@ui/forms";
import { Stack } from "@ui/layout";

import { usePageSettings } from "../PageTemplate/PageTemplate";

export type TipSaveFormFields = {
  odds: number;
  stake: number;
};

const ControlledInput = withController<TipSaveFormFields>()(Input);
const ControlledCurrencyInput =
  withController<TipSaveFormFields>()(CurrencyInput);

type TipSaveFormProps = {
  tip: TipItem;
  form: UseFormReturn<TipSaveFormFields>;
  stakeInitiallyLocked?: boolean;
};

export const oddsToNumber = (odds?: string | number) => {
  if (typeof odds === "string") {
    return Number(odds.replace(",", "."));
  }

  return Number(odds);
};

export const TipSaveForm: FC<TipSaveFormProps> = ({
  form,
  tip,
  stakeInitiallyLocked,
}) => {
  const [stakeLocked, setStakeLocked] = useState(!!stakeInitiallyLocked);
  const { currency, bankroll } = usePageSettings();
  const stake = form.watch("stake");
  const odds = form.watch("odds");
  const { kellyFraction } = usePageSettings();

  const kellyBetAmount = useMemo(() => {
    return Math.max(
      Number(
        calculateKelly(
          oddsToNumber(odds),
          Number(tip.probability),
          kellyFraction,
        ).toFixed(2),
      ),
      0,
    );
  }, [odds, tip.probability, kellyFraction]);

  useEffect(() => {
    if (!stakeLocked) {
      form.setValue(
        "stake",
        roundNumber((Number(bankroll) * kellyBetAmount) / 100),
      );
    }
  }, [stakeLocked, form.setValue, kellyBetAmount, bankroll]);

  const potentialReturn = useMemo(() => {
    return formatCurrency(
      stake * oddsToNumber(odds),
      currency?.code,
      currency?.locale,
    );
  }, [odds, currency, odds, stake]);

  return (
    <Stack direction="column">
      {kellyBetAmount <= 0 && (
        <Notification color="warning">
          <Stack direction="column">
            <Text weight="bold">Odds are too low</Text>
            <Text>
              Based on the Kelly Criterion, the current odds are lower than
              expected compared to the AI&apos;s probability.
            </Text>
          </Stack>
        </Notification>
      )}
      <Table isStriped={false}>
        <TableRow>
          <TableHeading>Odds</TableHeading>
          <TableHeading>Stake</TableHeading>
          <TableHeading>
            <Text align="right">Potential Return</Text>
          </TableHeading>
        </TableRow>
        <TableRow>
          <TableData vAlign="top">
            <ControlledInput
              name="odds"
              control={form.control}
              type="text"
              inputMode="decimal"
              className="odds-input"
              rules={{
                min: {
                  value: 1,
                  message: "Odds must be greater than 1.",
                },
                required: "Odds cannot be empty",
              }}
              min={1}
              enterKeyHint="enter"
              step="any"
            />
          </TableData>
          <TableData vAlign="top">
            <Field hasAddons>
              <Control>
                <ControlledCurrencyInput
                  name="stake"
                  className="stake-input"
                  control={form.control}
                  currency={currency}
                  disabled={stakeLocked}
                  rules={{
                    min: {
                      value: 1,
                      message: "Stake must be greater than 0.",
                    },
                    required: "This field is required",
                  }}
                  min={1}
                />
              </Control>
              <Control>
                <Button
                  type="button"
                  onClick={() => setStakeLocked(!stakeLocked)}
                  variant={stakeLocked ? "light" : undefined}
                >
                  <Icon
                    icon={!stakeLocked ? "LockSimpleOpen" : "LockSimple"}
                    size="small"
                  />
                </Button>
              </Control>
            </Field>
          </TableData>
          <TableData isFullwidth>
            <Title align="right" size={4}>
              {potentialReturn}
            </Title>
          </TableData>
        </TableRow>
      </Table>
    </Stack>
  );
};
