import React, { ButtonHTMLAttributes, FC } from "react";

import classNames from "classnames";

import "./Delete.scss";
type DeleteProps = {
  size?: "small" | "medium" | "large";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Delete: FC<DeleteProps> = ({ size, className, ...props }) => {
  return (
    <button
      className={classNames("delete", className, { [`is-${size}`]: !!size })}
      type="button"
      {...props}
    />
  );
};
