import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";
import "./Subtitle.scss";

type SubtitleProps = PropsWithChildren<{
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  noMargin?: boolean;
  noWrap?: boolean;
  isLoading?: boolean;
}>;

export const Subtitle: FC<SubtitleProps> = ({
  children,
  size,
  noMargin,
  noWrap,
  isLoading,
}) => {
  return (
    <p
      className={classNames([
        "subtitle",
        {
          [`is-${size}`]: !!size,
          "mb-0": noMargin,
          "no-wrap": noWrap,
          "is-skeleton": isLoading,
        },
      ])}
    >
      {children}
    </p>
  );
};
