import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type FetchLeaguesResponse = {
  league: {
    id: number;
    name: string;
    type: string;
    logo: string;
  };
  country: {
    name: string;
    code: string;
    flag: string;
  };
};

export const fetchLeague: QueryFunction<FetchLeaguesResponse[]> = ({
  queryKey,
}) => {
  const [_key, q] = queryKey;

  return client
    .get("/search-league", { params: { q } })
    .then((res) => res.data);
};

export const useLeaguesSearch = (
  query?: string | number,
  options?: Omit<
    UseQueryOptions<FetchLeaguesResponse[], DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<FetchLeaguesResponse[], DefaultErrorResponse> => {
  const response = useQuery<FetchLeaguesResponse[], DefaultErrorResponse>(
    ["fetch-league", query],
    fetchLeague,
    options,
  );

  return response;
};
