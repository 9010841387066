import React, { AnchorHTMLAttributes, FC, PropsWithChildren } from "react";

import classNames from "classnames";
import { Link as ReactLink } from "react-router-dom";

import "./Link.scss";

type LinkProps = PropsWithChildren<{
  size?: "normal" | "small";
  weight?: "normal" | "bold" | "light";
  to?: string;
}> &
  AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link: FC<LinkProps> = ({
  children,
  to,
  size,
  weight,
  ...rest
}) => {
  const linkProps = {
    ...rest,
    className: classNames(
      "link",
      {
        "is-size-6": size === "normal",
        "is-size-7": size === "small",
        "has-text-weight-bold": weight === "bold",
        "has-text-weight-normal": weight === "normal",
        "has-text-weight-light": weight === "light",
      },
      rest.className,
    ),
  };

  if (to) {
    return (
      <ReactLink {...linkProps} to={to}>
        {children}
      </ReactLink>
    );
  }

  return <a {...linkProps}>{children}</a>;
};
