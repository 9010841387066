import { TipsQueryArgs } from "@api/math-tips/types";

export const getTipsFilterQueryArgs = (args?: TipsQueryArgs) => {
  return [
    args?.from,
    args?.to,
    args?.page,
    args?.include_ratings,
    args?.tip_status,
    args?.include_leagues || [],
    args?.min_odds,
    args?.max_odds,
    args?.min_edge,
    args?.max_edge,
    args?.min_bet_amount,
    args?.max_bet_amount,
  ];
};

export const parseTipsFilterParams = (
  queryKey: readonly unknown[] | string[],
) => {
  const [
    _key,
    from,
    to,
    page,
    include_ratings,
    tip_status,
    include_leagues,
    min_odds,
    max_odds,
    min_edge,
    max_edge,
    min_bet_amount,
    max_bet_amount,
  ] = queryKey;

  return {
    key: _key,
    params: {
      from,
      to,
      page,
      include_ratings,
      tip_status,
      include_leagues,
      min_odds,
      max_odds,
      min_edge,
      max_edge,
      min_bet_amount,
      max_bet_amount,
    },
  };
};
