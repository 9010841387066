import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import { IconWeight } from "@phosphor-icons/react";
import classNames from "classnames";

import * as AvailableIcons from "./icon-collection";
import { IconSizes } from "./types";

import "./Icon.scss";
export type Icons = keyof typeof AvailableIcons;

type IconProps = PropsWithChildren<{
  size?: IconSizes;
  icon: Icons;
  weight?: IconWeight;
}> &
  HTMLAttributes<HTMLSpanElement>;

export const Icon: FC<IconProps> = ({
  size = "normal",
  icon,
  weight = "light",
  className,
  ...rest
}) => {
  const IconComponent = AvailableIcons[icon];

  return (
    <span
      className={classNames("icon", className, {
        [`is-${size}`]: size !== "normal",
      })}
      {...rest}
    >
      <IconComponent weight={weight} />
    </span>
  );
};
