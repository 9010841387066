import dayjs from "dayjs";

export type TimePeriod =
  | "today"
  | "tomorrow"
  | "yesterday"
  | "last-7-days"
  | "last-30-days"
  | "custom";

export const TimePeriodLabels: Record<TimePeriod, string> = {
  today: "Today",
  tomorrow: "Tomorrow",
  yesterday: "Yesterday",
  "last-7-days": "Last 7 days",
  "last-30-days": "Last 30 days",
  custom: "Custom",
};

export const getRangeFromTimePeriod = (period: TimePeriod) => {
  const today = dayjs();
  const todayFormatted = today.format("YYYY-MM-DD");

  switch (period) {
    case "today":
      return [today.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];
    case "yesterday":
      return [
        today.subtract(1, "day").format("YYYY-MM-DD"),
        today.subtract(1, "day").format("YYYY-MM-DD"),
      ];
    case "tomorrow":
      return [
        today.add(1, "day").format("YYYY-MM-DD"),
        today.add(1, "day").format("YYYY-MM-DD"),
      ];
    case "last-7-days":
      return [today.subtract(1, "week").format("YYYY-MM-DD"), todayFormatted];
    case "last-30-days":
      return [today.subtract(30, "day").format("YYYY-MM-DD"), todayFormatted];

    default:
      return [today.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];
  }
};
