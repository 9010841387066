import React, { FC, useCallback, useMemo, useState } from "react";

import {
  faCheckSquare,
  faCopy,
  faExclamationTriangle,
  faEye,
  faRotate,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import { UseMutationResult, useQueryClient, UseQueryResult } from "react-query";
import { toast } from "react-toastify";
import remarkGfm from "remark-gfm";

import { DefaultErrorResponse } from "@api/index";

import { TableData, TableRow } from "@ui/elements";

import "./PredictionChainTableRow.scss";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../components/Tooltip/Tooltip";
import { copyToClipboard } from "../../../../utils/copyToClipboard";

type PredictionChainTableRowProps = {
  title: string;
  /* eslint-disable-next-line */
  create: UseMutationResult<any, DefaultErrorResponse, any>;
  /* eslint-disable-next-line */
  del: UseMutationResult<any, DefaultErrorResponse, any>;
  /* eslint-disable-next-line */
  get: UseQueryResult<any, DefaultErrorResponse>;
  id?: string | number;
  getQueryKey: string;
};

export const PredictionChainTableRow: FC<PredictionChainTableRowProps> = ({
  create,
  get,
  del,
  title,
  id,
  getQueryKey,
}) => {
  const queryClient = useQueryClient();
  const [previewOpen, setPreviewOpen] = useState(false);
  const { data, isLoading, isRefetching, refetch, error } = get;
  const {
    mutateAsync: createFn,
    isLoading: createLoading,
    error: createError,
  } = create;
  const {
    mutateAsync: deleteFn,
    isLoading: deleteLoading,
    error: deleteError,
  } = del;

  const loading = isLoading || isRefetching || createLoading || deleteLoading;
  const notFound = error?.response?.status === 404;

  const handleMutation = useCallback(
    async (fn: () => Promise<unknown>, action: string) => {
      try {
        await toast.promise(fn, {
          pending: `${action} ${title} ...`,
          success: `${title} ${action}d 👌`,
          error: {
            render({ data }) {
              const message = (data as DefaultErrorResponse)?.response?.data
                .error;
              return (
                <div>
                  <strong>
                    Failed to {action} {title}
                  </strong>
                  {message && (
                    <>
                      <br />
                      <p className="is-size-7">{message}</p>
                    </>
                  )}
                </div>
              );
            },
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        queryClient.invalidateQueries([getQueryKey, id]);
      }
    },
    [title, queryClient, id, getQueryKey],
  );

  const handleCreate = () => handleMutation(() => createFn({ id }), "Create");
  const handleDelete = () => handleMutation(() => deleteFn({ id }), "Delete");

  const errorMessage =
    error?.response?.data.error ||
    createError?.response?.data.error ||
    deleteError?.response?.data.error;
  const showDelete = useMemo(() => data && !notFound, [data, notFound]);
  const isAvailable = !loading && !!data && !errorMessage;
  const isError = !loading && errorMessage && !notFound;
  const isNotAvailable =
    (!loading && !data && !errorMessage) || (notFound && !loading);
  const hasPreview = !!data?.data?.prompt;

  return (
    <TableRow>
      <TableData>
        {loading && (
          <FontAwesomeIcon
            className="has-text-info"
            icon={faSpinner}
            pulse={true}
            size="lg"
          />
        )}
        {isAvailable && (
          <FontAwesomeIcon
            className="has-text-success"
            icon={faCheckSquare}
            size="lg"
          />
        )}
        {isNotAvailable && (
          <FontAwesomeIcon
            className="has-text-warning"
            icon={faExclamationTriangle}
            size="lg"
          />
        )}
      </TableData>
      <TableData isFullwidth>
        <p className="has-text-grey">{title}</p>
        {isAvailable && <p className="help is-success">Available</p>}
        {loading && <p className="help is-info">Loading...</p>}
        {isError && <p className="help is-danger">Error: {errorMessage}</p>}
        {isNotAvailable && <p className="help is-warning">Not Available</p>}
      </TableData>
      <TableData className="pr-0">
        <button
          className="button is-small is-white"
          onClick={() => refetch()}
          disabled={loading}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faRotate} />
          </span>
        </button>
      </TableData>
      <TableData>
        <div className="field has-addons">
          <div className="control">
            <button
              className="button is-small is-light"
              onClick={() => copyToClipboard(data?.data.prompt)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faCopy} />
              </span>
            </button>
          </div>
          {isAvailable && hasPreview && (
            <div className="control">
              <Tooltip placement="top">
                <TooltipTrigger
                  className={classNames("button is-light is-small")}
                  onClick={() => setPreviewOpen(true)}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faEye} />
                  </span>
                </TooltipTrigger>
                <TooltipContent className="tooltip">
                  <div className="content is-small has-text-white">
                    <p>Preview</p>
                  </div>
                </TooltipContent>
              </Tooltip>
              <div
                className={classNames(["modal", { "is-active": previewOpen }])}
              >
                <div
                  className="modal-background"
                  onClick={() => setPreviewOpen(false)}
                ></div>
                <div className="modal-content box">
                  <ReactMarkdown
                    className="content is-small prediction-preview-content"
                    remarkPlugins={[remarkGfm]}
                  >
                    {data?.data.prompt}
                  </ReactMarkdown>
                </div>
                <button
                  onClick={() => setPreviewOpen(false)}
                  className="modal-close is-large"
                  aria-label="close"
                ></button>
              </div>
            </div>
          )}
          {showDelete && (
            <div className="control">
              <button
                className={classNames("button is-danger is-small is-light", {
                  "is-loading": deleteLoading,
                })}
                onClick={handleDelete}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faTrash} />
                </span>
              </button>
            </div>
          )}
          {(!data || notFound) && (
            <div className="control">
              <button
                className={classNames("button is-secondary is-small is-light", {
                  "is-loading": createLoading,
                })}
                onClick={handleCreate}
              >
                <span>Create</span>
              </button>
            </div>
          )}
        </div>
      </TableData>
    </TableRow>
  );
};
