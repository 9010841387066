import React, { FC } from "react";

import { useQuery } from "react-query";

import { FixtureItem } from "@api/football/types/fixture";
import { fetchStatistics } from "@api/statistics/fetchStatistics";

import {
  Icon,
  ImageComponent,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Tag,
  Title,
} from "@ui/elements";
import "./Rankings.scss";
import { Stack } from "@ui/layout";

const LeagueRecentForm: FC<{ form: string; name: string }> = ({ form }) => {
  const getBadge = (str: string, index: number) => {
    let badgeClass = "form-badge has-background-grey-light";
    switch (str) {
      case "D":
        badgeClass = "form-badge has-background-grey-light";
        break;
      case "W":
        badgeClass = "form-badge has-background-success";
        break;
      case "L":
        badgeClass = "form-badge has-background-danger";
        break;
    }
    return (
      <div key={`form-${name}-${index}`} className={badgeClass}>
        {str}
      </div>
    );
  };

  return <div className="badges-wrapper">{form?.split("").map(getBadge)}</div>;
};

type LeagueRankingItem = {
  ranking: number;
  logo: string;
  name: string;
  form: string;
  points: number;
  goalsDiff: number;
  goalsFor: number;
  goalsAgainst: number;
};

const LeagueRankingsTable: FC<{ rankings: LeagueRankingItem[] }> = ({
  rankings,
}) => {
  return (
    <Table isFullwidth>
      <TableRow>
        <TableHeading />
        <TableHeading colSpan={2}>League standings</TableHeading>
        <TableHeading>Recent form</TableHeading>
        <TableHeading className="has-text-right">Goals diff</TableHeading>
        <TableHeading className="has-text-right">Points</TableHeading>
      </TableRow>
      {rankings.map(
        ({
          ranking,
          logo,
          name,
          form,
          points,
          goalsDiff,
          goalsFor,
          goalsAgainst,
        }) => (
          <TableRow
            key={`${name}-league-ranking-${points}-${goalsAgainst}-${goalsFor}`}
          >
            <TableData className="has-text-right">
              <Tag size="medium">#{ranking}</Tag>
            </TableData>
            <TableData>
              <ImageComponent src={logo} size={32} />
            </TableData>
            <TableData isFullwidth className="name-cell">
              {name}
            </TableData>
            <TableData>
              <LeagueRecentForm form={form} name={name} />
            </TableData>
            <TableData className="has-text-right">{goalsDiff}</TableData>
            <TableData className="has-text-weight-bold has-text-right">
              {points}
            </TableData>
          </TableRow>
        ),
      )}
    </Table>
  );
};

type RankingsProps = {
  fixture: FixtureItem;
};

export const Rankings: FC<RankingsProps> = ({ fixture }) => {
  const { data: statisticsData, isLoading: statisticsLoading } = useQuery(
    ["statistics", fixture.fixture.id],
    fetchStatistics,
  );

  if (statisticsLoading) {
    return (
      <Stack direction="column" gap="md" isFullwidth>
        <Title size={5} hasIcon>
          <Icon icon="Ranking" />
          <span>Standings</span>
        </Title>
        <div className="skeleton-block" style={{ height: 129 }} />
      </Stack>
    );
  }

  if (!statisticsData?.standings?.away) return null;

  const standings = statisticsData.standings;

  const leagueRankings = [
    {
      ranking: standings.home.rank,
      logo: fixture.teams.home.logo,
      name: fixture.teams.home.name,
      form: standings.home.form,
      points: standings.home.points,
      goalsDiff: standings.home.goalsDiff,
      goalsFor: standings.home.all.goals.goals_for,
      goalsAgainst: standings.home.all.goals.against,
    },
    {
      ranking: standings.away.rank,
      logo: fixture.teams.away.logo,
      name: fixture.teams.away.name,
      form: standings.away.form,
      points: standings.away.points,
      goalsDiff: standings.away.goalsDiff,
      goalsFor: standings.away.all.goals.goals_for,
      goalsAgainst: standings.away.all.goals.against,
    },
  ].sort((a, b) => a.ranking - b.ranking);

  return (
    <Stack direction="column" gap="md" isFullwidth>
      <Title size={5} hasIcon>
        <Icon icon="Ranking" />
        <span>Standings</span>
      </Title>
      {statisticsLoading ? (
        <div className="skeleton-block" style={{ height: 232 }} />
      ) : (
        <LeagueRankingsTable rankings={leagueRankings} />
      )}
    </Stack>
  );
};
