import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateMathPredictionArgs = {
  id: string | number;
};

export type CreateMathPredictionResponse = {
  success: boolean;
  message: string;
};

export const createMathPrediction: MutateFunction<
  CreateMathPredictionResponse,
  DefaultErrorResponse,
  CreateMathPredictionArgs
> = ({ id }) => {
  return client.post("create-math-prediction", { id }).then((res) => res.data);
};

export const CreateMathPredictionMutationKey = "create-math-prediction";

export const useCreateMathPrediction = (
  options?: Omit<
    UseMutationOptions<
      CreateMathPredictionResponse,
      DefaultErrorResponse,
      CreateMathPredictionArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateMathPredictionResponse,
  DefaultErrorResponse,
  CreateMathPredictionArgs
> => {
  return useMutation(
    [CreateMathPredictionMutationKey],
    createMathPrediction,
    options,
  );
};
