import React, { FC, useState } from "react";

import { useDeleteAccount } from "@api/cognito/deleteAccount";

import { Modal } from "@ui/components";
import { Button, Notification, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import { toaster, useAuth } from "@components";

type DeleteConfirmationModalProps = {
  onClose: () => void;
  open: boolean;
};

const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  onClose,
  open,
}) => {
  const {
    error: deleteAccountError,
    mutateAsync: deleteAccount,
    isLoading,
  } = useDeleteAccount();
  const authContext = useAuth();

  const handleUserDelete = async () => {
    await deleteAccount();

    await authContext.logout();

    toaster({
      title: "Account successfully deleted!",
      message: "Sad to see you go. I hope to see you again soon!",
    });
  };

  return (
    <Modal
      isActive={open}
      onClose={onClose}
      header="Are you sure?"
      style={{ zIndex: 100 }}
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="xl">
          <Title size={6}>
            Please confirm that you wish to permanently delete your account.
          </Title>
          <Stack direction="column">
            <Text>
              This action cannot be undone and will result in the removal of all
              your personal data, preferences, and history from our records.
            </Text>
            <Text>
              You will lose access to all features, including predictions,
              immediately upon deletion.
            </Text>
          </Stack>
        </Stack>
        {deleteAccountError?.response?.data.error && (
          <Notification color="danger">
            <Text weight="bold">
              {deleteAccountError?.response?.data.error}
            </Text>
            <Text>{deleteAccountError?.response?.data.message}</Text>
          </Notification>
        )}
        {!deleteAccountError?.response?.data.error && deleteAccountError && (
          <Notification color="danger">
            <Text weight="bold">{String(deleteAccountError)}</Text>
            <Text>{deleteAccountError?.message}</Text>
          </Notification>
        )}
        <Stack justify="end">
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="light"
            color="danger"
            onClick={handleUserDelete}
            isLoading={isLoading}
          >
            Yes, i&apos;m sure
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export const DeleteAccount = () => {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction="column" gap="xl">
      <DeleteConfirmationModal open={open} onClose={() => setOpen(false)} />
      <Text variant="secondary">
        Deleting your account is permanent and will erase all your data. Please
        proceed with caution.
      </Text>
      <Stack justify="end">
        <Button color="danger" variant="light" onClick={() => setOpen(true)}>
          Delete Account
        </Button>
      </Stack>
    </Stack>
  );
};
