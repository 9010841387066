import React, { FC, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import dayjs from "dayjs";

import {
  formatBetAmount,
  formatOdds,
  formatProbability,
  formatRoi,
  useIsAdmin,
} from "@utils";

import { FixtureItem } from "@api/football/types/fixture";
import {
  ResultOrientedTipItem,
  useGetResultOrientedTips,
} from "@api/result-oriented-tips-service/getTips";

import {
  InfoBox,
  TeamsHeader,
  TeamsInfoItem,
  useTeamsHeaderProps,
} from "@ui/components";
import {
  Box,
  Icon,
  Table,
  TableData,
  TableHeading,
  TableRow,
  Tag,
  Text,
} from "@ui/elements";
import { Column, Columns, Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import {
  MatchDetailsDrawer,
  TipFilterValues,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@components";

import "./ResultOrientedTips.scss";
import { ScreenshotButton } from "../../../../components/ScreenshotButton/ScreenshotButton";

const ColorMap: Record<"safe" | "value" | "risky" | "super safe", Colors> = {
  safe: "success",
  value: "info",
  risky: "danger",
  "super safe": "success",
};

type TipRowProps = {
  item: Omit<ResultOrientedTipItem, "probability"> & { probability: number };
};

const TipRow: FC<TipRowProps> = ({ item }) => {
  return (
    <TableRow>
      <TableData isFullwidth>
        {item.category && item.name ? (
          <Stack align="center">
            {item.best_choice && <Icon icon="SealCheck" />}
            <Stack align="center">
              <Stack direction="column" gap="none">
                <Text hasIcon>
                  <span>{item.name}</span>
                </Text>
                <Text size="small" variant="secondary">
                  {item.category}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Text>
            <span>{item.name}</span>
          </Text>
        )}
      </TableData>
      <TableData>
        {item.reasoning ? (
          <Tooltip placement="top-end">
            <TooltipTrigger>
              <Tag
                type={ColorMap[item.rating]}
                size="medium"
                variant="light"
                isClickable
              >
                <Stack gap="none">
                  <span>{item.rating}</span>
                  <Icon icon="Info" />
                </Stack>
              </Tag>
            </TooltipTrigger>
            <TooltipContent className="tooltip">
              <Stack direction="column">
                <Text>{item.reasoning}</Text>
              </Stack>
            </TooltipContent>
          </Tooltip>
        ) : (
          <Tag type={ColorMap[item.rating]} size="medium" variant="light">
            <Stack gap="none">
              <span>{item.rating}</span>
            </Stack>
          </Tag>
        )}
      </TableData>
      <TableData>{formatProbability(item.probability)}</TableData>
      <TableData>
        <Text>{formatOdds(item.odds)}</Text>
      </TableData>
      <TableData>
        <Text>{formatBetAmount(item.bet_amount)}</Text>
      </TableData>
      {"winner" in item && (
        <TableData>
          <Text
            weight="bold"
            className={classNames({
              "has-text-danger": item.winner === false,
              "has-text-success": item.winner === true,
            })}
          >
            {item.winner === false && formatRoi(-1 * Number(item.bet_amount))}
            {item.winner === true &&
              !!item.odds &&
              formatRoi(
                Number(item.bet_amount) * Number(item.odds) -
                  Number(item.bet_amount),
              )}
            {!item.odds && item.winner === false && "0%"}
            {!item.odds && item.winner === true && "0%"}
          </Text>
        </TableData>
      )}
    </TableRow>
  );
};

type TipsTableProps = {
  id?: string | number;
  filterValues: TipFilterValues;
  tips: ResultOrientedTipItem[];
};

const TipsTable: FC<TipsTableProps> = ({ filterValues, tips }) => {
  const sortedData = useMemo(() => {
    if (tips) {
      return tips
        ?.map((item) => ({
          ...item,
          probability: Number(item.probability),
        }))
        .sort((a, b) => b.probability - a.probability)
        .filter((tip) => {
          if (!filterValues?.include_ratings?.length) {
            return true;
          }

          return [...filterValues.include_ratings, "super safe"].includes(
            tip.rating,
          );
        });
    }

    return [];
  }, [tips, filterValues]);

  if (!sortedData?.length) {
    return null;
  }

  return (
    <Table>
      <TableRow>
        <TableHeading>Tip</TableHeading>
        <TableHeading>Rating</TableHeading>
        <TableHeading>Probability</TableHeading>
        <TableHeading>Odds</TableHeading>
        <TableHeading>Stake</TableHeading>
        {sortedData?.some((e) => "winner" in e) && (
          <TableHeading></TableHeading>
        )}
      </TableRow>
      {sortedData?.map((tip) => (
        <TipRow key={`result-oriented-gen-tip-${tip.id}`} item={tip} />
      ))}
    </Table>
  );
};

type TipBoxProps = {
  fixture: FixtureItem;
  filterValues: TipFilterValues;
  tips: ResultOrientedTipItem[];
};

const TipBox: FC<TipBoxProps> = ({ fixture, filterValues, tips }) => {
  const [matchDetailsOpen, setMatchDetailsOpen] = useState(false);
  const teamsHeaderProps = useTeamsHeaderProps(fixture);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const admin = useIsAdmin();

  return (
    <Stack direction="column">
      <Box
        ref={boxRef}
        key={`fixture-list-fixture-${fixture.fixture.id}`}
        onHeaderClick={() => setMatchDetailsOpen(true)}
        header={
          <Stack gap="none">
            <TeamsHeader {...teamsHeaderProps} />
          </Stack>
        }
      >
        <MatchDetailsDrawer
          isOpen={matchDetailsOpen}
          onClose={() => setMatchDetailsOpen(false)}
          fixture={fixture}
        />
        <TipsTable tips={tips} filterValues={filterValues} />
      </Box>
      {admin.isInGroup && (
        <Stack align="end" justify="end" isFullwidth>
          <ScreenshotButton
            filename={`${fixture.teams.home.name} vs ${fixture.teams.away.name}`}
            elementRef={boxRef}
          >
            <Icon icon="Image" />
            <span>Download</span>
          </ScreenshotButton>
        </Stack>
      )}
    </Stack>
  );
};

type ResultOrientedProps = {
  filterValues: TipFilterValues;
};

export const ResultOrientedTipsV2: FC<ResultOrientedProps> = ({
  filterValues,
}) => {
  const { data: resultOriented, isLoading } = useGetResultOrientedTips({
    from: dayjs(filterValues.from).format("YYYY-MM-DD"),
    to: dayjs(filterValues.to).format("YYYY-MM-DD"),
  });

  const filteredData = useMemo(() => {
    return filterValues.include_leagues?.length
      ? resultOriented?.data.filter((e) =>
          filterValues.include_leagues?.includes(String(e.fixture.fixture.id)),
        )
      : resultOriented?.data;
  }, [resultOriented, filterValues]);

  const ComboItems = useMemo(() => {
    return filteredData
      ?.map((e) => ({
        ...e,
        tip: e.tips
          .filter((tip) => tip.rating === "super safe")
          .filter((tip) => Number(tip.probability) >= 90)
          .sort((a, b) => Number(b.probability) - Number(a.probability))[0],
      }))
      ?.filter((e) => !!e.tip);
  }, [filteredData]);

  const RecommendedTips = useMemo(() => {
    return filteredData
      ?.map((e) => ({
        ...e,
        tips: e.tips.filter((tip) => tip.best_choice),
      }))
      ?.filter((e) => !!e.tips.length);
  }, [filteredData]);

  console.log({ ComboItems, RecommendedTips });

  return (
    <Stack direction="column">
      <Columns>
        <Column>
          <Stack direction="column" gap="xl">
            {!isLoading && !filteredData?.length && (
              <InfoBox
                icon="SmileyXEyes"
                hasSpacing
                title="Upcoming tips not found ..."
              >
                We couldn&apos;t find any tips with your filter criteria. <br />
                Come back later or modify your search.
              </InfoBox>
            )}
            {isLoading && (
              <>
                {Array(3)
                  .fill("result-oriented-header")
                  .map((identifier, index) => (
                    <Box
                      key={`${identifier}-${index}`}
                      header={
                        <TeamsHeader
                          isLoading
                          title="Bahia vs Botafogo"
                          subtitle={
                            <>
                              <TeamsInfoItem
                                isLoading
                                icon="Trophy"
                                label="08 August"
                              />
                              <TeamsInfoItem
                                isLoading
                                icon="Trophy"
                                label="00:00"
                              />
                              <TeamsInfoItem
                                isLoading
                                icon="Trophy"
                                label="Copa do Brasil"
                              />
                            </>
                          }
                          score="2-2"
                        />
                      }
                    />
                  ))}
              </>
            )}
            {filteredData
              ?.sort(
                (a, b) =>
                  Number(a.fixture.fixture.timestamp) -
                  Number(b.fixture.fixture.timestamp),
              )
              .map(({ fixture, tips }) => {
                return (
                  <TipBox
                    key={`result-oriented-match-fixture-${fixture.fixture.id}`}
                    fixture={fixture}
                    tips={tips}
                    filterValues={filterValues}
                  />
                );
              })}
          </Stack>
        </Column>
      </Columns>
    </Stack>
  );
};
