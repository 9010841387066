import React, { FC, PropsWithChildren, useState } from "react";

import classNames from "classnames";

import { Button, Icon, Icons, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./OddsMessage.scss";

type OddsMessageProps = {
  title: string;
  icon?: Icons;
};

export const OddsMessage: FC<PropsWithChildren<OddsMessageProps>> = ({
  title,
  children,
  icon,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <article className="odds-message">
      <Stack
        className="odds-message__header"
        onClick={() => setOpen(!open)}
        tabIndex={-1}
        justify="between"
        align="center"
      >
        <Text>
          {icon && <Icon icon={icon} />}
          <span>{title}</span>
        </Text>
        <Button variant="ghost" isRounded size="small">
          <Icon icon={open ? "CaretUp" : "CaretDown"} />
        </Button>
      </Stack>
      <div
        className={classNames([
          "odds-message__body",
          { "odds-message__body--active": open },
        ])}
      >
        {children}
      </div>
    </article>
  );
};
