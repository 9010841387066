import React, { FC, useMemo } from "react";

import {
  formatHitRate,
  formatOdds,
  formatProbability,
  formatRoi,
} from "@utils";

import { GetTipsSummaryResponse } from "@api/math-tips/getTipsSummary";

import { Box, Icon, IconCard, Tag, Text, Title } from "@ui/elements";
import { Cell, Grid, Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import { ROIChart, Tooltip, TooltipContent, TooltipTrigger } from "@components";

type AIPerformanceCardsProps = {
  isLoading?: boolean;
  data?: GetTipsSummaryResponse;
};

export const AIPerformanceCards: FC<AIPerformanceCardsProps> = ({
  isLoading,
  data,
}) => {
  if (!isLoading && !data?.summary.bankroll_over_time.length) {
    return null;
  }

  const profitLossColor: Colors | undefined = useMemo(() => {
    if (Number(data?.summary.total_profit) > 0) {
      return "success";
    }

    if (Number(data?.summary.total_profit) < 0) {
      return "danger";
    }
  }, [data?.summary.total_profit]);

  return (
    <Stack isFullwidth direction="column" gap="xxl">
      <Stack isFullwidth direction="column">
        <Grid
          type="fixed"
          cols={{ mobile: 2, tablet: 3, desktop: 2, fullhd: 3 }}
        >
          <Cell colSpan={{ mobile: 2, tablet: 1, desktop: 2, fullhd: 1 }}>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Target" />
                <Stack direction="column" gap="none">
                  <Text size="small">Hit Rate</Text>
                  <Title size={5} isLoading={isLoading}>
                    {formatHitRate(data?.summary.hit_rate)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Coin" />
                <Stack direction="column" gap="none">
                  <Text size="small">Avg. Probability</Text>
                  <Title size={5} isLoading={isLoading}>
                    {formatProbability(data?.summary.avg_probability)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell>
            <Box isFullwidth>
              <Stack align="center">
                <IconCard icon="Scales" />
                <Stack direction="column" gap="none">
                  <Text size="small">Avg. Odds</Text>
                  <Title size={5} isLoading={isLoading}>
                    {formatOdds(data?.summary.avg_odds)}
                  </Title>
                </Stack>
              </Stack>
            </Box>
          </Cell>
          <Cell
            colSpan={{ mobile: 2, tablet: 3, desktop: 2, fullhd: 3 }}
            rowStart={{ mobile: 1, tablet: 2, desktop: 3, fullhd: 2 }}
          >
            <Box isFullwidth>
              <ROIChart
                title={
                  <Stack gap="xxs">
                    <Tooltip placement="top-start">
                      <TooltipTrigger>
                        <Icon icon="Info" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <Stack direction="column" className="tooltip">
                          <Text>
                            ROI (Return on Investment) shows the percentage of
                            profit or loss generated from the AI’s tips over
                            time. It helps track how well the AI’s predictions
                            are performing.
                          </Text>
                          <Text>
                            <em>
                              ROI = (Total Profit or Loss / Total Amount Staked)
                              × 100
                            </em>
                          </Text>
                        </Stack>
                      </TooltipContent>
                    </Tooltip>
                    <span>Return on Investment</span>
                  </Stack>
                }
                value={formatRoi(data?.summary.roi)}
                data={data?.summary.bankroll_over_time}
                isLoading={isLoading}
                endContainer={
                  <Tooltip placement="top-end">
                    <TooltipTrigger>
                      <Tag
                        size="medium"
                        variant="light"
                        type={profitLossColor}
                        isLoading={isLoading}
                      >
                        {formatRoi(data?.summary.total_profit)}
                      </Tag>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="tooltip">
                        <Stack direction="column">
                          <Text>
                            Total profit/loss in percentage for the selected
                            selected period.
                          </Text>
                        </Stack>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                }
              />
            </Box>
          </Cell>
        </Grid>
      </Stack>
    </Stack>
  );
};
