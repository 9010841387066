import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { FetchLeaguesResponse } from "../../football/fetchLeagues";
import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type UpdateLeaguesResponse = {
  news_enabled: boolean;
};

export type UpdateLeaguesArgs = FetchLeaguesResponse[];

const updateLeagues: MutationFunction<
  UpdateLeaguesResponse,
  UpdateLeaguesArgs
> = (args) => {
  return client.post("update-leagues", args).then((res) => res.data);
};

export const UPDATE_LEAGUES_QUERY_KEY = "update-leagues";

export const useUpdateLeagues = (
  options?: Omit<
    UseMutationOptions<
      UpdateLeaguesResponse,
      DefaultErrorResponse,
      UpdateLeaguesArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  UpdateLeaguesResponse,
  DefaultErrorResponse,
  UpdateLeaguesArgs
> => {
  return useMutation([UPDATE_LEAGUES_QUERY_KEY], updateLeagues, options);
};
