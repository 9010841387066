import { useMemo } from "react";

import { QueryFunction } from "react-query";

import { client } from "../client";

type MissingStatisticsBreakdown = {
  total: number;
  missing: number;
};

export type StatisticsKeys =
  | "shots_on_target"
  | "shots_off_target"
  | "total_shots"
  | "blocked_shots"
  | "shots_insidebox"
  | "shots_outsidebox"
  | "fouls"
  | "corners"
  | "offsides"
  | "ball_possessions"
  | "yellow_cards"
  | "red_cards"
  | "goalkeeper_saves"
  | "total_passes"
  | "passes_accurate"
  | "passes_percentage"
  | "expected_goals"
  | "shot_accuracy"
  | "first_half_goals"
  | "second_half_goals";

export type PlayerStatisticsKeys =
  | "minutes"
  | "rating"
  | "offsides"
  | "shots_total"
  | "shots_on_goal"
  | "goals_total"
  | "goals_conceded"
  | "assists"
  | "saves"
  | "passes_total"
  | "passes_key"
  | "pass_accuracy"
  | "tackles"
  | "blocks"
  | "interceptions"
  | "duels"
  | "duels_won"
  | "dribbles"
  | "dribbles_success"
  | "dribbles_past"
  | "fouls_drawn"
  | "fouls_committed"
  | "yellow_cards"
  | "red_cards";

export const ALL_STATISTICS_KEYS: StatisticsKeys[] = [
  "shots_on_target",
  "shots_off_target",
  "total_shots",
  "blocked_shots",
  "shots_insidebox",
  "shots_outsidebox",
  "fouls",
  "corners",
  "offsides",
  "ball_possessions",
  "yellow_cards",
  "red_cards",
  "goalkeeper_saves",
  "total_passes",
  "passes_accurate",
  "passes_percentage",
  "expected_goals",
  "shot_accuracy",
  "first_half_goals",
  "second_half_goals",
];

export type StatisticsAverage = Record<StatisticsKeys, number>;

export type StatisticsSummary = {
  missing_statistics: {
    breakdown: Record<StatisticsKeys, MissingStatisticsBreakdown>;
    total: MissingStatisticsBreakdown;
  };
  average_statistics: {
    home: {
      total: StatisticsAverage;
      played_home: StatisticsAverage;
      played_away: StatisticsAverage;
    };
    away: {
      total: StatisticsAverage;
      played_home: StatisticsAverage;
      played_away: StatisticsAverage;
    };
    h2h: {
      home: StatisticsAverage;
      away: StatisticsAverage;
    };
  };
};

export type StatisticsRanking = {
  home: number;
  away: number;
};

type StatisticsStandingBreakdown = {
  played: number;
  win: number;
  draw: number;
  lose: number;
  goals: {
    goals_for: number;
    against: number;
  };
};

export type StatisticsStanding = {
  rank: number;
  points: number;
  goalsDiff: number;
  group: string;
  form: string;
  all: StatisticsStandingBreakdown;
  home: StatisticsStandingBreakdown;
  away: StatisticsStandingBreakdown;
};

export type StatisticsStandingsRecord = {
  home: StatisticsStanding;
  away: StatisticsStanding;
};

type OddValue = {
  value: string;
  odd: number;
};

export type OddItem = {
  id: number;
  name: string;
  values: OddValue[];
};

export type PlayerPosition = "G" | "D" | "M" | "F";

type Player = {
  id: number;
  name: string;
  position: PlayerPosition;
  team_id: number;
  played_times: number;
};

export type PlayerRecord = {
  player: Player;
  statistics: Record<PlayerStatisticsKeys, number | null>;
};

export type TopPlayersRecord = {
  home: PlayerRecord[];
  away: PlayerRecord[];
};

export type InjuredPlayersRecord = {
  home: PlayerRecord[];
  away: PlayerRecord[];
};

export type LineupsPlayers = {
  starting_xi: PlayerRecord[];
  substitutes: PlayerRecord[];
};

export type LineupsPlayerRecord = {
  home: LineupsPlayers;
  away: LineupsPlayers;
};

type RecentFixtureInfo = {
  id: number;
  referee: string;
  timezone: string;
  date: string;
  timestamp: number;
};

type RecentFixtureLeague = {
  id: number;
  name: string;
  country: string;
  logo: string;
  flag: string;
  season: number;
  round: string;
};

type RecentFixtureTeam = {
  id: number;
  name: string;
  logo: string;
  ranking: number;
  winner: boolean;
};

type RecentFixtureGoals = {
  home: number | null;
  away: number | null;
};

type StatisticsRecord = {
  team: {
    id: number;
    name: string;
    logo: string;
  };
  statistics: StatisticsAverage;
};

export type RecentFixtureRecord = {
  fixture: RecentFixtureInfo;
  league: RecentFixtureLeague;
  teams: {
    home: RecentFixtureTeam;
    away: RecentFixtureTeam;
  };
  goals: RecentFixtureGoals;
  score: {
    halftime: RecentFixtureGoals;
    fulltime: RecentFixtureGoals;
    extratime: RecentFixtureGoals;
    penalty: RecentFixtureGoals;
  };
  statistics: StatisticsRecord[];
};

type StatisticsResponse = {
  summary: StatisticsSummary;
  rankings: StatisticsRanking;
  standings: StatisticsStandingsRecord;
  home_fixtures: RecentFixtureRecord[];
  away_fixtures: RecentFixtureRecord[];
  h2h_fixtures: RecentFixtureRecord[];
  lineups: LineupsPlayerRecord;
  odds: OddItem[];
  top_players: TopPlayersRecord;
  injuries: InjuredPlayersRecord;
};

export const fetchStatistics: QueryFunction<StatisticsResponse> = ({
  queryKey,
}) => {
  const [_key, id] = queryKey;

  return client.get(`/statistics`, { params: { id } }).then((res) => res.data);
};

type UseConcededGoalsOptions = {
  fixtures_record?: Array<RecentFixtureRecord[] | undefined>;
  home_team_name?: string;
  away_team_name?: string;
};

export const useConcededGoals = ({
  fixtures_record,
  home_team_name,
  away_team_name,
}: UseConcededGoalsOptions) => {
  const homeFixtures = fixtures_record?.[0] || [];
  const awayFixtures = fixtures_record?.[1] || [];

  const homeConcededGoals = useMemo(() => {
    const fixturesWithGoals =
      homeFixtures?.filter(
        (e) =>
          typeof e.goals.away === "number" && typeof e.goals.home === "number",
      ) || [];

    const totalConceded = fixturesWithGoals?.reduce((acc, curr) => {
      let opponent: "home" | "away";

      if (curr.teams.home.name === home_team_name) {
        opponent = "away";
      } else {
        opponent = "home";
      }

      return (acc += curr.goals[opponent] || 0);
    }, 0);

    return totalConceded / fixturesWithGoals?.length;
  }, [homeFixtures, home_team_name]);

  const awayConcededGoals = useMemo(() => {
    const fixturesWithGoals =
      awayFixtures?.filter(
        (e) =>
          typeof e.goals.away === "number" && typeof e.goals.home === "number",
      ) || [];

    const totalConceded = fixturesWithGoals?.reduce((acc, curr) => {
      let opponent: "home" | "away";

      if (curr.teams.away.name === away_team_name) {
        opponent = "home";
      } else {
        opponent = "away";
      }

      return (acc += curr.goals[opponent] || 0);
    }, 0);

    return totalConceded / fixturesWithGoals?.length;
  }, [awayFixtures, away_team_name]);

  return [homeConcededGoals, awayConcededGoals];
};
