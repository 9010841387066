import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type StandingItem = {
  all: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  away: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  form: string;
  rank: string;
  description: string;
  update: string;
  team: {
    name: string;
    logo: string;
    id: string;
  };
  goalsDiff: string;
  points: string;
  group: string;
  status: string;
  home: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
};

type StatisticsData = {
  id: string;
  standings: {
    home: StandingItem;
    away: StandingItem;
  };
  prompt?: string;
};

export type GetStatisticsResponse = {
  error: string | null;
  data: StatisticsData | null;
};

export const getStatistics: QueryFunction<GetStatisticsResponse> = ({
  queryKey,
}) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/statistics", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetStatisticsQueryKey = "get-key-statistics";

export const useGetStatistics = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetStatisticsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetStatisticsResponse, DefaultErrorResponse> => {
  return useQuery([GetStatisticsQueryKey, id], getStatistics, options);
};
