import React, { FC, forwardRef } from "react";

import classNames from "classnames";
import RcSlider, { SliderProps as RcSliderProps } from "rc-slider";

import { Text } from "@ui/elements";
import { Label } from "@ui/forms";
import { Stack } from "@ui/layout";
import type { Colors } from "@ui/types";

import "./Slider.scss";

export type SliderProps = {
  label?: string;
  type?: Colors;
} & RcSliderProps;

export const Slider: FC<SliderProps> = forwardRef<HTMLElement, SliderProps>(
  ({ label, type, value, ...rest }, ref) => {
    return (
      <div className="custom-slider">
        <Stack gap="xxs" direction="column">
          <Stack justify="between">
            {label && <Label>{label}</Label>}
            <Text>{value}</Text>
          </Stack>
          <RcSlider
            value={value}
            className={classNames({ [`is-${type}`]: type })}
            ref={ref}
            {...rest}
          />
        </Stack>
      </div>
    );
  },
);

Slider.displayName = "Slider";
