import React, { FC, HTMLAttributes, PropsWithChildren } from "react";

import classNames from "classnames";

import { Colors, Variants } from "../../types";
import "./Tag.scss";

type TagProps = PropsWithChildren<{
  type?: Colors;
  variant?: Variants;
  size?: "normal" | "medium" | "large";
  isClickable?: boolean;
  isDelete?: boolean;
  isRounded?: boolean;
  isLoading?: boolean;
}> &
  HTMLAttributes<HTMLSpanElement>;

export const Tag: FC<TagProps> = ({
  children,
  type,
  variant,
  size,
  isClickable,
  isDelete,
  isRounded,
  isLoading,
  className,
  ...rest
}) => {
  return (
    <span
      className={classNames([
        "tag",
        className,
        {
          [`is-${type}`]: type,
          [`is-${variant}`]: variant,
          [`is-${size}`]: size,
          "is-hoverable": isClickable,
          "is-delete": isDelete,
          "is-rounded": isRounded,
          "is-skeleton": isLoading,
        },
      ])}
      {...rest}
    >
      {children}
    </span>
  );
};

type TagsProps = PropsWithChildren<{
  hasAddons?: boolean;
}> &
  HTMLAttributes<HTMLDivElement>;

export const Tags: FC<TagsProps> = ({ children, hasAddons }) => {
  return (
    <div className={classNames("tags", { "has-addons": hasAddons })}>
      {children}
    </div>
  );
};
