import React from "react";

import dayjs from "dayjs";
import { toast } from "react-toastify";

import {
  capitalize,
  copyToClipboard,
  formatBetAmount,
  formatOdds,
  formatProbability,
  formatTipName,
} from "@utils";

import { TipRating, useFillResults, useGetMatchAnalysis } from "@api/math-tips";
import { useGetFixtureTips } from "@api/math-tips/getFixtureTips";

import { Button } from "@ui/elements";
import { Stack } from "@ui/layout";

import { useMatchDetails } from "@components";

const TipCategoryMap: Record<TipRating, string> = {
  safe: "🟢",
  value: "🔵",
  risky: "🔴",
};

export const AdminActions = () => {
  const { fixture } = useMatchDetails();
  const { mutateAsync: fillResultsMutate } = useFillResults();
  const { data: tips } = useGetFixtureTips({ id: String(fixture.fixture.id) });
  const { data: matchAnalysis } = useGetMatchAnalysis({
    id: String(fixture.fixture.id),
  });

  const fillResults = () => {
    toast.promise(() => fillResultsMutate({ id: fixture.fixture.id }), {
      pending: "Fill results loading",
      success: "Filled results 👌",
      error: "Failed to fill results 🤯",
    });
  };
  const copyId = () => {
    toast.promise(() => copyToClipboard(fixture.fixture.id), {
      success: "Copied to clipboard 👌",
    });
  };

  const copyTelegramMessage = () => {
    if (!matchAnalysis) {
      return toast("No match analysis", {
        type: "error",
      });
    }
    if (!tips) {
      return toast("No tips found", {
        type: "error",
      });
    }

    let str = `${fixture.teams.home.name} ⚔️ ${fixture.teams.away.name}\n`;
    str += `⏰ Kickoff: ${dayjs(fixture.fixture.date).format("HH:mm")}\n\n`;

    str += `📝 Prediction (${matchAnalysis.correct_score}):\n`;
    str += `${matchAnalysis.correct_score_reasoning}\n\n`;

    str += `🔮 Tips:\n`;
    tips.forEach((tip) => {
      str += `${formatTipName(tip.category, tip.name)} (${
        TipCategoryMap[tip.rating]
      } ${capitalize(tip.rating)})\n`;
      str += `🍀 Probability: ${formatProbability(tip.probability)}\n`;
      str += `⚖️ Odds: ${formatOdds(tip.odds)}\n`;
      str += `💰 Bet Amount: ${formatBetAmount(tip.bet_amount)}\n\n`;
    });

    toast.promise(() => copyToClipboard(str), {
      success: "Copied to clipboard 👌",
    });
  };

  return (
    <Stack>
      <Button variant="light" onClick={copyId}>
        <span>Fixture ID</span>
      </Button>
      <Button variant="light" onClick={copyTelegramMessage}>
        <span>Telegram Message</span>
      </Button>
      <Button variant="light" onClick={fillResults}>
        <span>Fill Results</span>
      </Button>
    </Stack>
  );
};
