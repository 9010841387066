import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";
import {
  GET_TIPS_QUERY_KEY,
  GET_UPCOMING_PARLAYS_QUERY_KEY,
  GET_UPCOMING_TIPS_QUERY_KEY,
} from "@api/math-tips";
import { GET_USER_PREFERENCES_QUERY_KEY } from "@api/user/getPreferences";

import { client } from "../client";

type UpdatePreferencesArgs = Partial<{
  kellyFraction: number;
  currency: string;
  allowResultOrientedV2: boolean;
  force?: boolean;
}>;

export type UpdatePreferencesResponse = {
  success: boolean;
  message: string;
};

const updatePreferences: MutateFunction<
  UpdatePreferencesResponse,
  DefaultErrorResponse,
  UpdatePreferencesArgs
> = (params) => {
  return client.post("preferences", params).then((res) => res.data);
};

export const UpdatePreferencesMutationKey = "update-preferences";

export const useUpdatePreferences = (
  options?: Omit<
    UseMutationOptions<
      UpdatePreferencesResponse,
      DefaultErrorResponse,
      UpdatePreferencesArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  UpdatePreferencesResponse,
  DefaultErrorResponse,
  UpdatePreferencesArgs
> => {
  const queryClient = useQueryClient();

  return useMutation([UpdatePreferencesMutationKey], updatePreferences, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_USER_PREFERENCES_QUERY_KEY],
      });

      // Fetch to get new bet_amount
      if (variables.kellyFraction) {
        queryClient.invalidateQueries({
          queryKey: [GET_UPCOMING_TIPS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_UPCOMING_PARLAYS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [GET_TIPS_QUERY_KEY],
        });
      }
      options?.onSuccess?.(data, variables, context);
    },
  });
};
