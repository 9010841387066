import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type TipCategory =
  | "Match Winner"
  | "Double Chance"
  | "Draw no Bet"
  | "Win to Nil"
  | "Win and Both to Score"
  | "Win Either Half"
  | "Win Both Halves"
  | "First Half Winner"
  | "Second Half Winner"
  | "Goals"
  | "Home Goals"
  | "Away Goals"
  | "First Half Goals"
  | "Second Half Goals"
  | "Both Teams to Score"
  | "Score in Both Halves"
  | "Team to Score First"
  | "Home Clean Sheet"
  | "Away Clean Sheet"
  | "Player to Score"
  | "Cards"
  | "Home Cards"
  | "Away Cards"
  | "Exact Score";

export const TipCategoryMap: Record<TipCategory, string> = {
  "Match Winner": "Match Winner",
  "Double Chance": "Double Chance",
  "Draw no Bet": "Asian Handicap",
  "Win to Nil": "Win To Nil",
  "Win and Both to Score": "Results/Both Teams Score",
  "Win Either Half": "To Win Either Half",
  "Win Both Halves": "Win Both Halves",
  "First Half Winner": "First Half Winner",
  "Second Half Winner": "Second Half Winner",
  Goals: "Goals Over/Under",
  "Home Goals": "Total - Home",
  "Away Goals": "Total - Away",
  "First Half Goals": "Goals Over/Under First Half",
  "Second Half Goals": "Goals Over/Under - Second Half",
  "Both Teams to Score": "Both Teams Score",
  "Score in Both Halves": "To Score In Both Halves By Teams",
  "Team to Score First": "Team To Score First",
  "Home Clean Sheet": "Clean Sheet - Home",
  "Away Clean Sheet": "Clean Sheet - Away",
  "Player to Score": "Anytime Goal Scorer",
  Cards: "Cards Over/Under",
  "Home Cards": "Home Team Total Cards",
  "Away Cards": "Away Team Total Cards",
  "Exact Score": "Exact Score",
};

export type NextGenTipItem = {
  id: string;
  tip_name: string;
  tip_group: "safe" | "value" | "risk";
  tip_category: TipCategory;
  tip_value: string;
  chance_of_occurring: string;
  reasoning: string;
};

type TipsData = {
  analysis: {
    motivations: string;
    standings: string;
    characteristics: string;
    statistics: string;
    players: string;
    lineup: string;
    expert_opinion: string;
  };
  id: string;
  predictions: Array<NextGenTipItem[]>;
  summary: NextGenTipItem[];
};

export type GetTipsResponse = {
  error: string | null;
  data: TipsData | null;
};

export const getTips: QueryFunction<GetTipsResponse> = ({ queryKey }) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/get-tips", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetTipsQueryKey = "get-nextgen-tips";

export const useGetNextGenTips = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetTipsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetTipsResponse, DefaultErrorResponse> => {
  return useQuery([GetTipsQueryKey, id], getTips, options);
};
