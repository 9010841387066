import { useEffect, useState } from "react";

const tabletBreakpoint = 768; // Matches max-width: 768px
const desktopBreakpoint = 1024; // Matches min-width: 1024px
const widescreenBreakpoint = 1216; // Matches min-width: 1216px
const fullhdBreakpoint = 1408; // Matches min-width: 1408px

export type Device = "mobile" | "tablet" | "desktop" | "widescreen" | "fullhd";

export const useDevice = (): Device => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (windowSize.width <= tabletBreakpoint) {
    return "mobile";
  }

  if (
    windowSize.width > tabletBreakpoint &&
    windowSize.width < desktopBreakpoint
  ) {
    return "tablet";
  }

  if (
    windowSize.width >= desktopBreakpoint &&
    windowSize.width < widescreenBreakpoint
  ) {
    return "desktop";
  }

  if (
    windowSize.width >= widescreenBreakpoint &&
    windowSize.width < fullhdBreakpoint
  ) {
    return "widescreen";
  }

  if (windowSize.width >= fullhdBreakpoint) {
    return "fullhd";
  }

  return "fullhd";
};
