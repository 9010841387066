import React, { FC, useContext, useMemo } from "react";

import classNames from "classnames";

import { useGetLeagueFixtures } from "../../../../../services/api/football/fetchFixturesByLeagueId/fetchFixturesByLeagueId";
import { StepComponentCommonProps } from "../../StepComponent/StepComponent";
import { TipValidatorContext, ValidatorSteps } from "../TipValidator";

export const FixtureSelect: FC<StepComponentCommonProps<ValidatorSteps>> = ({
  goToStep,
  active,
}) => {
  const {
    form: { register, watch },
  } = useContext(TipValidatorContext);
  const fixtureId = watch("id");
  const date = watch("date");
  const { data, isLoading } = useGetLeagueFixtures(date, { enabled: !!date });

  const fixtureName = useMemo(() => {
    const fixture = data
      ?.flatMap((e) => e.fixtures)
      .find((e) => String(e.fixture.id) === fixtureId);
    return `${fixture?.teams.home.name} vs ${fixture?.teams.away.name}`;
  }, [data, fixtureId]);

  if (!active) {
    return <p>{fixtureName}</p>;
  }

  return (
    <div>
      <div className="field">
        <div className="control">
          <div className={classNames(["select", { "is-loading": isLoading }])}>
            <select {...register("id")}>
              <option value="">Choose an option</option>
              {data
                ?.flatMap((e) => e.fixtures)
                .map((fixture) => (
                  <option
                    key={`select-${fixture.fixture.id}`}
                    value={fixture.fixture.id}
                  >
                    {fixture.teams.home.name} vs {fixture.teams.away.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className="field">
        <div className="control">
          <button
            type="button"
            className="button is-secondary"
            disabled={!fixtureId}
            onClick={() => {
              goToStep("news");
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
