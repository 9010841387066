import React from "react";

import { Spinner } from "@ui/elements";
import { Stack } from "@ui/layout";

export const FullPageLoading = () => {
  return (
    <Stack justify="center">
      <Spinner />
    </Stack>
  );
};
