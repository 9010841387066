import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

export type MatchAnalysisTeamMetrics = {
  team_health_index: string;
  motivations: string;
  injury_impact: string;
  tactical_advantage: string;
  recent_form: string;
};

export type MatchAnalysisRecommendationItem = {
  title: string;
  short_reasoning: string;
};

export type MatchAnalysisResponse = {
  id: string;
  match_preview: string;
  correct_score_reasoning?: string;
  correct_score: string;
  summary: {
    predictability: string;
    safe_for_betting: boolean;
    match_importance: string;
  };
  team_comparison: {
    reasoning: string;
    home_team: MatchAnalysisTeamMetrics;
    away_team: MatchAnalysisTeamMetrics;
  };
  recommendations: {
    betting_recommendations: MatchAnalysisRecommendationItem[];
    things_to_avoid: MatchAnalysisRecommendationItem[];
  };
};

type MatchAnalysisQueryArgs = {
  id: string;
};

export const fetchMatchAnalysis: QueryFunction<MatchAnalysisResponse> = ({
  queryKey,
}) => {
  const [_key, id] = queryKey;

  return client
    .get("/match-analysis", { params: { id } })
    .then((res) => res.data);
};

export const FETCH_MATCH_ANALYSIS_QUERY_KEY = "fetch-match-analysis";

export const useGetMatchAnalysis = (
  args?: MatchAnalysisQueryArgs,
  options?: Omit<
    UseQueryOptions<MatchAnalysisResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<MatchAnalysisResponse, DefaultErrorResponse> => {
  return useQuery(
    [FETCH_MATCH_ANALYSIS_QUERY_KEY, args?.id],
    fetchMatchAnalysis,
    options,
  );
};
