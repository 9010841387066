import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client, Currency } from "../client";

export type GetUserTipResponse = {
  id: string;
  user_id: string;
  kelly_fraction: string;
  odds: string;
  start_date: string;
  bet_amount: string;
  currency: Currency;
  type: "single" | "parlay";
};

const getTip: QueryFunction<GetUserTipResponse> = ({ queryKey }) => {
  const [_key, id] = queryKey;

  return client.get(`/tips/${id}`).then((res) => res.data);
};

export const GET_USER_TIP_QUERY_KEY = "get-user-tip";

export const useGetUserTip = (
  id?: string,
  options?: Omit<
    UseQueryOptions<GetUserTipResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetUserTipResponse, DefaultErrorResponse> => {
  return useQuery([GET_USER_TIP_QUERY_KEY, id], getTip, options);
};
