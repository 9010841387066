import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { FixtureItem } from "@api/football/types/fixture";
import { DefaultErrorResponse } from "@api/index";
import { TipItem } from "@api/math-tips";

import { client } from "../client";

type ParlayResponseItem = {
  bet_amount: string;
  odds: string;
  probability: string;
  winner?: boolean;
  start_date: string;
  end_date: string;
  tips: TipItem[];
};

export type UpcomingParlaysResponse = {
  fixtures: FixtureItem[];
  parlays: ParlayResponseItem[];
};

export const fetchUpcomingParlays: QueryFunction<UpcomingParlaysResponse> = ({
  queryKey,
}) => {
  const [_key] = queryKey;

  return client.get("/upcoming-parlays").then((res) => res.data);
};

export const GET_UPCOMING_PARLAYS_QUERY_KEY = "get-upcoming-parlays";

export const useGetUpcomingParlays = (
  options?: Omit<
    UseQueryOptions<UpcomingParlaysResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<UpcomingParlaysResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_UPCOMING_PARLAYS_QUERY_KEY],
    fetchUpcomingParlays,
    options,
  );
};
