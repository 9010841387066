import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type MathPrediction = {
  name:
    | "Goals Over/Under"
    | "Match Winner"
    | "Both Teams Score"
    | "Double Chance";
  value: string;
  probability: number;
};

export type GetMathPredictionResponse = {
  id: number;
  home_team: string;
  away_team: string;
  date: string;
  correct_score: string;
  url: string;
  predictions?: MathPrediction[];
  error?: string;
};

export const getMathPrediction: QueryFunction<GetMathPredictionResponse> = ({
  queryKey,
}) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("get-math-prediction", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetMathPredictionQueryKey = "get-math-prediction";

export const useGetMathPrediction = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetMathPredictionResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetMathPredictionResponse, DefaultErrorResponse> => {
  return useQuery([GetMathPredictionQueryKey, id], getMathPrediction, options);
};
