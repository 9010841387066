import React, { useEffect, useState } from "react";

import { confirmSignUp } from "aws-amplify/auth";
import { Link, useSearchParams } from "react-router-dom";

import { Button, Icon, Notification, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

export const VerifyAccount = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const verifyAccount = async () => {
    setLoading(true);

    try {
      await confirmSignUp({
        confirmationCode: token as string,
        username: email as string,
      });
    } catch (error) {
      setLoading(false);

      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    }

    setLoading(false);
  };

  useEffect(() => {
    verifyAccount();
  }, []);

  if (loading) {
    return <Text>Verifying account, please wait ...</Text>;
  }

  const errorMessage =
    error && error !== "User cannot be confirmed. Current status is CONFIRMED"
      ? error
      : "";

  return (
    <Stack direction="column">
      {!errorMessage && (
        <>
          <Stack align="center" gap="xxs">
            <Icon
              className="has-text-success"
              icon="CheckCircle"
              size="medium"
            />
            <Title size={5}>Account verified!</Title>
          </Stack>
          <Text>You can continue now to access the application.</Text>
        </>
      )}
      {errorMessage && (
        <Notification color="danger">{errorMessage}</Notification>
      )}
      <Link to="/login">
        <Button color="primary" isFullwidth>
          Log In
        </Button>
      </Link>
    </Stack>
  );
};
