import React, { FC, useMemo, useState } from "react";
import "./CalendarSlider.scss";
import "swiper/css";
import "swiper/css/navigation";

import dayjs from "dayjs";
import { Swiper as SwiperRef } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Button, CalendarDay, Icon } from "@ui/elements";
import { Stack } from "@ui/layout";

type CalendarSliderProps = {
  value?: string;
  onChange: (date: string) => void;
};

export const CalendarSlider: FC<CalendarSliderProps> = ({
  value,
  onChange,
}) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperRef | null>(null);

  const weeks = useMemo(() => {
    const startDate = dayjs().subtract(1, "month").startOf("isoWeek");
    const endDate = dayjs().add(1, "month").endOf("isoWeek");

    const daysArray = [];
    let currentDate = startDate;

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      daysArray.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    const groupedArray = [];
    for (let i = 0; i < daysArray.length; i += 7) {
      groupedArray.push(daysArray.slice(i, i + 7));
    }

    return groupedArray;
  }, []);

  const activeIndex = useMemo(() => {
    return weeks.findIndex((dates) =>
      dates.find((date) => date === dayjs().format("YYYY-MM-DD")),
    );
  }, [weeks]);

  return (
    <div className="calendar-slider">
      <Button
        isRounded
        variant="ghost"
        onClick={() => swiperInstance?.slidePrev()}
      >
        <Icon icon="CaretLeft" />
      </Button>

      <Swiper
        initialSlide={activeIndex}
        slidesPerView={1}
        speed={300}
        spaceBetween={50}
        onSwiper={(swiper: SwiperRef) => setSwiperInstance(swiper)}
      >
        {weeks.map((days, weekIndex) => (
          <SwiperSlide key={`calendar-week-${weekIndex}`}>
            <Stack isFullwidth justify="between">
              {days.map((date) => (
                <CalendarDay
                  onClick={() => onChange(date)}
                  isActive={value === date}
                  key={`calendar-day-${date}`}
                  date={date}
                />
              ))}
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>

      <Button
        size="small"
        isRounded
        variant="ghost"
        onClick={() => swiperInstance?.slideNext()}
      >
        <Icon icon="CaretRight" />
      </Button>
    </div>
  );
};
