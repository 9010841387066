import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";

import { GET_EMAIL_REFERENCES_QUERY_KEY } from "@api/cognito/getEmailPreferences";
import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

export type UpdateEmailPreferencesArgs = {
  token?: string;
  platformUpdates?: boolean;
  feedbackSurvey?: boolean;
  newsLetters?: boolean;
  marketingEmails?: boolean;
};

export type UpdateEmailPreferencesResponse = {
  message: string;
  error?: string;
};

const mutateUpdateEmailPreferences: MutationFunction<
  UpdateEmailPreferencesResponse,
  UpdateEmailPreferencesArgs
> = (args) => {
  return client
    .put("/email-preferences", {
      token: args.token,
      platformUpdates: args.platformUpdates,
      feedbackSurvey: args.feedbackSurvey,
      newsLetters: args.newsLetters,
      marketingEmails: args.marketingEmails,
    })
    .then((res) => res.data);
};

export const useUpdateEmailPreferences = (
  options?: UseMutationOptions<
    UpdateEmailPreferencesResponse,
    DefaultErrorResponse,
    UpdateEmailPreferencesArgs
  >,
): UseMutationResult<
  UpdateEmailPreferencesResponse,
  DefaultErrorResponse,
  UpdateEmailPreferencesArgs
> => {
  const queryClient = useQueryClient();

  return useMutation(mutateUpdateEmailPreferences, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        queryKey: [GET_EMAIL_REFERENCES_QUERY_KEY, variables.token],
      });
    },
  });
};
