import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type StandingItem = {
  all: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  away: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
  form: string;
  rank: string;
  description: string;
  update: string;
  team: {
    name: string;
    logo: string;
    id: string;
  };
  goalsDiff: string;
  points: string;
  group: string;
  status: string;
  home: {
    draw: string;
    played: string;
    win: string;
    lose: string;
    goals: {
      for: string;
      against: string;
    };
  };
};

type PlayersData = {
  id: string;
  standings: {
    home: StandingItem;
    away: StandingItem;
  };
  prompt?: string;
};

export type GetPlayersResponse = {
  error: string | null;
  data: PlayersData | null;
};

export const getPlayers: QueryFunction<GetPlayersResponse> = ({ queryKey }) => {
  const [_key, fixture_id] = queryKey;

  return client
    .get("/players", { params: { id: fixture_id } })
    .then((res) => res.data);
};

export const GetPlayersQueryKey = "get-top-players";

export const useGetPlayers = (
  id?: string | number,
  options?: Omit<
    UseQueryOptions<GetPlayersResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetPlayersResponse, DefaultErrorResponse> => {
  return useQuery([GetPlayersQueryKey, id], getPlayers, options);
};
