import React, { FC } from "react";

import { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import "./StepComponent.scss";

export type StepComponentCommonProps<T> = {
  goToStep: (step: T) => void;
  active: boolean;
};

type StepComponentItemProps<T> = {
  id: T;
  title: string;
  icon?: IconDefinition;
  Component: FC<StepComponentCommonProps<T>>;
};

type StepComponentProps<T> = {
  items: Array<StepComponentItemProps<T>>;
  currentStep: T;
  goToStep: (step: T) => void;
};

export const StepComponent = <T extends string>({
  items,
  currentStep,
  goToStep,
}: StepComponentProps<T>) => {
  return (
    <ul className="steps is-vertical">
      {items.map((item, index) => {
        const activeIndex = items.findIndex((item) => item.id === currentStep);
        const clickable = activeIndex > index;

        const handleItemClick = () => {
          if (clickable) {
            goToStep(item.id);
          }
        };

        return (
          <li
            key={`step-item-${item.id}`}
            className={`steps-segment ${
              currentStep === item.id ? "is-active" : ""
            }`}
          >
            <span
              className={classNames([
                "steps-marker",
                { "has-cursor-pointer": clickable },
              ])}
              onClick={handleItemClick}
            >
              {item.icon ? <FontAwesomeIcon icon={item.icon} /> : index + 1}
            </span>
            <div className="steps-content">
              <p
                className={classNames([
                  "is-size-5 mb-3 has-text-weight-light",
                  { "has-cursor-pointer": clickable },
                ])}
                onClick={handleItemClick}
              >
                {item.title}
              </p>
              <item.Component
                goToStep={goToStep}
                active={currentStep === item.id}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
