import React, { FC, PropsWithChildren } from "react";

import { InfoBox } from "@ui/components";
import { Spinner } from "@ui/elements/Spinner/Spinner";
import { Stack } from "@ui/layout";

import { useModal } from "@components";

import { usePageSettings } from "../PageTemplate/PageTemplate";

type SubscriptionWrapperProps = PropsWithChildren;

export const SubscriptionWrapper: FC<SubscriptionWrapperProps> = ({
  children,
}) => {
  const pageSettings = usePageSettings();
  const modals = useModal();

  if (pageSettings.isLoading) {
    return (
      <Stack justify="center">
        <Spinner />
      </Stack>
    );
  }

  if (!pageSettings.hasSubscription) {
    return (
      <Stack justify="center">
        <InfoBox
          hasSpacing
          icon="LockSimple"
          title="Access Denied"
          primaryButtonAction={() => modals.open("subscription")}
          primaryButtonLabel="View Plans"
        >
          It looks like you don’t have an active subscription.
          <br />
          To access this section, please choose a subscription plan.
        </InfoBox>
      </Stack>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return null;
};
