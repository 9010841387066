import React, { FC, PropsWithChildren } from "react";

import "./Grid.scss";
import classNames from "classnames";

type Enumerate<
  N extends number,
  Acc extends number[] = [],
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type IntRange<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

type GridGap =
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 4.5
  | 5
  | 5.5
  | 6
  | 6.5
  | 7
  | 7.5
  | 8;

type ResponsiveKeys = "mobile" | "tablet" | "desktop" | "widescreen" | "fullhd";

type ResponsiveCellOptionValue<ValueType> =
  | Partial<Record<ResponsiveKeys, TwelveRange>>
  | ValueType;

type TwelveRange = IntRange<1, 13>;

type CommonGridProps = {
  minColWidth?: IntRange<1, 33>;
  gap?: GridGap;
  columnGap?: GridGap;
  rowGap?: GridGap;
};

type SmartGridProps = {
  type?: "smart";
} & CommonGridProps;

type FixedGridProps = {
  type?: "fixed";
  cols?: ResponsiveCellOptionValue<TwelveRange>;
} & CommonGridProps;

type GridProps = PropsWithChildren<SmartGridProps | FixedGridProps>;

const formatResponsiveCellClass = (
  formatter: (value: string | number, responsiveKey?: ResponsiveKeys) => string,
  config?: ResponsiveCellOptionValue<TwelveRange>,
) => {
  if (!config) {
    return [];
  }

  if (typeof config !== "object") {
    return [formatter(config)];
  }

  return Object.entries(config).reduce((acc, [responsiveKey, value]) => {
    acc.push(formatter(value, responsiveKey as ResponsiveKeys));

    return acc;
  }, [] as string[]);
};

export const Grid: FC<GridProps> = ({ type = "smart", children, ...props }) => {
  const { minColWidth, gap, columnGap, rowGap } = props as CommonGridProps;

  const gridClassNames = classNames("grid", {
    [`is-col-min-${minColWidth}`]: minColWidth,
    [`is-column-gap-${columnGap}`]: columnGap,
    [`is-row-gap-${rowGap}`]: rowGap,
    [`is-gap-${gap}`]: gap,
  });

  if (type === "smart") {
    return <div className={gridClassNames}>{children}</div>;
  }

  const { cols } = props as FixedGridProps;

  return (
    <div
      className={classNames(
        "fixed-grid",
        "is-gap-5",
        ...formatResponsiveCellClass(
          (value, responsiveKey) =>
            responsiveKey
              ? `has-${value}-cols-${responsiveKey}`
              : `has-${value}-cols`,
          cols,
        ),
      )}
    >
      <div className={gridClassNames}>{children}</div>
    </div>
  );
};

type CellProps = PropsWithChildren<{
  colStart?: ResponsiveCellOptionValue<TwelveRange>;
  colFromEnd?: ResponsiveCellOptionValue<TwelveRange>;
  colSpan?: ResponsiveCellOptionValue<TwelveRange>;
  rowStart?: ResponsiveCellOptionValue<TwelveRange>;
  rowFromEnd?: ResponsiveCellOptionValue<TwelveRange>;
  rowSpan?: ResponsiveCellOptionValue<TwelveRange>;
}>;

export const Cell: FC<CellProps> = ({
  children,
  colStart,
  colFromEnd,
  colSpan,
  rowStart,
  rowFromEnd,
  rowSpan,
}) => {
  const getCellClass = (
    prefix: string,
    config?: ResponsiveCellOptionValue<TwelveRange>,
  ) => {
    return formatResponsiveCellClass(
      (value, responsiveKey) =>
        responsiveKey
          ? `${prefix}-${value}-${responsiveKey}`
          : `${prefix}-${value}`,
      config,
    );
  };

  return (
    <div
      className={classNames(
        "cell",
        ...getCellClass("is-col-start", colStart),
        ...getCellClass("is-col-from-end", colFromEnd),
        ...getCellClass("is-col-span", colSpan),
        ...getCellClass("is-row-start", rowStart),
        ...getCellClass("is-row-from-end", rowFromEnd),
        ...getCellClass("is-row-span", rowSpan),
      )}
    >
      {children}
    </div>
  );
};
