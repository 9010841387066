import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";
import "./Control.scss";

type ControlProps = PropsWithChildren<{
  isExpanded?: boolean;
  hasIconLeft?: boolean;
  hasIconRight?: boolean;
}>;

export const Control: FC<ControlProps> = ({
  isExpanded,
  hasIconLeft,
  hasIconRight,
  children,
}) => {
  return (
    <div
      className={classNames("control", {
        "is-expanded": isExpanded,
        "has-icon-left": hasIconLeft,
        "has-icon-right": hasIconRight,
      })}
    >
      {children}
    </div>
  );
};
