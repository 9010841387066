import React from "react";

import { Button, Icon, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./CancelSubscription.scss";
import { useModal } from "@components";

export const CancelSubscription = () => {
  const modals = useModal();

  return (
    <Stack direction="column" className="cancel-subscription-box">
      <Stack direction="column" align="center" gap="lg">
        <Stack direction="column" gap="xs" justify="center" align="center">
          <Icon
            icon="XCircle"
            size="large"
            weight="fill"
            className="has-text-danger"
          />
          <Title size={4}>Subscription Cancelled</Title>
        </Stack>
        <Text className="has-text-centered">
          Your subscription process has been cancelled. If you have any
          questions or need assistance, please contact our support team.
        </Text>
        <Stack direction="column" gap="xs">
          <Button
            color="primary"
            type="button"
            onClick={() => modals.open("subscription")}
          >
            Try Again
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
