import React, { FC, useState } from "react";

import { useDeleteUserTip } from "@api/user/deleteTip";

import { Modal } from "@ui/components";
import { Button, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import { toaster } from "../Toaster/Toaster";

type DeleteTipModalProps = {
  onClose: (closeAll?: boolean) => void;
  open: boolean;
  id: string;
};

export const DeleteTipModal: FC<DeleteTipModalProps> = ({
  onClose,
  open,
  id,
}) => {
  const [error, setError] = useState("");
  const { mutateAsync: deleteTipMutate, isLoading: deleteTipLoading } =
    useDeleteUserTip();

  const handleDelete = async () => {
    setError("");
    try {
      await deleteTipMutate({ id });

      toaster({
        title: "Successful removal!",
        message: "We've removed this tip from your portfolio.",
      });
      onClose(true);
    } catch (error) {
      if (error instanceof Error) {
        return setError(error.message);
      }

      setError(String(error));
    }
  };

  return (
    <Modal
      isActive={open}
      onClose={() => onClose(false)}
      header="Are you sure?"
      style={{ zIndex: 100 }}
      footer={
        <Stack justify="end" isFullwidth>
          <Button variant="light" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            variant="light"
            color="danger"
            onClick={handleDelete}
            isLoading={deleteTipLoading}
          >
            Yes, i&apos;m sure
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="xl">
          <Text>
            Please confirm that you wish to delete this tip from your portfolio.
          </Text>
        </Stack>
        {error && (
          <div className="notification is-danger is-light is-size-7">
            {error}
          </div>
        )}
      </Stack>
    </Modal>
  );
};
