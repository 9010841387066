import {
    MutationFunction,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
  } from "react-query";
  
  import { DefaultErrorResponse } from "@api/index";
  
  import { client } from "../client";
  
  type ValidateRecaptchaArgs = {
    token?: string;
  };
  
  export type ValidateRecaptchaResponse = {
    "success": boolean;
    "score": string | null;
    message: string;
    error?: string;
  };
  
  const mutateValidateRecaptcha: MutationFunction<ValidateRecaptchaResponse, ValidateRecaptchaArgs> = (args) => {
    return client
      .post("/validate-recaptcha", { token: args.token })
      .then((res) => res.data);
  };
  
  export const useValidateRecaptcha = (
    options?: UseMutationOptions<ValidateRecaptchaResponse, DefaultErrorResponse, ValidateRecaptchaArgs>,
  ): UseMutationResult<ValidateRecaptchaResponse, DefaultErrorResponse, ValidateRecaptchaArgs> => {
    return useMutation(mutateValidateRecaptcha, options);
  };
