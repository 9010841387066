import React, { FC, useMemo, useState } from "react";

import { formatProbability, formatTipName } from "@utils";

import { FixtureItem } from "@api/football/types/fixture";
import { TipItem, useGetTipAnalysis } from "@api/math-tips";

import { InfoBox, Modal, TeamsHeader, TeamsInfoItem } from "@ui/components";
import { Content, Icon, StatusBadge, Tag, Text } from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

type TipAnalysisProps = {
  fixture: FixtureItem;
  tip: TipItem;
  tagContent?: "rating" | "percentage";
};

export const TipRating: FC<TipAnalysisProps> = ({
  fixture,
  tip,
  tagContent = "rating",
}) => {
  const [tipAnalysisOpen, setTipAnalysisOpen] = useState(false);
  const { data: tipAnalysis, isLoading: tipAnalysisLoading } =
    useGetTipAnalysis({ id: tip.id }, { enabled: tipAnalysisOpen });

  const tagType: Colors = useMemo(() => {
    switch (tip.rating) {
      case "safe":
        return "success";
      case "value":
        return "info";
      case "risky":
        return "danger";
      default:
        return "dark";
    }
  }, [tip.rating]);

  return (
    <>
      <Tag
        onClick={() => setTipAnalysisOpen(true)}
        type={tagType}
        variant="light"
        size="medium"
        isClickable
      >
        {tagContent === "rating" && (
          <>
            <span>{tip.rating}</span>
            <Icon icon="Info" />
          </>
        )}
        {tagContent === "percentage" && (
          <>
            <span>{formatProbability(tip.probability)}</span>
            <Icon icon="Info" />
          </>
        )}
      </Tag>
      <Modal
        isActive={tipAnalysisOpen}
        header={
          <TeamsHeader
            id={fixture?.fixture.id}
            homeLogo={fixture?.teams.home.logo}
            awayLogo={fixture?.teams.away.logo}
            title={
              <>
                <Stack align="center">
                  <Text>{formatTipName(tip.category, tip.name)}</Text>{" "}
                  <Text variant="secondary">{tip.probability}%</Text>
                </Stack>
              </>
            }
            subtitle={
              <TeamsInfoItem
                label={`${fixture?.teams.home.name} vs ${fixture?.teams.away.name}`}
              />
            }
          />
        }
        onClose={() => setTipAnalysisOpen(false)}
      >
        {tipAnalysisLoading && (
          <div className="skeleton-lines">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {!tipAnalysisLoading && !tipAnalysis && (
          <InfoBox
            title="Ooops ..."
            secondaryButtonAction={() => setTipAnalysisOpen(false)}
            secondaryButtonLabel="Close"
          >
            Seems like this tip has no AI reasoning.
            <br />
            Please try again later.
          </InfoBox>
        )}
        {tipAnalysis && (
          <Stack direction="column" gap="xl">
            <Content noMargin>
              <p>{tipAnalysis?.reasoning}</p>
            </Content>
            {!!tipAnalysis?.risks.length && (
              <Stack direction="column" gap="lg">
                {tipAnalysis?.risks.map((risk) => {
                  const badgeType: Colors = (() => {
                    switch (risk.level) {
                      case "Moderate":
                        return "warning";
                      case "Low":
                        return "success";
                      case "High":
                        return "danger";
                    }
                  })();

                  return (
                    <Stack
                      direction="column"
                      key={`risk-item-${risk.title}`}
                      gap="xs"
                    >
                      <Stack align="center">
                        <StatusBadge type={badgeType} />
                        <Stack direction="column" gap="none" align="start">
                          <Text isNarrow>{risk.title}</Text>
                          <Text size="small" variant="secondary">
                            {risk.level} risk
                          </Text>
                        </Stack>
                      </Stack>
                      <Text>{risk.reasoning}</Text>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        )}
      </Modal>
    </>
  );
};
