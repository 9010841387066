import { fetchAuthSession } from "@aws-amplify/auth";
import axios, { AxiosError, AxiosInstance } from "axios";

export type DefaultErrorResponse = AxiosError<{
  message: string;
  error: string;
}>;

export const createClient = (path: string): AxiosInstance => {
  const client = axios.create({
    baseURL: `${process.env.REACT_APP_FOOTBALL_API_URL}/${path}`,
    headers: {
      "Content-Type": "application/json",
    },
  });

  client.interceptors.request.use(
    async (config) => {
      const session = await fetchAuthSession();

      config.headers.Authorization = session.tokens?.idToken?.toString();
      return config;
    },
    (error) => {
      window.location.href = "/login";
      return Promise.reject(error);
    },
  );

  return client;
};
