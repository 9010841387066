import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { FixtureItem } from "@api/football/types/fixture";
import { DefaultErrorResponse } from "@api/index";
import { League, TipItem, TipsQueryArgs } from "@api/math-tips";
import {
  getTipsFilterQueryArgs,
  parseTipsFilterParams,
} from "@api/math-tips/utils";

import { client } from "../client";

export type TipsResponseItem = {
  fixture: FixtureItem;
  tips: TipItem[];
};

type GetTipsResponse = {
  page: string;
  limit: string;
  total_items: string;
  total_tips: string;
  total_pages: string;
  available_leagues: League[];
  data: TipsResponseItem[];
};

export const fetchTips: QueryFunction<GetTipsResponse> = ({ queryKey }) => {
  const { params } = parseTipsFilterParams(queryKey);

  return client
    .get("/tips", {
      params,
    })
    .then((res) => res.data);
};

export const GET_TIPS_QUERY_KEY = "get-tips";

export const useGetTips = (
  args?: TipsQueryArgs,
  options?: Omit<
    UseQueryOptions<GetTipsResponse, DefaultErrorResponse>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<GetTipsResponse, DefaultErrorResponse> => {
  return useQuery(
    [GET_TIPS_QUERY_KEY, ...getTipsFilterQueryArgs(args)],
    fetchTips,
    options,
  );
};
