import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateLineupsResponse = {
  id: number;
  success: boolean;
};

type CreateLineupsArgs = {
  id: string | number;
};

export const createLineups: MutationFunction<
  CreateLineupsResponse,
  CreateLineupsArgs
> = (props) => {
  return client.post(`/lineups`, { id: props.id }).then((res) => res.data);
};

export const CreateLineupsMutationKey = "create-lineups";

export const useCreateLineups = (
  params?: CreateLineupsArgs,
  options?: Omit<
    UseMutationOptions<
      CreateLineupsResponse,
      DefaultErrorResponse,
      CreateLineupsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateLineupsResponse,
  DefaultErrorResponse,
  CreateLineupsArgs
> => {
  return useMutation(
    [CreateLineupsMutationKey, params?.id],
    createLineups,
    options,
  );
};
