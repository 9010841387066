import React, { FC } from "react";

import { useQuery } from "react-query";

import { FixtureItem } from "@api/football/types/fixture";
import { fetchStatistics } from "@api/statistics/fetchStatistics";

import { InfoBox, TabItem, TabList, TabPanel, Tabs } from "@ui/components";
import { Icon, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import { StatisticsOverview } from "./StatisticsOverview/StatisticsOverview";

import "./RecentStatistics.scss";

type RecentStatisticsProps = {
  fixture: FixtureItem;
};

export const RecentStatistics: FC<RecentStatisticsProps> = ({ fixture }) => {
  const { data: statisticsData, isLoading: statisticsLoading } = useQuery(
    ["statistics", fixture.fixture.id],
    fetchStatistics,
  );

  const noData =
    Object.keys(statisticsData?.summary.average_statistics.home.total || {})
      ?.length === 0;

  if (!statisticsLoading && noData) {
    return (
      <Stack direction="column" gap="md" isFullwidth>
        <Stack direction="column" gap="none">
          <Title size={5} hasIcon>
            <Icon icon="TrendUp" />
            <span>Recent Statistics</span>
          </Title>
          <Text variant="secondary">From last 10 fixtures</Text>
        </Stack>
        <InfoBox hasSpacing icon="SmileyXEyes">
          Failed to fetch Recent Statistics ... <br />
          Please try again later.
        </InfoBox>
      </Stack>
    );
  }

  if (!statisticsData?.summary || statisticsLoading)
    return (
      <Stack direction="column" gap="md" isFullwidth>
        <Stack direction="column" gap="none">
          <Title size={5} hasIcon>
            <Icon icon="TrendUp" />
            <span>Recent Statistics</span>
          </Title>
          <Text variant="secondary">From last 10 fixtures</Text>
        </Stack>
        <div className="skeleton-block" style={{ height: 232 }} />
      </Stack>
    );

  const hasH2H = !!Object.keys(
    statisticsData.summary.average_statistics.h2h.home,
  ).length;

  return (
    <Stack direction="column" gap="md" isFullwidth>
      <Stack direction="column" gap="none">
        <Title size={5} hasIcon>
          <Icon icon="TrendUp" />
          <span>Recent Statistics</span>
        </Title>
        <Text variant="secondary">From last 10 fixtures</Text>
      </Stack>
      <Tabs active="total">
        <Stack direction="column" isFullwidth gap="xl">
          <TabList type="toggle" isFullwidth>
            <TabItem tab="total">Total</TabItem>
            {hasH2H && <TabItem tab="head-to-head">Head to Head</TabItem>}
            <TabItem tab="home-vs-away">Home vs Away</TabItem>
          </TabList>

          <TabPanel tab="total">
            <StatisticsOverview
              id="total"
              home={statisticsData.summary.average_statistics.home.total}
              away={statisticsData.summary.average_statistics.away.total}
            />
          </TabPanel>

          {hasH2H && (
            <TabPanel tab="head-to-head">
              <StatisticsOverview
                id="h2h"
                home={statisticsData.summary.average_statistics.h2h.home}
                away={statisticsData.summary.average_statistics.h2h.away}
              />
            </TabPanel>
          )}

          <TabPanel tab="home-vs-away">
            <StatisticsOverview
              id="home-away"
              home={statisticsData.summary.average_statistics.home.played_home}
              away={statisticsData.summary.average_statistics.away.played_away}
            />
          </TabPanel>
        </Stack>
      </Tabs>
    </Stack>
  );
};
