import React, { FC } from "react";

import dayjs from "dayjs";
import {
  DateRange as ReactDateRange,
  DateRangePickerProps,
} from "react-date-range";

import "./DatePicker.scss";
import { Button, Icon, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

export type DateRange = {
  startDate?: string;
  endDate?: string;
};

type DatePickerProps = {
  value?: DateRange;
  onChange?: (value: DateRange) => void;
} & Omit<DateRangePickerProps, "value" | "onChange">;

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <ReactDateRange
      ranges={[
        {
          startDate: dayjs(value?.startDate).toDate(),
          endDate: dayjs(value?.endDate).toDate(),
          key: "selection",
          color: "var(--bulma-text)",
        },
      ]}
      showMonthAndYearPickers={false}
      showDateDisplay={false}
      navigatorRenderer={(currFocusedDate, changeShownDate) => (
        <Stack justify="between" align="center">
          <Button
            variant="white"
            onClick={() =>
              changeShownDate(currFocusedDate.getMonth() - 1, "setMonth")
            }
          >
            <Icon icon="CaretLeft" />
          </Button>
          <Text>{dayjs(currFocusedDate).format("MMMM")}</Text>
          <Button
            variant="white"
            onClick={() =>
              changeShownDate(currFocusedDate.getMonth() + 1, "setMonth")
            }
          >
            <Icon icon="CaretRight" />
          </Button>
        </Stack>
      )}
      weekdayDisplayFormat="EEEEE"
      weekStartsOn={1}
      onChange={({ selection }) =>
        onChange?.({
          startDate: dayjs(selection.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(selection.endDate).format("YYYY-MM-DD"),
        })
      }
      {...props}
    />
  );
};

DatePicker.displayName = "DatePicker";
