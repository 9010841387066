import React, { PropsWithChildren } from "react";

import { toPng } from "html-to-image";

import { Button } from "@ui/elements";

type ScreenshotButtonProps = PropsWithChildren<{
  elementRef: React.RefObject<HTMLElement>;
  filename?: string;
}>;

export const ScreenshotButton: React.FC<ScreenshotButtonProps> = ({
  elementRef,
  filename = "screenshot.png",
  children,
}) => {
  const handleDownload = () => {
    if (!elementRef.current) return;

    toPng(elementRef.current, { cacheBust: true, pixelRatio: 2 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = filename;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error("Failed to capture screenshot", err);
      });
  };

  return <Button onClick={handleDownload}>{children}</Button>;
};
