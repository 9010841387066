import React, {
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";

import "./Switch.scss";
import classNames from "classnames";

import { Colors } from "@ui/types";

type CheckboxProps = PropsWithChildren<{
  type?: Colors;
  isCentered?: boolean;
  size?: "small" | "normal" | "medium" | "large";
  isRounded?: boolean;
}> &
  Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

export const Switch = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      isCentered = true,
      size,
      name,
      children,
      onChange,
      type = "text",
      isRounded = true,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className="switch-field" tabIndex={-1}>
        <input
          id={name}
          name={name}
          className={classNames([
            "switch",
            {
              [`is-${type}`]: type,
              "is-centered": isCentered,
              "is-rounded": isRounded,
              [`is-${size}`]: size,
            },
          ])}
          type="checkbox"
          onChange={onChange}
          ref={ref}
          {...rest}
        />
        <label htmlFor={name}>{children}</label>
      </div>
    );
  },
);

Switch.displayName = "Checkbox";
