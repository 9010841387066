import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";
import "./Field.scss";

type ControlProps = PropsWithChildren<{
  hasAddons?: boolean;
  addonsCentered?: boolean;
  addonsRight?: boolean;
  isGrouped?: boolean;
  groupedCentered?: boolean;
  groupedRight?: boolean;
  groupedMultiline?: boolean;
  isHorizontal?: boolean;
}>;

export const Field: FC<ControlProps> = ({
  hasAddons,
  addonsCentered,
  addonsRight,
  isGrouped,
  groupedCentered,
  groupedRight,
  groupedMultiline,
  isHorizontal,
  children,
}) => {
  return (
    <div
      className={classNames("field", {
        "has-addons": hasAddons,
        "has-addons-centered": addonsCentered,
        "has-addons-right": addonsRight,
        "is-grouped": isGrouped,
        "is-grouped-centered": groupedCentered,
        "is-grouped-right": groupedRight,
        "is-grouped-multiline": groupedMultiline,
        "is-horizontal": isHorizontal,
      })}
    >
      {children}
    </div>
  );
};
