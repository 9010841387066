import React, { FC } from "react";

import classNames from "classnames";

import { Colors } from "@ui/types";
import "./ProgressBar.scss";

type ProgressBarDirection = "ltr" | "rtl";

type ProgressBarProps =
  | {
      direction?: ProgressBarDirection;
      isLoading?: boolean;
      type?: Colors;
      value: number;
    }
  | {
      type?: Array<Colors>;
      isLoading?: boolean;
      value: number[];
    };

export const ProgressBar: FC<ProgressBarProps> = ({
  type = "text",
  value,
  isLoading,
  ...rest
}) => {
  const isMulti = Array.isArray(value);

  return isMulti ? (
    <div className="multi-progress-bar">
      <div
        className={classNames("progress-bar", "is-rtl", {
          "has-skeleton": isLoading,
        })}
      >
        <div
          style={{ width: `${value[0]}%` }}
          className={classNames({
            [`has-background-${type[0]}-80`]: type[0],
          })}
        />
      </div>
      <div
        className={classNames("progress-bar", "is-ltl", {
          "has-skeleton": isLoading,
        })}
      >
        <div
          style={{ width: `${value[1]}%` }}
          className={classNames({
            [`has-background-${type[1]}-80`]: type[1],
          })}
        />
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        "progress-bar",
        { "has-skeleton": isLoading },
        `is-${"direction" in rest ? rest.direction : "is-ltr"}`,
      )}
    >
      <div
        style={{ width: `${value}%` }}
        className={classNames({
          [`has-background-${type}-80`]: type && !isLoading,
        })}
      />
    </div>
  );
};
