import React, { InputHTMLAttributes, forwardRef } from "react";

import classNames from "classnames";

import { Icons } from "@ui/elements";
import { Label } from "@ui/forms";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import "./Input.scss";

type InputProps = {
  label?: string;
  error?: string;
  variant?: Colors | "grey";
  isFullwidth?: boolean;
  leftIcon?: Icons;
  rightIcon?: Icons;
  size?: "small" | "normal" | "medium" | "large";
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      leftIcon,
      rightIcon,
      variant,
      isFullwidth,
      label,
      error,
      type = "text",
      value,
      size = "normal",
      ...rest
    }: InputProps,
    ref,
  ) => {
    return (
      <Stack direction="column" gap="xxs" isFullwidth={isFullwidth}>
        {label && <Label>{label}</Label>}
        <Stack direction="column" gap="none">
          <input
            type={type}
            value={value}
            {...rest}
            className={classNames(
              "input",
              {
                [`is-${variant}`]: variant,
                "is-danger": error,
                [`is-${size}`]: size,
              },
              rest.className,
            )}
            ref={ref}
          />
          {error && <p className="help is-danger">{error}</p>}
        </Stack>
      </Stack>
    );
  },
);

Input.displayName = "Input";
