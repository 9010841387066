import React, {
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";

import "./Checkbox.scss";
import classNames from "classnames";

import { Icon } from "@ui/elements";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

type CheckboxProps = PropsWithChildren<{
  type?: Colors;
  error?: string;
  isCentered?: boolean;
}> &
  InputHTMLAttributes<HTMLInputElement>;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { isCentered = true, name, error, children, onChange, type, ...rest },
    ref,
  ) => {
    return (
      <Stack direction="column" gap="none">
        <div
          className={classNames("checkbox", {
            [`is-${type}`]: type && !error,
            "is-centered": isCentered,
            "is-danger": error,
          })}
          tabIndex={-1}
        >
          <input
            id={name}
            name={name}
            type="checkbox"
            onChange={onChange}
            ref={ref}
            {...rest}
          />
          <div className="checkbox-toggle">
            <Icon icon="Check" size="small" />
          </div>
          <label htmlFor={name}>{children}</label>
        </div>
        {error && <p className="help is-danger">{error}</p>}
      </Stack>
    );
  },
);

Checkbox.displayName = "Checkbox";
