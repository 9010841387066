import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateStatisticsResponse = {
  id: number;
  success: boolean;
};

type CreateStatisticsArgs = {
  id: string | number;
};

export const createStatistics: MutationFunction<
  CreateStatisticsResponse,
  CreateStatisticsArgs
> = (props) => {
  return client.post(`/statistics`, { id: props.id }).then((res) => res.data);
};

export const CreateStatisticsMutationKey = "create-statistics";

export const useCreateStatistics = (
  params?: CreateStatisticsArgs,
  options?: Omit<
    UseMutationOptions<
      CreateStatisticsResponse,
      DefaultErrorResponse,
      CreateStatisticsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateStatisticsResponse,
  DefaultErrorResponse,
  CreateStatisticsArgs
> => {
  return useMutation(
    [CreateStatisticsMutationKey, params?.id],
    createStatistics,
    options,
  );
};
