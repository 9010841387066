import React from "react";

import { Column, Columns } from "@ui/layout";

import { AIPerformancePanel } from "./Panels/AIPerformancePanel/AIPerformancePanel";
import { MyBetsPanel } from "./Panels/MyBetsPanel/MyBetsPanel";
import { UpcomingTipsPanel } from "./Panels/UpcomingTipsPanel/UpcomingTipsPanel";
import "./Dashboard.scss";

export const Dashboard = () => {
  return (
    <Columns isMultiline className="dashboard-columns">
      <Column size={12}>
        <MyBetsPanel />
      </Column>
      <Column tablet={7} desktop={12} widescreen={7}>
        <UpcomingTipsPanel />
      </Column>
      <Column tablet={5} desktop={12} widescreen={5}>
        <AIPerformancePanel />
      </Column>
    </Columns>
  );
};
