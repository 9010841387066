import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type DeleteStatisticsResponse = {
  error: string | null;
  success: boolean;
};

type DeleteStatisticsArgs = {
  id: string | number;
};

export const deleteStatistics: MutationFunction<
  DeleteStatisticsResponse,
  DeleteStatisticsArgs
> = (props) => {
  return client
    .delete(`/statistics`, { data: { id: props.id } })
    .then((res) => res.data);
};

export const DeleteStatisticsMutationKey = "delete-statistics";

export const useDeleteStatistics = (
  params?: DeleteStatisticsArgs,
  options?: Omit<
    UseMutationOptions<
      DeleteStatisticsResponse,
      DefaultErrorResponse,
      DeleteStatisticsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  DeleteStatisticsResponse,
  DefaultErrorResponse,
  DeleteStatisticsArgs
> => {
  return useMutation(
    [DeleteStatisticsMutationKey, params?.id],
    deleteStatistics,
    options,
  );
};
