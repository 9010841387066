import React, {
  InputHTMLAttributes,
  forwardRef,
  ChangeEvent,
  useState,
  useEffect,
} from "react";

import classNames from "classnames";
import ReactCurrencyInputField from "react-currency-input-field";

import { getLocaleOptionConfig } from "@utils";

import { Currency } from "@api/user/client";

import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import "./CurrencyInput.scss";

type InputProps = {
  label?: string;
  error?: string;
  variant?: Colors | "grey";
  currency?: Currency;
  size?: "small" | "normal" | "medium" | "large";
  value?: InputHTMLAttributes<HTMLInputElement>["value"] | null;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "value">;

export const CurrencyInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      variant,
      label,
      error,
      value,
      step,
      size,
      className,
      defaultValue,
      currency,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState<string | undefined>(
      value ? String(value) : undefined,
    );

    useEffect(() => {
      setInternalValue(value ? String(value) : undefined);
    }, [value]);

    return (
      <Stack direction="column" gap="none">
        <ReactCurrencyInputField
          className={classNames(
            "input",
            `is-${size}`,
            { [`is-${variant}`]: !!variant, "is-danger": error },
            className,
          )}
          defaultValue={internalValue}
          value={internalValue}
          onValueChange={(value, name, values) => {
            setInternalValue(value);
            onChange?.({
              target: { value: values?.float ? String(values?.float) : 0 },
            } as ChangeEvent<HTMLInputElement>);
          }}
          intlConfig={{
            locale: currency?.locale || "de-DE",
            currency: currency?.code || "EUR",
          }}
          decimalsLimit={2}
          {...getLocaleOptionConfig(currency?.locale || "de-DE")}
          step={Number(step)}
          ref={ref}
          {...rest}
        />
        {error && <p className="help is-danger">{error}</p>}
      </Stack>
    );
  },
);

CurrencyInput.displayName = "CurrencyInput";
