import { createClient } from "../index";

export type Currency = {
  code: string;
  name: string;
  symbol: string;
  locale: string;
};

export const client = createClient("user");
