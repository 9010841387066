import React, { FC, forwardRef, TextareaHTMLAttributes } from "react";

import classNames from "classnames";
import { FieldError } from "react-hook-form";

import { Icons } from "@ui/elements";
import { Label } from "@ui/forms";
import { Stack } from "@ui/layout";
import { Colors } from "@ui/types";

import "./Textarea.scss";

type TextareaProps = {
  label?: string;
  error?: FieldError;
  variant?: Colors | "grey";
  isFullwidth?: boolean;
  leftIcon?: Icons;
  rightIcon?: Icons;
  size?: "small" | "normal" | "medium" | "large";
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "size">;

export const Textarea: FC<TextareaProps> = forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(
  (
    {
      leftIcon,
      rightIcon,
      variant,
      isFullwidth,
      label,
      error,
      value,
      size = "normal",
      ...rest
    },
    ref,
  ) => {
    return (
      <Stack direction="column" gap="xxs" isFullwidth={isFullwidth}>
        {label && <Label>{label}</Label>}
        <Stack direction="column" gap="none">
          <textarea
            value={value}
            {...rest}
            className={classNames(
              "textarea",
              {
                [`is-${variant}`]: variant,
                "is-danger": error,
                [`is-${size}`]: size,
              },
              rest.className,
            )}
            ref={ref}
          />
          {error && <p className="help is-danger">{error.message}</p>}
        </Stack>
      </Stack>
    );
  },
);

Textarea.displayName = "Textarea";
