import React, { useEffect } from "react";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { useGetEmailPreferences } from "@api/cognito/getEmailPreferences";
import {
  UpdateEmailPreferencesArgs,
  useUpdateEmailPreferences,
} from "@api/cognito/updateEmailPreferences";
import { useValidateRecaptcha } from "@api/cognito/validateRecaptcha";

import { Button, Notification, Text, Title } from "@ui/elements";
import { Checkbox } from "@ui/forms";
import { Stack } from "@ui/layout";

import { toaster } from "@components";

type UpdateEmailPreferencesFormValues = UpdateEmailPreferencesArgs;

export const UpdateEmailPreferences = () => {
  const [searchParams] = useSearchParams();
  const {
    mutateAsync: updateEmailPreferences,
    error,
    isLoading: updateEmailPreferencesLoading,
  } = useUpdateEmailPreferences();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    mutateAsync: validateRecaptcha,
    isLoading: recaptchaLoading,
    data: recaptchaResponseData,
  } = useValidateRecaptcha();
  const token = searchParams.get("token") || "";
  const { data: emailPreferences, isLoading: emailPreferencesLoading } =
    useGetEmailPreferences(
      { token },
      {
        enabled: !!token,
      },
    );

  const form = useForm<UpdateEmailPreferencesFormValues>({
    defaultValues: {
      token: searchParams.get("token") || "",
      platformUpdates: emailPreferences?.email_preferences.platformUpdates,
      feedbackSurvey: emailPreferences?.email_preferences.feedbackSurvey,
      marketingEmails: emailPreferences?.email_preferences.marketingEmails,
      newsLetters: emailPreferences?.email_preferences.newsLetters,
    },
  });

  useEffect(() => {
    if (emailPreferences?.email_preferences) {
      form.setValue(
        "platformUpdates",
        emailPreferences.email_preferences.platformUpdates,
      );
      form.setValue(
        "marketingEmails",
        emailPreferences.email_preferences.marketingEmails,
      );
      form.setValue(
        "newsLetters",
        emailPreferences.email_preferences.newsLetters,
      );
      form.setValue(
        "feedbackSurvey",
        emailPreferences.email_preferences.feedbackSurvey,
      );
    }
  }, [emailPreferences]);

  const update = async (values: UpdateEmailPreferencesFormValues) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("update_email_preferences");
    const recaptchaResponse = await validateRecaptcha({ token });

    if (!recaptchaResponse.success) {
      console.log("Failed to validate reCAPTCHA. Please try again.");
      return;
    }

    const response = await updateEmailPreferences(values);

    if (!response.error) {
      toaster.success({
        title: "E-mail preferences updated!",
        message: "Your email preferences have been successfully updated.",
      });
    }
  };

  const onSubmit = async (values: UpdateEmailPreferencesFormValues) => {
    update(values);
  };

  const handleUnsubscribeFromAll = () => {
    update({
      token: searchParams.get("token") || "",
      platformUpdates: false,
      feedbackSurvey: false,
      marketingEmails: false,
      newsLetters: false,
    });
  };
  const hasError = !!error?.response?.data.error;

  const loading = recaptchaLoading || updateEmailPreferencesLoading;

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Stack direction="column" gap="xl">
        <Title size={5}>Update Your Email Preferences</Title>

        <Stack direction="column">
          <Checkbox
            isCentered={false}
            disabled={emailPreferencesLoading}
            {...form.register("platformUpdates")}
          >
            <Text>Platform Updates</Text>
            <Text size="small">
              Receive updates about new features, downtimes, maintenance
              windows, or other important information regarding the platform.
            </Text>
          </Checkbox>
          <Checkbox
            isCentered={false}
            disabled={emailPreferencesLoading}
            {...form.register("feedbackSurvey")}
          >
            <Text>Feedback and Surveys</Text>
            <Text size="small">
              Participate in feedback requests and surveys to help us improve
              our services and products.
            </Text>
          </Checkbox>
          <Checkbox
            isCentered={false}
            disabled={emailPreferencesLoading}
            {...form.register("marketingEmails")}
          >
            <Text>Marketing Emails</Text>
            <Text size="small">
              Receive promotional content such as special offers, new product
              announcements, and sales.
            </Text>
          </Checkbox>
          <Checkbox
            isCentered={false}
            disabled={emailPreferencesLoading}
            {...form.register("newsLetters")}
          >
            <Text>Newsletters</Text>
            <Text size="small">
              Get regularly scheduled emails with valuable content, including
              blog posts, industry news, tips, and user stories.
            </Text>
          </Checkbox>
        </Stack>

        <Stack direction="column">
          {hasError && (
            <Notification color="danger">
              <Stack direction="column" gap="lg">
                <Stack direction="column" gap="xxs">
                  <Text weight="bold">{error.response?.data.message}</Text>
                  <Text>
                    {error.response?.data.error ||
                      "An unexpected error has occurred."}
                  </Text>
                </Stack>

                <Text>
                  Please try again later. If the issue persists, contact us at{" "}
                  <a href="mailto:info@football-genie.com">
                    info@football-genie.com
                  </a>
                  .
                </Text>
              </Stack>
            </Notification>
          )}
          {!!recaptchaResponseData?.error && (
            <Notification color="danger">
              <Text>{recaptchaResponseData.error}</Text>
            </Notification>
          )}
          <Stack direction="column" gap="xs">
            <Button color="primary" isLoading={loading}>
              Update Email Preferences
            </Button>
            <Button
              variant="text"
              type="button"
              disabled={loading}
              onClick={() => handleUnsubscribeFromAll()}
            >
              Unsubscribe from All
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};
