import React, { FC, PropsWithChildren } from "react";

import classNames from "classnames";

import { Button, Icon, Icons, Text, Title } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./InfoBox.scss";

type InfoBoxProps = PropsWithChildren<{
  title?: string;
  icon?: Icons;
  hasSpacing?: boolean;
  primaryButtonLabel?: string;
  primaryButtonAction?: () => void;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
}>;

export const InfoBox: FC<InfoBoxProps> = ({
  title,
  icon,
  children,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
  hasSpacing,
}) => {
  return (
    <Stack
      className={classNames("info-box", { "has-spacing": hasSpacing })}
      direction="column"
      align="center"
      gap="lg"
    >
      <Stack direction="column" gap="xs" justify="center" align="center">
        {icon && <Icon icon={icon} size="large" />}
        {title && <Title size={4}>{title}</Title>}
      </Stack>
      <Text align="centered" className="has-text-centered">
        {children}
      </Text>
      {(primaryButtonLabel || secondaryButtonLabel) && (
        <Stack direction="column" gap="xs">
          {primaryButtonLabel && (
            <Button color="primary" type="button" onClick={primaryButtonAction}>
              {primaryButtonLabel}
            </Button>
          )}
          {secondaryButtonLabel && (
            <Button variant="ghost" onClick={secondaryButtonAction}>
              {secondaryButtonLabel}
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};
