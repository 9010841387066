import {
  MutateFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";

import { GET_USER_TIPS_QUERY_KEY } from "@api/user/getTips";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type DeleteUserTipsResponse = {
  error?: string;
  message?: string;
};

const deleteTip: MutateFunction<
  DeleteUserTipsResponse,
  DefaultErrorResponse
> = () => {
  return client.delete("tips").then((res) => res.data);
};

export const DELETE_USER_TIPS_QUERY_KEY = "delete-user-tips";

export const useDeleteUserTips = (
  options?: Omit<
    UseMutationOptions<DeleteUserTipsResponse, DefaultErrorResponse, void>,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<DeleteUserTipsResponse, DefaultErrorResponse, void> => {
  const queryClient = useQueryClient();

  return useMutation([DELETE_USER_TIPS_QUERY_KEY], deleteTip, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries({
        queryKey: [GET_USER_TIPS_QUERY_KEY],
        refetchInactive: true,
      });
    },
  });
};
