import React, { FC, useContext, useState } from "react";

import { faMinus, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { withController } from "@utils";
import { copyToClipboard } from "@utils";

import { useGetExpertOpinions } from "@api/risk-analysis/getExpertOpinions";

import { ImageComponent } from "@ui/elements";
import { Textarea } from "@ui/forms";

import { StepComponentCommonProps } from "../../StepComponent/StepComponent";
import {
  TipValidatorContext,
  TipValidatorFormFields,
  ValidatorSteps,
} from "../TipValidator";

const ControlledTextarea = withController<TipValidatorFormFields>()(Textarea);

export const NewsSelector: FC<StepComponentCommonProps<ValidatorSteps>> = ({
  goToStep,
  active,
}) => {
  const {
    form: { watch, setValue, control },
  } = useContext(TipValidatorContext);

  const fixtureId = watch("id");
  const selectedArticles = watch("selectedArticles") || [];
  const [customNewsEnabled, setCustomNewsEnabled] = useState(false);
  const { data: newsData, isLoading: newsLoading } = useGetExpertOpinions(
    fixtureId,
    {
      enabled: !!fixtureId && active,
    },
  );

  const addArticle = (link: string) => {
    setValue("selectedArticles", [...selectedArticles, link]);
  };

  const removeArticle = (link: string) => {
    const newArticles = selectedArticles.filter((e) => e !== link);
    setValue("selectedArticles", newArticles);
  };

  if (!fixtureId) return null;

  return (
    <div>
      {newsData?.error && (
        <div className="notification is-danger">{newsData?.error}</div>
      )}
      {newsLoading && (
        <div className="is-size-3 has-text-grey-light">
          <span className="icon">
            <FontAwesomeIcon icon={faSpinner} pulse={true} />
          </span>
        </div>
      )}
      <div className="columns is-multiline">
        {newsData?.results.map((item) => {
          const selected = selectedArticles.includes(item.search_result.link);

          return (
            <div key={item.search_result.link} className="column is-3">
              <div
                className={classNames([
                  "card article-card",
                  { "is-active": selected },
                ])}
              >
                <div className="card-image">
                  <ImageComponent
                    src={item.search_result.pagemap.metatags[0]["og:image"]}
                    ratio="16by9"
                  />
                </div>
                <div className="card-content">
                  <p className="title is-6">{item.search_result.title}</p>
                  <p className="subtitle is-7">{item.page}</p>

                  <div
                    className="content is-small"
                    dangerouslySetInnerHTML={{
                      __html: item.search_result.htmlSnippet,
                    }}
                  />
                </div>
                <footer className="card-footer is-size-7">
                  <a
                    onClick={() => copyToClipboard(item.content)}
                    className="card-footer-item"
                  >
                    Copy
                  </a>
                  <a
                    href={item.search_result.link}
                    target="_blank"
                    className="card-footer-item"
                    rel="noreferrer"
                  >
                    Visit
                  </a>
                  <a
                    className="card-footer-item"
                    onClick={() =>
                      selected
                        ? removeArticle(item.search_result.link)
                        : addArticle(item.search_result.link)
                    }
                  >
                    {selected ? "Remove" : "Select"}
                  </a>
                </footer>
              </div>
            </div>
          );
        })}
      </div>
      <a
        className="mb-2 is-flex is-align-items-center"
        onClick={() => setCustomNewsEnabled(!customNewsEnabled)}
      >
        <span className="icon">
          <FontAwesomeIcon icon={!customNewsEnabled ? faPlus : faMinus} />
        </span>
        <span>Custom News</span>
      </a>
      <div className="field">
        {customNewsEnabled && (
          <ControlledTextarea
            className="custom-news-textarea"
            name="expertOpinion"
            rows={10}
            control={control}
          />
        )}
      </div>
      {active && !newsLoading && (
        <>
          <div className="field">
            <div className="control">
              <button
                type="button"
                className="button is-secondary"
                onClick={() => {
                  goToStep("options");
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
