import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

export type CustomTipItem = {
  tip: string; // The betting tip
  confidence: number; // Your confidence level, from 1 to 10
  rationale: string; // The reasoning behind your choice
  bet_amount: number; // Suggested bet amount as a percentage of the bankroll, from 1 to 5
};

type CreateCustomPredictionResponse = {
  tips: CustomTipItem[];
  error: string | null;
};

type CreateCustomPredictionArgs = {
  id: string | number;
  expert_opinion?: string;
};

export const createCustomPrediction: MutationFunction<
  CreateCustomPredictionResponse,
  CreateCustomPredictionArgs
> = (props) => {
  return client
    .post(`/create-custom-prediction`, {
      id: props.id,
      expert_opinion: props.expert_opinion,
    })
    .then((res) => res.data);
};

export const CreateCustomPredictionMutationKey = "get-math-prediction";

export const useCreateCustomPrediction = (
  params?: CreateCustomPredictionArgs,
  options?: Omit<
    UseMutationOptions<
      CreateCustomPredictionResponse,
      DefaultErrorResponse,
      CreateCustomPredictionArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateCustomPredictionResponse,
  DefaultErrorResponse,
  CreateCustomPredictionArgs
> => {
  return useMutation(
    [CreateCustomPredictionMutationKey, params?.id, params?.expert_opinion],
    createCustomPrediction,
    options,
  );
};
