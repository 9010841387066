import React, { FC, useContext } from "react";

import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { toast } from "react-toastify";

import { copyToClipboard } from "../../../../../utils/copyToClipboard";
import { StepComponentCommonProps } from "../../StepComponent/StepComponent";
import { CustomTipCard } from "../CustomTipCard";
import { TipValidatorContext, ValidatorSteps } from "../TipValidator";



export const Generate: FC<StepComponentCommonProps<ValidatorSteps>> = ({
  active,
}) => {
  const { tips, setTips, isLoading } = useContext(TipValidatorContext);

  const createPrompt = async () => {
    let outputString = `
      Act as my personal bookmaker expert. I have collected predictions from different sources, and i want you to use them and create a highly reliable safe combination bet ticket for me.
      Understand the general risks involved in each bet category, modify them to make it as safe as possible.
      Be very cautious with the sources, try to add thresholds, modify or enhance tips if you feel not confident about them.
      Your final answer should be a combination bet ticket with the tips listed. Give an overall predictability percentage from 0 to 100%.
      Important that you craft a safe bet independently, while still considering the insights from the sources.
      
      
    `;

    tips.forEach((tip, index) => {
      outputString += `Source ${index + 1} says:\n`;

      tip.forEach((item) => {
        outputString += `- ${item.tip} with confidence of ${item.confidence}/10 because: ${item.rationale}\n`;
      });

      outputString += "\n";
    });

    await copyToClipboard(outputString);

    toast("Copied to clipboard!", {
      type: "success",
    });
  };

  if (!active) return null;

  return (
    <div>
      <button
        type="submit"
        className={classNames([
          "button is-primary",
          { "is-loading": isLoading },
        ])}
      >
        Generate
      </button>
      <div className="columns is-multiline">
        {tips.map((items, index) => {
          return (
            <div
              key={`tip-group-${index}`}
              className="column is-4-tablet is-3-desktop"
            >
              <CustomTipCard items={items} index={index + 1} />
            </div>
          );
        })}
      </div>

      {!!tips.length && (
        <div className="buttons">
          <div
            className="button is-danger is-outlined"
            onClick={() => setTips([])}
          >
            Reset
          </div>
          <div className="button is-secondary" onClick={createPrompt}>
            <span className="icon">
              <FontAwesomeIcon icon={faCopy} />
            </span>
            <span>Copy prompt</span>
          </div>
        </div>
      )}
    </div>
  );
};
