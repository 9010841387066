export const formatRoi = (value?: string | number) => {
  const numValue = Number(value);

  if (isNaN(numValue) || value === null) {
    return "-";
  }

  const prefix = numValue > 0 ? "+" : "";

  return `${prefix}${numValue.toFixed(2)}%`;
};

export const formatBetAmount = (value?: string | number) => {
  const numValue = Number(value);

  if (isNaN(numValue) || value === null) {
    return "-";
  }

  return `${numValue.toFixed(2)}%`;
};

export const formatHitRate = (value?: string | number | null) => {
  const numValue = Number(value);

  if (isNaN(numValue) || value === null) {
    return "-";
  }

  return `${numValue.toFixed(2)}%`;
};

export const formatProbability = (value?: string | number) => {
  const numValue = Number(value);

  if (isNaN(numValue) || value === null) {
    return "-";
  }

  return `${Math.round(numValue)}%`;
};

export const formatOdds = (value?: string | number) => {
  const numValue = Number(value);

  if (isNaN(numValue) || value === null) {
    return "-";
  }

  return `x${numValue.toFixed(2)}`;
};
