import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "@api/index";

import { client } from "../client";

type ContactResponse = {
  error?: string;
  message: string;
};

type ContactArgs = {
  email: string;
  name: string;
  message: string;
};

const mutateContact: MutationFunction<ContactResponse, ContactArgs> = ({
  name,
  email,
  message,
}) => {
  return client
    .post("/contact", { name, email, message })
    .then((res) => res.data);
};

export const useContact = (
  args?: ContactArgs,
  options?: UseMutationOptions<
    ContactResponse,
    DefaultErrorResponse,
    ContactArgs
  >,
): UseMutationResult<ContactResponse, DefaultErrorResponse, ContactArgs> => {
  return useMutation<ContactResponse, DefaultErrorResponse, ContactArgs>(
    mutateContact,
    options,
  );
};
