import React, { FC, useState } from "react";

import { Menu, MenuItem, MenuList, MenuPanel, Modal } from "@ui/components";
import { Column, Columns } from "@ui/layout";

import { useAuth } from "@components";

import { BetaFeatures } from "./Menus/BetaFeatures/BetaFeatures";
import { BetAmount } from "./Menus/BetAmount/BetAmount";
import { CurrencySettings } from "./Menus/CurrencySettings/CurrencySettings";
import { MyBets } from "./Menus/MyBets/MyBets";

type PreferencesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PreferencesModal: FC<PreferencesModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [activeMenu, setActiveMenu] = useState("currency");
  const authContext = useAuth();

  if (!authContext.userAttributes) return null;

  return (
    <Modal
      size="large"
      header="Preferences"
      isActive={isOpen}
      onClose={onClose}
    >
      <Menu value={activeMenu} onChange={setActiveMenu}>
        <Columns>
          <Column size={4}>
            <MenuList>
              <MenuItem icon="Coins" value="currency">
                Currency
              </MenuItem>
              <MenuItem icon="Money" value="bet-amount">
                Bet Amount
              </MenuItem>
              <MenuItem icon="BookmarkSimple" value="my-bets">
                My Bets
              </MenuItem>
              <MenuItem icon="Flask" value="beta-features">
                Beta Features
              </MenuItem>
            </MenuList>
          </Column>
          <Column>
            <MenuPanel value="currency">
              <CurrencySettings />
            </MenuPanel>
            <MenuPanel value="bet-amount">
              <BetAmount />
            </MenuPanel>
            <MenuPanel value="my-bets">
              <MyBets />
            </MenuPanel>
            <MenuPanel value="beta-features">
              <BetaFeatures />
            </MenuPanel>
          </Column>
        </Columns>
      </Menu>
    </Modal>
  );
};
