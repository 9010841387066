import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { useQueryClient } from "react-query";
import ReactSelect, {
  MultiValue,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import { toast } from "react-toastify";

import {
  FetchLeaguesResponse,
  useLeaguesSearch,
} from "@api/football/fetchLeagues";
import {
  GET_SETTINGS_QUERY_KEY,
  useGetSettings,
} from "@api/settings/getSettings";
import { useUpdateLeagues } from "@api/settings/updateLeagues";

import { ImageComponent, Tag, Tags, Text } from "@ui/elements";
import { Stack } from "@ui/layout";

import "./LeagueSelect.scss";

type LeagueOption = {
  label: string;
  value: number;
  data: FetchLeaguesResponse;
};

type LeagueSelectOptions = MultiValue<LeagueOption>;

const MultiValueLabel = (props: MultiValueGenericProps<LeagueOption>) => {
  const data = props.data.data;
  return (
    <Tag size="medium">
      <Stack gap="xs">
        <ImageComponent src={data.league.logo} size={16} />
        <span>{data.league.name}</span>
      </Stack>
    </Tag>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<LeagueOption>) => {
  return (
    <Tag
      size="medium"
      isDelete
      isClickable
      onClick={props.innerProps.onClick}
    />
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<LeagueOption>) => {
  return <Tags hasAddons>{props.children}</Tags>;
};

const ValueContainer = (props: ValueContainerProps<LeagueOption>) => {
  return (
    <Stack {...props.innerProps} wrap className="league-value-container">
      {props.children}
    </Stack>
  );
};

const Option = (props: OptionProps<LeagueOption>) => {
  const data = props.data.data;

  return (
    <div
      {...props.innerProps}
      className={classNames([
        props.innerProps.className,
        "custom-select-option",
      ])}
    >
      <Stack gap="xs" align="center">
        <ImageComponent isRounded padded src={data.league.logo} size={48} />
        <Stack direction="column" gap="none">
          <Text>{data.league.name}</Text>
          <Stack gap="xs" align="center">
            <ImageComponent src={data.country.flag} size={16} />
            <Text size="small">{data.country.name}</Text>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export const LeagueSelect = () => {
  const queryClient = useQueryClient();
  const [query, setQuery] = useState("");
  const {
    data: settings,
    isRefetching: isSettingsRefetching,
    isLoading: isSettingsLoading,
  } = useGetSettings();
  const { mutateAsync, isLoading: isUpdateLoading } = useUpdateLeagues();
  const { data: leagues, isLoading: isLeaguesLoading } =
    useLeaguesSearch(query);

  const options = useMemo(() => {
    const fetchedLeagues = leagues || [];

    return (
      fetchedLeagues.map((data) => ({
        label: data.league.name,
        value: data.league.id,
        data,
      })) || []
    );
  }, [leagues]);

  const values = useMemo(() => {
    return settings?.leagues?.map((data) => ({
      label: data.league.name,
      value: data.league.id,
      data,
    }));
  }, [settings]);

  const saveLeagues = (values: LeagueSelectOptions) => {
    const payload = values.flatMap((e) => e.data);

    toast.promise(
      async () => {
        await mutateAsync(payload);
        await queryClient.invalidateQueries([GET_SETTINGS_QUERY_KEY]);
      },
      {
        pending: "Saving leagues ...",
        success: "Successfully updated supported leagues 👌",
        error: "Failed to update leagues 🤯",
      },
    );
  };

  return (
    <div className="field league-select">
      <label className="label">Supported leagues</label>
      <ReactSelect
        isMulti
        isDisabled={isSettingsLoading}
        isClearable={false}
        components={{
          MultiValueLabel,
          MultiValueRemove,
          MultiValueContainer,
          Option,
          ValueContainer,
        }}
        isLoading={
          isLeaguesLoading ||
          isSettingsLoading ||
          isSettingsRefetching ||
          isUpdateLoading
        }
        value={values}
        onChange={(values) => saveLeagues(values)}
        onInputChange={(value) => setQuery(value)}
        options={options}
      />
    </div>
  );
};
