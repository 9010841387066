import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";

import { DefaultErrorResponse } from "../../index";
import { client } from "../client";

type CreateMotivationsResponse = {
  id: number;
  success: boolean;
};

type CreateMotivationsArgs = {
  id: string | number;
};

export const createMotivations: MutationFunction<
  CreateMotivationsResponse,
  CreateMotivationsArgs
> = (props) => {
  return client.post(`/motivations`, { id: props.id }).then((res) => res.data);
};

export const CreateMotivationsMutationKey = "create-motivations";

export const useCreateMotivations = (
  params?: CreateMotivationsArgs,
  options?: Omit<
    UseMutationOptions<
      CreateMotivationsResponse,
      DefaultErrorResponse,
      CreateMotivationsArgs
    >,
    "queryKey" | "queryFn"
  >,
): UseMutationResult<
  CreateMotivationsResponse,
  DefaultErrorResponse,
  CreateMotivationsArgs
> => {
  return useMutation(
    [CreateMotivationsMutationKey, params?.id],
    createMotivations,
    options,
  );
};
